<roma-modal-template
  size="md"
  [showDefaultButtons]="false"
  [showHeader]="false"
  [footerTemplate]="footerCustomTemplate"
  (cancel)="close(false)"
>
  <roma-loading *ngIf="loading"></roma-loading>
  <ng-container *ngIf="!loading">
    <h2 class="owner-name">
      <roma-user-avatar
        [user]="params.clockHour.owner"
        width="28"
        height="28"
      ></roma-user-avatar>
      {{ clockAbsence.owner?.name }}
    </h2>

    <div class="data-flex-container">
      <div class="item item-name strong">
        {{ 'table.colum.duration' | translate }}
      </div>
      <div class="item item-info">
        <strong>{{ getClockHoursDuration(clockAbsence) }}</strong>
      </div>
    </div>
    <div class="data-flex-container">
      <div class="item item-name">
        {{ 'general.date' | translate }}
      </div>
      <div class="item item-info">
        {{ clockAbsence?.date | dateDayMonthConvert }}
      </div>
    </div>
    <div class="data-flex-container">
      <div class="item item-name">
        {{ 'general.status' | translate }}
      </div>
      <div class="item item-info">
        <ng-container [ngSwitch]="clockAbsence?.status">
          <span
            *ngSwitchCase="clockHourStatusRef.Pending"
            class="status-wrapper warn"
          >
            {{ 'table.content.pending' | translate }}
          </span>
          <span
            *ngSwitchCase="clockHourStatusRef.Approved"
            class="status-wrapper green"
          >
            {{ 'table.content.approved' | translate }}
          </span>
          <span
            *ngSwitchCase="clockHourStatusRef.Rejected"
            class="status-wrapper red"
          >
            {{ 'table.content.rejected' | translate }}
          </span>
          <ng-container *ngSwitchDefault>
            {{ tagNotAvailable | translate }}
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="divider"></div>

    <div *ngFor="let shift of shifts; let i = index">
      <div class="data-flex-container">
        <div class="item item-name strong">
          {{ 'clockHour.turn' | translate }} {{ i + 1 }}
        </div>
        <div class="item item-info">
          <div class="shift-time">
            <span class="hour-shift">{{ shift.startDate }}</span>
            <img class="arrow" src="/assets/img/icons/arrow-right-gray.svg" />
            <span class="hour-shift">{{ shift.endDate }} </span>
          </div>
        </div>
      </div>

      <div class="data-flex-container" *ngIf="shift.startLocation">
        <div class="item item-name">{{ 'clockHour.enter' | translate }}</div>
        <div class="item item-info">
          <roma-google-maps-link
            [latitude]="shift.startLocation.geolocation.latitude"
            [longitude]="shift.startLocation.geolocation.longitude"
            [street]="shift.startLocation.address.street"
            [state]="shift.startLocation.address.state"
            [city]="shift.startLocation.address.city"
            [maxWidth]="isMobile ? '220px' : '320px'"
          ></roma-google-maps-link>
        </div>
      </div>
      <div class="data-flex-container" *ngIf="shift.endLocation">
        <div class="item item-name">{{ 'clockHour.exit' | translate }}</div>
        <div class="item item-info">
          <roma-google-maps-link
            [latitude]="shift.endLocation.geolocation.latitude"
            [longitude]="shift.endLocation.geolocation.longitude"
            [street]="shift.endLocation.address.street"
            [state]="shift.endLocation.address.state"
            [city]="shift.endLocation.address.city"
            [maxWidth]="isMobile ? '220px' : '320px'"
          ></roma-google-maps-link>
        </div>
      </div>

      <div *ngIf="shift.projectId && shift.project" class="data-flex-container">
        <div class="item item-name">{{ 'clockHour.project' | translate }}</div>
        <div class="item item-info chip">
          {{ shift.project.name }}
        </div>
      </div>
      <div *ngIf="shift.dealId && shift.deal" class="data-flex-container">
        <div class="item item-name">{{ 'clockHour.deal' | translate }}</div>
        <div class="item item-info chip">
          {{ shift.deal.name }}
        </div>
      </div>

      <div class="divider-shifts"></div>
    </div>

    <div class="data-flex-container">
      <div class="item item-name">
        {{ 'general.notes' | translate }}
      </div>
      <div class="item item-info">
        <ng-container *ngIf="clockAbsence?.notes?.length > 0">
          <ng-container *ngIf="clockAbsence.notes?.length; else noText">
            {{ getNotes(clockAbsence) }}
          </ng-container>
          <ng-template #noText> - </ng-template>
        </ng-container>

        <ng-container *ngIf="!clockAbsence?.notes?.length"> - </ng-container>
      </div>
    </div>

    <div
      *ngIf="clockAbsence.status === statusRef.Rejected"
      class="data-flex-container"
    >
      <div class="item item-name">
        {{ 'general.reason' | translate }}
      </div>
      <div class="item item-info">
        <ng-container *ngIf="clockAbsence?.reasonRejected?.description">
          {{ clockAbsence?.reasonRejected?.description }}
        </ng-container>

        <ng-container *ngIf="!clockAbsence?.reasonRejected?.description">
          -
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="showOptionsReasonDescription">
      <div class="container__reason">
        <label>{{ 'clockAbsence.addReason' | translate }}</label>
        <textarea
          name=""
          id=""
          [value]="''"
          cols="30"
          rows="10"
          [placeholder]="'clockAbsence.enterReason' | translate"
          (keyup)="changeReasonDescription($event.target.value)"
        >
        </textarea>
        <div class="text-danger" *ngIf="showReasonError">
          {{ 'clockAbsence.reasonError' | translate }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</roma-modal-template>

<ng-template #footerCustomTemplate>
  <div class="footer">
    <div class="row gap-2 w-100" *ngIf="showOptionsReasonDescription">
      <div class="col p-0">
        <base-button (click)="continue()">
          {{ 'general.continue' | translate }}
        </base-button>
      </div>
      <div class="col p-0">
        <base-button
          buttonClass="tertiary"
          (click)="cancel()"
          [style]="{ color: '#15152e !important' }"
        >
          {{ 'general.cancel' | translate }}
        </base-button>
      </div>
    </div>

    <ng-container *ngIf="!showOptionsReasonDescription">
      <div class="edit-button-container d-block d-sm-none">
        <base-button
          *ngIf="showButtonsOptions.showEdit"
          buttonClass="secondary-text-link edit-button"
          romaProFeature
          [featureUser]="featureUserRef.ClockHour.update"
          (clicked)="edit()"
        >
          <mat-icon class="edit-button-icon" svgIcon="edit_icon"></mat-icon>
        </base-button>
      </div>

      <div class="row gap-2 w-100">
        <div
          class="col p-0 d-none d-lg-block"
          *ngIf="showButtonsOptions.showEdit"
        >
          <base-button
            buttonClass="secondary-text-link"
            romaProFeature
            [featureUser]="featureUserRef.ClockHour.update"
            (clicked)="edit()"
          >
            <img src="assets/icons/gl_edit-fill.svg" />
            {{ 'general.edit' | translate }}
          </base-button>
        </div>
        <div class="col d-block p-0" *ngIf="showButtonsOptions.showDelete">
          <base-button
            buttonClass="tertiary-danger"
            romaClickDelete
            romaProFeature
            subtitle="deleteModal.body_final"
            [featureUser]="featureUserRef.ClockHour.delete"
            (wantDelete)="delete()"
          >
            {{ 'general.remove' | translate }}
          </base-button>
        </div>
        <div class="col p-0" *ngIf="showButtonsOptions.showReject">
          <base-button
            buttonClass="tertiary-danger"
            romaProFeature
            [featureUser]="featureUserRef.ClockHourCompany.approve"
            (clicked)="reject()"
          >
            {{ 'general.decline' | translate }}
          </base-button>
        </div>
        <div class="col p-0" *ngIf="showButtonsOptions.showApproval">
          <base-button
            romaProFeature
            [featureUser]="featureUserRef.ClockHourCompany.approve"
            (clicked)="approved()"
          >
            {{ 'general.approve' | translate }}
          </base-button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
