import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  COMPANY_ID_KEY,
  ClockHour,
  Feature,
  FeatureUser,
  IUser,
  TypeRol,
} from '@tacliatech/types';

import { PaginateResponse } from '@web-frontend/shared/interfaces';

import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { FilterItem } from '@web-frontend/shared/components/filter';
import { FilterComponent } from '@web-frontend/shared/components/filter/filter.component';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import {
  AuthService,
  ClockHourService,
  StorageService,
  UserSearchService,
  UserService,
  clockHourPaginateResponse,
} from '@web-frontend/shared/services';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { RemoveEmpty } from '@web-frontend/shared/utils';
import { Workbook } from 'exceljs';
import { Subscription } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

import {
  AddClockHourAdminService,
  MobileClockHourAdmin,
  ShowDetailClockHourService,
} from '@web-frontend/shared/components/clock-hours';
import { CreatePersonService } from '@web-frontend/shared/components/create-persons/create-person.service';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { RmSelectComponent } from '@web-frontend/shared/components/globals/rm-select';
import { RmSelect } from '@web-frontend/shared/components/globals/rm-select/rm-select.types';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { TableComponent } from '@web-frontend/shared/components/table/table.component';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { addHours } from 'date-fns';
import {
  SOURCE_FILTER,
  SOURCE_MOBILE,
  SOURCE_SECONDARY_WEB_FILTER,
} from './admin-clock-hour.const';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { ViewType } from '../clock-table-header/clock-table-header.component';
import { ClockHourView } from '../clock-hour.type';
import { ListTeamViewComponent } from '../list-team-view/list-team-view.component';

const INITIAL_FILTER = () => {
  return {
    'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
    'idOwners[]': [],
    'avgHoursWorked[]': [],
    from: null,
    to: null,
    customProperties: null,
    keyword: '',
    date: null,
    page: 1,
    limit: 50,
  };
};

@Component({
  selector: 'roma-admin-clock-hour',
  templateUrl: './admin-clock-hour.component.html',
  styleUrls: [
    './admin-clock-hour.component.scss',
    '../clock-hour.component.scss',
  ],
})
export class AdminClockHourComponent implements OnInit, OnDestroy {
  tableMarginTop = '0px';
  distanceToTop = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
    this.validateViewType();
  }

  @ViewChild(FilterComponent)
  filterComponent: FilterComponent;

  @ViewChild(TableComponent)
  table: TableComponent;

  @ViewChild('inputFilterSearch')
  input: ElementRef<HTMLInputElement>;

  @ViewChild(RmSelectComponent) child: RmSelectComponent;

  @ViewChild(ListTeamViewComponent) listTeamView: ListTeamViewComponent;

  isLoading = false;
  loadingAuthors = false;
  clockHours: PaginateResponse<ClockHour.Output[]>;
  data: ClockHour.Output[] = [];
  resize$ = this.sandBoxService.screenBusChannel$;
  personService = this.createPersonService;
  authors: RmSelect.Items = [];
  authorsCharged = false;
  isAdmin = false;
  showDisclaimer = false;
  viewTypeActive = ViewType.TEAM;
  viewTypeRef = ViewType;
  updateMetrics = false;
  private isRequestInProgress = false;

  status: RmSelect.Items = [
    {
      title: this.i18n.instant('clockAbsence.pending'),
      value: ClockHour.Status.Pending,
      id: ClockHour.Status.Pending,
    },
    {
      title: this.i18n.instant('clockAbsence.rejected'),
      value: ClockHour.Status.Rejected,
      id: ClockHour.Status.Rejected,
    },
    {
      title: this.i18n.instant('clockAbsence.approved'),
      value: ClockHour.Status.Approved,
      id: ClockHour.Status.Approved,
    },
  ];

  ownerID: string[];
  typeRolRef = TypeRol;
  user$ = this.authService.user$;
  clockHoursTotal = '00:00';
  deleteMessage = false;
  canApprovalOrRejectAll = false;
  statusRef = ClockHour.Status;

  entriesForDelete!: ClockHour.Output[];
  totalPages: any[] = [];
  hoursFilter!: 'MINOR' | 'MAJOR' | 'EQUAL';
  hoursLimit!: string;
  hoursFilterActive = false;
  usersFilters = false;
  statusFilters = false;
  activeFilters = false;
  searchFilter = false;
  timeout: any = null;

  sourceSearch = SOURCE_FILTER;

  sourceOptions = SOURCE_SECONDARY_WEB_FILTER({
    userSearchService: this.userSearchService,
  });

  sourceSecondaryMobileFilter = SOURCE_MOBILE({
    userSearchService: this.userSearchService,
  });

  private _input: ElementRef<HTMLInputElement>;
  private sub$ = new Subscription();

  private searchParams = INITIAL_FILTER();

  showVideoTutorialButton: boolean;
  featureRef = Feature;
  featureUserRef = FeatureUser;

  scrollDown$ = this.scrollService.scrollDown$;
  private academyLink: string;
  users: IUser[] = [];

  dateRange: {
    startDate: string;
    endDate: string;
    selectedPeriod: ClockHourView;
  } = {
    startDate: '',
    endDate: '',
    selectedPeriod: ClockHourView.Week,
  };

  searchTeamMember = '';

  constructor(
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private authService: AuthService,
    private userSearchService: UserSearchService,
    private clockHourService: ClockHourService,
    private createPersonService: CreatePersonService,
    private addClockHourService: AddClockHourAdminService,
    private showDetailClockHourService: ShowDetailClockHourService,
    private amplitudeService: AmplitudeService,
    private fileWrite: FileWriteService,
    private datePipe: DatePipe,
    private alertService: AlertService,
    private sandBoxService: SandboxService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
    private video: VideoService,
    public tutorialService: TutorialService,
    private scrollService: ScrollService,
    private readonly brazeService: BrazeService,
    private readonly downloadBtnService: DownloadBtnService,
    private analyticsService: AnalyticsService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.getUsers();
    this.requestClockHours(true);
    this.validateViewType();
    this.watchRefreshList();
    this.isAdmin =
      JSON.parse(StorageService.userData).role?.toLowerCase() == 'admin_role';
    this.haveFeature();

    // TODO -> Once the video is added delete this logic from here and HTML
    // Logic to enable tutorial button
    this.sub$.add(
      this.tutorialService
        .get('clock-hours-team')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.academyLink = res?.academy;
          if (res?.source) {
            this.showVideoTutorialButton = !!res.source;
          } else {
            this.showVideoTutorialButton = false;
          }
        })
    );
  }

  validateViewType() {
    this.viewTypeActive = this.isLowerThan1024px ? ViewType.ALL : ViewType.TEAM;
    this.cdRef.detectChanges();
  }

  showCreateUser(): void {
    this.createPersonService.open().subscribe((res) => {
      if (res) {
        if (this.viewTypeActive === ViewType.TEAM) {
          this.listTeamView.getTeamMetrics();
        }
        this.draw();
      }
    });
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.user_start,
      eventProperties: {
        event_location: 'trackingTimeTeam',
      },
    });
  }

  public refresh() {
    return this.draw();
  }

  isAvailableFeature = true;
  haveFeature() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.ClockHour.ViewTimer)
        .subscribe((res) => {
          this.isAvailableFeature = res;
          if (!this.isAvailableFeature) {
            if (this.isAdmin) {
              this.showDisclaimer = true;
              this.draw();
            }
          }
        })
    );
  }

  onClickSelect(item: string) {
    if (item === 'authors' && !this.authorsCharged) {
      this.authorsCharged = true;
      this.requestAuthors();
      this.draw();
    }
  }

  ngOnDestroy(): void {
    // Removing property to sort on adminClockHours table
    localStorage.removeItem('adminClockHours-sort');
  }

  nextPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestClockHours(true);
  }

  goToPage(page) {
    this.searchParams = {
      ...this.searchParams,
      page: page,
    };

    this.requestClockHours(true);
  }

  previousPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };

    this.requestClockHours(true);
  }

  cleanFilters() {
    this.input.nativeElement.value = '';
    this.filterComponent.clearRangeDateInput();
    this.child.clearInput('out');
    this.hoursFilter = null;
    this.hoursLimit = '';
    this.hoursFilterActive = false;
    this.activeFilters = false;
    this.usersFilters = false;
    this.searchFilter = false;
    this.searchParams = INITIAL_FILTER();
    this.requestClockHours(true);
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.changeKeyword(event.target.value);
      }
    }, 500);
  }

  private changeKeyword(keyword: string) {
    this.searchFilter = Boolean(keyword);
    this.searchParams['keyword'] = keyword;
    this.searchParams['page'] = 1;
    this.requestClockHours(true);
  }

  private requestClockHours(loadingGlobal = false, loadingPagination = false) {
    if (this.isRequestInProgress) {
      return;
    }

    this.isRequestInProgress = true;

    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    const query = RemoveEmpty(this.searchParams);

    this.sub$.add(
      this.clockHourService
        .search({
          ...query,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.isRequestInProgress = false;
            this.calcTableDistance();
            this.draw();
          })
        )
        .subscribe((res) => {
          this.setClockHours(res);
        })
    );
  }

  private setClockHours(response: clockHourPaginateResponse) {
    this.clockHours = response;
    this.source = response.docs;
    this.clockHoursTotal = ClockHour.formatHoursMinutes(
      response.totalHours,
      response.totalMinutes
    );
    let i;
    this.totalPages = [];
    for (i = 1; i <= this.clockHours.countPages; i++) {
      this.totalPages.push({
        page: i,
      });
    }
  }

  private set source(clockHours: ClockHour.Output[]) {
    this.data = clockHours;
    this.updateDataWithUser();

    const pending = this.data.filter(
      (row) => row.status === ClockHour.Status.Pending
    );

    if (pending.length > 0) {
      this.brazeService.sendEvent({
        event: BrazeEventType.time_tracking_pending_approval,
        eventProperties: {
          count: pending.length,
        },
      });
    }

    this.draw();
  }

  updateDataWithUser(): void {
    this.data = this.data.map((item) => ({
      ...item,
      owner: this.users.find((user) => user._id === item.idOwner) || item.owner,
    }));
  }

  mobileActions(evt: MobileClockHourAdmin.Action) {
    const clockHour = this.data.find((item) => item._id === evt.id);

    if (evt.action === 'EDIT' || evt.action === 'VIEW') {
      this.launchModalClockHourEdit(clockHour);
    } else if (evt.action === 'DELETE') {
      this.deleteClockHour([clockHour]);
    }
  }

  changeOwners(evt: Array<{ title: string; value: string }>): void {
    const ids = evt.map((el) => el.value);

    this.searchParams['idOwners[]'] = ids;
    this.searchParams['page'] = 1;
    this.ownerID = ids;
    this.usersFilters = evt.length > 0;

    this.requestClockHours(true);
  }

  changeStatus(evt: Array<{ title: string; value: string }>): void {
    const ids = evt.map((el) => el.value);

    this.searchParams['status[]'] = ids;
    this.searchParams['page'] = 1;
    this.statusFilters = evt.length > 0;

    this.requestClockHours(true);
  }

  changeDate(type: 'START' | 'END', value: Date) {
    if (!value) return;

    this.activeFilters = true;
    this.searchParams = {
      ...this.searchParams,
      [type === 'START' ? 'from' : 'to']: JSON.stringify(
        ClockHour.fromStringDateToObjectDate(addHours(value, 6).toUTCString())
      ),
    };

    if (this.searchParams['from'] && this.searchParams['to']) {
      const from = JSON.parse(this.searchParams['from']);
      const to = JSON.parse(this.searchParams['to']);

      if (
        new Date(`${from['year']}-${from['month'] + 1}-${from['day']}`) >
        new Date(`${to['year']}-${to['month'] + 1}-${to['day']}`)
      ) {
        return;
      }

      this.searchParams['page'] = 1;
      this.requestClockHours(true);
    }
  }

  hoursRadioChange() {
    this.hoursLimit = '';
  }

  updateStatusClockHour(
    status: ClockHour.Status.Approved | ClockHour.Status.Rejected
  ) {
    if (status === ClockHour.Status.Approved) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_approve,
        eventProperties: {
          event_location: 'all_list',
        },
      });
    } else {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_decline,
        eventProperties: {
          event_location: 'all_list',
        },
      });
    }
    this.sub$.add(
      this.clockHourService
        .updateMany(
          this.entriesForDelete.map((el) => el._id),
          {
            status,
          }
        )
        .subscribe(() => {
          this.entriesForDelete = [];
          this.deleteMessage = false;
          this.draw();
          this.requestClockHours(true);
        })
    );
  }

  launchModalClockHour(): void {
    this.updateMetrics = false;
    this.addClockHourService
      .open({
        data: {
          mode: 'ADD',
        },
      })
      .subscribe(() => {
        this.updateMetrics = true;
      });
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.trackingTimeTeam_start,
    });
  }

  downloadExcel() {
    this.clockHourService
      .search({
        ...RemoveEmpty(this.searchParams),
        limit: 999999999,
      })
      .pipe(take(1))
      .subscribe((res: PaginateResponse<ClockHour.Output[]>) => {
        const workbook = this.getBuildExcel(res);

        const type =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        workbook.xlsx
          .writeBuffer()
          .then(
            (data) =>
              new Blob([data], {
                type,
              })
          )
          .then((blob) =>
            this.fileWrite.writeFile({
              path: `clock-hour-${this.datePipe.transform(
                new Date(),
                'dd-MM-yyyy'
              )}.xlsx`,
              blob,
            })
          )
          .then(() => {
            // this.alertService.success(this.i18n.instant('general.savedFile'))
            this.downloadBtnService.downloadedSuccessfully();
          });
      });
  }

  private getBuildExcel(data: PaginateResponse<ClockHour.Output[]>) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('ClockHour');
    const header = [
      this.i18n.instant('person.title2'),
      this.i18n.instant('billing.date'),
      this.i18n.instant('general.in'),
      this.i18n.instant('clockHour.create.form.duration'),
      this.i18n.instant('person.create.form.position'),
      this.i18n.instant('general.notes'),
      this.i18n.instant('filter.status'),
    ];

    const footer = [' ', ' ', 'TOTAL', this.clockHoursTotal];
    const headerRow = worksheet.addRow(header);

    headerRow.font = {
      color: { argb: '003c48ec' },
      bold: true,
      size: 12,
    };
    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    const NOT_AVAILABLE_TEXT = this.i18n.instant(
      'general.propertyNotAvailable'
    );

    for (const clockHour of data?.docs) {
      const cells = [];
      cells.push(clockHour.owner ? clockHour.owner?.name : 'No Disponible');
      cells.push(
        clockHour.date
          ? clockHour.date?.day +
              '/' +
              (clockHour.date?.month + 1) +
              '/' +
              clockHour.date?.year
          : NOT_AVAILABLE_TEXT
      );

      if (clockHour.histories.length) {
        const items = clockHour.histories
          .map((history) => `${history?.startDate} - ${history?.endDate}`)
          .join(' | ');
        cells.push(items);
      } else {
        cells.push(NOT_AVAILABLE_TEXT);
      }

      cells.push(
        clockHour?.histories?.length
          ? this.getClockHoursDuration(clockHour)
          : NOT_AVAILABLE_TEXT
      );

      //Position
      cells.push(
        clockHour?.owner?.position
          ? clockHour?.owner?.position
          : NOT_AVAILABLE_TEXT
      );

      //Notes
      cells.push(
        clockHour?.notes?.length ? this.getNotes(clockHour) : NOT_AVAILABLE_TEXT
      );

      cells.push(
        clockHour?.status?.length
          ? this.i18n.instant(`table.content.${clockHour.status.toLowerCase()}`)
          : NOT_AVAILABLE_TEXT
      );

      const row = worksheet.addRow(cells);

      row.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    }

    worksheet.columns.forEach(function (column) {
      column.width = 27;
    });

    const footerRow = worksheet.addRow(footer);
    footerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    footerRow.font = {
      color: { argb: '003c48ec' },
      bold: true,
      size: 12,
    };

    return workbook;
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: this.entriesForDelete?.length > 1,
        body: this.i18n.instant('deleteModal.body_final'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteClockHour(this.entriesForDelete);
      }
    });
  }

  getNotes(clockHour: ClockHour.Output) {
    return ClockHour.getNotes(clockHour);
  }

  getClockHoursDuration(clockHour: ClockHour.Output) {
    return ClockHour.getTotalHourFromClockHours([clockHour], 0);
  }

  private requestAuthors() {
    this.loadingAuthors = true;

    this.sub$.add(
      this.userSearchService
        .search({ applyPaginate: false })
        .pipe(
          finalize(() => {
            this.loadingAuthors = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.authors = res.docs
            .map((res) => {
              return {
                title: res.name.toString().toLocaleLowerCase(),
                value: res._id,
                id: res._id,
              };
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
        })
    );
  }

  private watchRefreshList() {
    this.sub$.add(
      this.addClockHourService.refreshList$
        .pipe(filter((res) => res.reload))
        .subscribe(() => {
          this.requestClockHours(true);
        })
    );

    this.sub$.add(
      this.createPersonService.refreshList$
        .pipe(filter((res) => Boolean(res)))
        .subscribe(() => {
          this.requestAuthors();
        })
    );

    this.sub$.add(
      this.showDetailClockHourService.refreshList$
        .pipe(filter((res) => Boolean(res)))
        .subscribe((res) => {
          if (res?.reload) {
            this.requestClockHours(true);
          }
        })
    );
  }

  private selectAuthorInFilterComponent(item: FilterItem) {
    this.filterComponent.selectAuthor.select(item);
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  cleanHoursRangeFilter() {
    this.hoursFilter = null;
    this.hoursLimit = '';
    this.hoursFilterActive = false;
    this.searchParams = {
      ...this.searchParams,
      'avgHoursWorked[]': [],
    };
    this.requestClockHours(true);
  }

  rmFilterCoreChanges(evt: RmFilter.Changes) {
    this.searchParams = {
      ...INITIAL_FILTER(),
      ...evt.queryParams,
    };

    if (this.viewTypeActive === ViewType.TEAM) {
      this.searchTeamMember = this.searchParams.keyword;
    } else {
      if (evt.queryParams?.from && evt.queryParams?.to) {
        this.searchParams.from = JSON.stringify(
          ClockHour.fromStringDateToObjectDate(this.searchParams.from)
        );
        this.searchParams.to = JSON.stringify(
          ClockHour.fromStringDateToObjectDate(this.searchParams.to)
        );
      }
      if (evt.queryParams && Object.keys(evt.queryParams).length > 0) {
        this.amplitudeService.sendEvent({
          event:
            evt.type === 'CHANGE'
              ? AmplitudeEvents.trackingTimeTeam_filter
              : AmplitudeEvents.trackingTimeTeam_search,
        });
      }

      this.requestClockHours(true);
    }
  }

  confirmHoursFilter() {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'avgHoursWorked[]': [this.hoursFilter, +this.hoursLimit],
    };
    this.requestClockHours(true);
    this.hoursFilterActive = true;
  }

  timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  deleteClockHour(evt: ClockHour.Output[]): void {
    const ids = evt.map((entry) => entry._id);

    this.sub$.add(
      this.clockHourService
        .deleteBulk({
          ids: ids,
        })
        .pipe(
          finalize(() => {
            this.deleteMessage = false;
            this.entriesForDelete = [];
          })
        )
        .subscribe(() => {
          const DELETE_MESSAGE = this.i18n.instant('clockHour.deleteSuccess');

          this.requestClockHours(true);
          this.alertService.success(DELETE_MESSAGE);
          this.amplitudeService.sendEvent({
            event: 'trackingTime_delete',
          });
        })
    );
  }

  deleteMany(evt: ClockHour.Output[]) {
    this.entriesForDelete = evt;

    this.canApprovalOrRejectAll = evt
      .map((el) => el.status)
      .every((el) => el === ClockHour.Status.Pending);

    if (evt.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
  }

  getUsers(): void {
    this.userService
      .findAllByCompany(StorageService.CompanyId)
      .subscribe((res) => {
        this.users = res;
        this.updateDataWithUser();
        this.draw();
      });
  }

  launchModalClockHourEdit(evt: ClockHour.Output): void {
    this.showDetailClockHourService
      .open({
        data: {
          clockHour: evt,
        },
      })
      .subscribe();
  }

  applyFilters(evt: {
    startDate: Date;
    endDate: Date;
    customProperties: { [key: string]: any };
  }) {
    const startDate = evt.startDate;
    const endDate = evt.endDate;
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      customProperties: JSON.stringify(evt.customProperties),
    };

    if (!startDate && !endDate) {
      this.searchParams['from'] = startDate;
      this.searchParams['to'] = endDate;
      this.requestClockHours(true);
      return;
    }

    this.changeDate('START', startDate);
    this.changeDate('END', endDate);
  }

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.table.clearChecks();
  }

  calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 1000);
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  // Get current language
  getLang() {
    return this.i18n.currentLang;
  }

  // Open videotutorial modal
  openVideo(type: string) {
    const data = {
      event: 'trackingTimeTeam_play_tutorial',
    };
    this.amplitudeService.sendTutorialEvent(data, type);
    this.video.open(type);
  }

  changeViewType(evt: ViewType) {
    this.viewTypeActive = evt;

    const analyticEvent =
      evt === ViewType.TEAM
        ? AmplitudeEvents.trackingTimeTeam_teamView
        : AmplitudeEvents.trackingTimeTeam_fullView;

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: analyticEvent,
    });

    if (evt === ViewType.ALL) {
      this.searchParams = INITIAL_FILTER();
      this.searchParams['keyword'] = this.searchTeamMember;
      this.requestClockHours(true);
      this.watchRefreshList();
    }

    this.draw();
  }

  /********************************************TOOLTIP************************************************** */
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'clockHourAdmin.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.trackingTimeTeam_info,
    });
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  get isLowerThan1024px() {
    return window.innerWidth < 1024;
  }

  download(type: DownloadType) {
    if (this.viewTypeActive === ViewType.TEAM) {
      this.listTeamView.downloadAll.emit();
    } else {
      const downloadActions = {
        [DownloadType.EXCEL]: () => {
          this.downloadExcel();
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.trackingTimeTeam_excel_download,
          });
        },
      };

      downloadActions[type]?.();
    }
  }

  changeDateRange(evt: {
    startDate: string;
    endDate: string;
    selectedPeriod: ClockHourView;
  }) {
    this.dateRange = evt;
    this.cdRef.detectChanges();
  }
}
