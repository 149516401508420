<roma-modal-template
  size="md"
  [title]="
    !loadingLocation &&
      (haveLocation
        ? 'clockHour.geolocation.title'
        : 'clockHour.geolocation.titleRequest') | translate
  "
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (success)="submit()"
  (cancel)="close()"
  *ngIf="(showModal && haveLocation) || (showModal && loadingLocation)"
>
  <div *ngIf="loadingLocation" class="loading-container">
    <roma-loading></roma-loading>
  </div>
  <div *ngIf="!loadingLocation" class="modal-body-container">
    <p class="modal-subtitle">
      {{ 'clockHour.geolocation.description' | translate }}
    </p>

    <div class="map-container">
      <google-map
        *ngIf="haveLocation"
        height="100%"
        width="100%"
        [center]="mapOptions.center"
        [zoom]="mapOptions.zoom"
        [options]="mapOptions"
      >
        <map-marker [options]="markerOptions"></map-marker>
      </google-map>

      <div *ngIf="!haveLocation" class="image-container">
        <img src="assets/icons/location-block.svg" alt="map" />
      </div>
    </div>
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div *ngIf="!loadingLocation" class="modalFooter mobile-footer">
    <base-button
      [ngClass]="'cta-button'"
      (click)="submit()"
      [disabled]="loadingLocation"
    >
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="20"
        *ngIf="loadingLocation"
      ></mat-progress-spinner>
      <span *ngIf="!loadingLocation">
        {{
          (haveLocation
            ? 'clockHour.geolocation.cta'
            : 'clockHour.geolocation.ctaRequest'
          ) | translate
        }}
      </span>
    </base-button>
  </div>
</ng-template>
