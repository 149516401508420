import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SupervisedTeams,
  Supervisor,
  UnsupervisedTeams,
  UserWithSupervisors,
} from '../../interfaces/supervisor';

@Injectable({
  providedIn: 'root',
})
export class SupervisorService {
  private userApiBasePath = 'user/users';

  constructor(private http: HttpClient) {}

  getUnsupervisedUsers(): Observable<UnsupervisedTeams> {
    return this.http.get<UnsupervisedTeams>(
      `:API_BASE_URL${this.userApiBasePath}/supervised-teams/unsupervised-users`
    );
  }

  getSupervisedTeams(userId: string): Observable<SupervisedTeams> {
    return this.http.get<SupervisedTeams>(
      `:API_BASE_URL${this.userApiBasePath}/${userId}/supervised-teams`
    );
  }

  updateSupervisedTeams(userId: string, teams: string[]): Observable<unknown> {
    return this.http.patch(
      `:API_BASE_URL${this.userApiBasePath}/${userId}/supervised-teams`,
      {
        supervisedUserIds: teams,
      }
    );
  }

  getSupervisor(userId: string): Observable<Supervisor> {
    return this.http.get<Supervisor>(
      `:API_BASE_URL${this.userApiBasePath}/${userId}/supervisor`
    );
  }

  getUserWithSupervisors(userIds: string[]): Observable<UserWithSupervisors> {
    let params = new HttpParams();
    userIds.forEach((id) => {
      params = params.append('userIds', id);
    });

    return this.http.get<UserWithSupervisors>(
      `:API_BASE_URL${this.userApiBasePath}//supervised-teams/supervisors`,
      { params }
    );
  }
}
