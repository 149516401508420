import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor() {}

  // @ts-ignore
  public async getGeolocation(): Promise<{ lat: number; lng: number }> {
    if (Capacitor.getPlatform() === 'web') {
      return this.handleGetGeolocationInWeb();
    }
    if (['ios', 'android'].includes(Capacitor.getPlatform())) {
      return this.handleGetGeolocationInMobile();
    }
  }

  private handleGetGeolocationInWeb(): Promise<{ lat: number; lng: number }> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (response) => {
          resolve({
            lat: response.coords.latitude,
            lng: response.coords.longitude,
          });
        },
        (err) => {
          reject(null);
        }
      );
    });
  }

  public async handleGetGeolocationInMobile(): Promise<{
    lat: number;
    lng: number;
  }> {
    return new Promise(async (res, rej) => {
      try {
        const result = await Geolocation.requestPermissions({
          permissions: ['location', 'coarseLocation'],
        });

        if (result.location || result.coarseLocation) {
          const position = await Geolocation.getCurrentPosition();
          res({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        } else {
          NativeSettings.openAndroid({
            option: AndroidSettings.ApplicationDetails,
          });
        }
      } catch (err) {
        NativeSettings.openAndroid({
          option: AndroidSettings.ApplicationDetails,
        });
        rej(null);
      }
    });
  }

  public kill(processId: string) {}
}
