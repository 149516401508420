import { ModuleType } from '../../../../../types-legacy';

export const modulesInfo = [
  {
    name: 'pro.salesModule.name',
    type: ModuleType.SALES,
    icon: 'sales',
    description: 'pro.salesModule.description',
    features: [
      {
        name: 'pro.salesModule.features.users',
        basic: 'pro.salesModule.features.basic.users',
        pro: 'pro.salesModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.salesModule.features.invoices',
        basic: 'pro.salesModule.features.basic.invoices',
        pro: 'pro.salesModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.salesModule.features.clients',
        basic: 'pro.salesModule.features.unlimited',
        pro: 'pro.salesModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.salesModule.features.products_services',
        basic: 'pro.salesModule.features.unlimited',
        pro: 'pro.salesModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.salesModule.features.quotes',
        basic: true,
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.salesModule.features.proforms',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.salesModule.features.sales_tickets',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.payment_links',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.custom_templates',
        basic: false,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.download_excel_pdf',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.whatsapp_sending',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.waybills',
        basic: false,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.vendor_management',
        basic: false,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.salesModule.features.expense_management',
        basic: false,
        pro: true,
        otherSection: true,
      },
    ],
  },
  {
    name: 'pro.timeTrackingModule.name',
    type: ModuleType.TIME_TRACKING,
    icon: 'schedule',
    description: 'pro.timeTrackingModule.description',
    features: [
      {
        name: 'pro.timeTrackingModule.features.users',
        basic: 'pro.timeTrackingModule.features.basic.users',
        pro: 'pro.timeTrackingModule.features.pro.users',
        otherSection: false,
      },
      {
        name: 'pro.timeTrackingModule.features.clockingTimer',
        basic: true,
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.timeTrackingModule.features.overtimeManagement',
        basic: true,
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.timeTrackingModule.features.vacationDaysRecord',
        basic: true,
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.timeTrackingModule.features.geolocation',
        basic: true,
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.timeTrackingModule.features.documentPayrollManagement',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.mobileAppClocking',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.downloadsReports',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.calendarViewTeam',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.userPermissions',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.supervisors',
        basic: false,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.projectManagement',
        basic: false,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.timeTrackingModule.features.expenseManagement',
        basic: false,
        pro: true,
        otherSection: true,
      },
    ],
  },
  {
    name: 'pro.crmModule.name',
    type: ModuleType.CRM,
    icon: 'users-pink',
    description: 'pro.crmModule.description',
    features: [
      {
        name: 'pro.crmModule.features.users',
        basic: 'pro.crmModule.features.basic.users',
        pro: 'pro.crmModule.features.pro.users',
        otherSection: false,
      },
      {
        name: 'pro.crmModule.features.clients',
        basic: 'pro.crmModule.features.unlimited',
        pro: 'pro.crmModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.crmModule.features.sales',
        basic: 'pro.crmModule.features.unlimited',
        pro: 'pro.crmModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.crmModule.features.proofs',
        basic: true,
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.crmModule.features.customFields',
        basic: 'pro.crmModule.features.customFields_basic',
        pro: 'pro.crmModule.features.unlimited',
        otherSection: false,
      },
      {
        name: 'pro.crmModule.features.templates',
        basic: 'pro.crmModule.features.templates_basic',
        pro: true,
        otherSection: false,
      },
      {
        name: 'pro.crmModule.features.payments',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.crmModule.features.projects',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.crmModule.features.tasks',
        basic: true,
        pro: true,
        otherSection: true,
      },
      {
        name: 'pro.crmModule.features.onlineBooking',
        basic: false,
        pro: true,
        otherSection: true,
      },
    ],
  },
  {
    name: 'pro.fullPlan.name',
    type: ModuleType.FULL,
    icon: 'globe-dark-blue',
    description: 'pro.fullPlan.description',
    features: [
      {
        name: 'pro.fullPlan.features.users',
      },
      { name: 'pro.fullPlan.features.functionalities' },
    ],
  },
];
