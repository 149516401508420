import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { LocationMessageModalComponent } from './location-message-modal/location-message-modal.component';
import { GeolocationService } from '@web-frontend/shared/services';
import { GeocodeApiService } from '../address-map/service/geocode-api.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import AmplitudeEvents from '../../../../types/amplitude.enum';

enum PermissionState {
  GRANTED = 'granted',
  PROMPT = 'prompt',
  DENIED = 'denied',
}

@Component({
  selector: 'roma-register-location-modal',
  templateUrl: './register-location-modal.component.html',
  styleUrls: ['./register-location-modal.component.scss'],
})
export class RegisterLocationModalComponent implements OnInit {
  mapOptions: google.maps.MapOptions;
  markerOptions: google.maps.MarkerOptions;
  haveLocation = false;
  loadingLocation = false;
  permissions: PermissionState = PermissionState.PROMPT;
  coordinates: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  };
  showModal = false;

  constructor(
    private dialogRef: MatDialogRef<RegisterLocationModalComponent>,
    private geolocationService: GeolocationService,
    private dialog: MatDialog,
    private geocodeApiService: GeocodeApiService,
    private changeDetectionRef: ChangeDetectorRef,
    private toastService: ToastService,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { isEnding: boolean }
  ) {}

  ngOnInit(): void {
    this.checkGeolocationPermissions();
    this.loadMap();
  }

  loadMap(): void {
    this.mapOptions = {
      center: this.coordinates,
      zoom: 16,
      disableDefaultUI: true,
      draggable: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      zoomControl: false,
      scaleControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    };

    this.markerOptions = {
      position: this.coordinates,
      animation: google.maps.Animation.DROP,
      icon: 'assets/icons/map-pin.svg',
    };
  }

  checkGeolocationPermissions(): void {
    if (!navigator.permissions) return;

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      this.permissions = result.state as PermissionState;
      if (this.permissions !== PermissionState.DENIED) {
        this.askForGeolocation();
      } else if (this.permissions === PermissionState.DENIED) {
        this.dialogRef.close();
        this.locationAccessDenied();
      }
    });
  }

  askForGeolocation(): void {
    this.showModal = true;
    this.loadingLocation = true;
    this.draw();

    this.geolocationService
      .getGeolocation()
      .then((position) => {
        if (position?.lat && position?.lng) {
          this.coordinates = {
            lat: position.lat,
            lng: position.lng,
          };
          this.haveLocation = true;
          this.permissions = PermissionState.GRANTED;
          this.loadingLocation = false;
          this.loadMap();
        }
      })
      .catch(() => {
        this.loadingLocation = false;
        this.haveLocation = false;
        this.locationAccessDenied();
      });
  }

  locationAccessDenied(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze'],
      eventName: this.data?.isEnding
        ? AmplitudeEvents.geolocation_deniedEnd
        : AmplitudeEvents.geolocation_deniedStart,
    });

    this.dialogRef.close();
    this.dialog.open(LocationMessageModalComponent, {});
  }

  closeAndRegisterLocation(): void {
    this.geocodeApiService
      .getAddressFromLatLng(
        this.coordinates.lat.toString(),
        this.coordinates.lng.toString()
      )
      .subscribe((response) => {
        if (!response) return;
        const formattedAddress = this.geocodeApiService.formatGeocodeAddress(
          response
        );
        const returnData = {
          geolocation: {
            latitude: this.coordinates.lat,
            longitude: this.coordinates.lng,
          },
          address: formattedAddress,
        };
        this.dialogRef.close(returnData);

        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName: this.data?.isEnding
            ? AmplitudeEvents.geolocation_confirmedEnd
            : AmplitudeEvents.geolocation_confirmedStart,
        });

        this.locationSentToast();
      });
  }

  submit(): void {
    const actions = {
      [PermissionState.GRANTED]: () =>
        this.haveLocation
          ? this.closeAndRegisterLocation()
          : this.askForGeolocation(),
      [PermissionState.PROMPT]: () => this.askForGeolocation(),
      [PermissionState.DENIED]: () => this.locationAccessDenied(),
    };

    actions[this.permissions]();
  }

  private locationSentToast(): void {
    this.toastService.show({
      text: 'clockHour.geolocation.toast.titleSuccess',
      description: 'clockHour.geolocation.toast.descriptionSuccess',
      type: 'success',
      msDuration: 4000,
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
}
