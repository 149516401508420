<div class="safe-area-padding no-overflow-y">
  <no-login-register-banner></no-login-register-banner>
  <section class="header">
    <div class="container header_content">
      <h2 class="text-nowrap">{{ title | translate }}</h2>
      <div class="header_group_buttons">
        <button
          class="rounded-button"
          (click)="clickSettings()"
          clickOpenSettings
          romaProFeature
          [featureUserHide]="featureRefUser.CompanyAdjust.show"
          (closeModal)="refreshSettings()"
        >
          <img
            src="assets/icons/b_menu_help.svg"
            class="settings-icon"
            alt="configuration"
          />{{ 'section_settings.title' | translate }}
        </button>
        <ng-container *ngIf="validForBillLegacy">
          <button
            class="rounded-button"
            (click)="showPreview('desktop')"
            [disabled]="isLoadingSettings || (formIsInvalid && form.invalid)"
          >
            <img [src]="'assets/icons/gl_eye-on.svg'" alt="eye" />{{
              'budgets.title.preview' | translate
            }}
          </button>
          <button
            class="rounded-button"
            *ngIf="false"
            (click)="showCustomization()"
          >
            <img
              [src]="'assets/icons/gl_personalizacion.svg'"
              alt="magic wand"
            />
            {{ 'budgets.title.customize' | translate }}
          </button>
          <div class="more-button">
            <ng-container *ngIf="isResponsive992">
              <button
                (closeModal)="refreshSettings()"
                clickOpenSettings
                (click)="clickSettings()"
                romaProFeature
                [featureUserHide]="featureRefUser.CompanyAdjust.show"
              >
                <img [src]="'assets/icons/b_menu_help.svg'" alt="more" />
              </button>
            </ng-container>
          </div>
        </ng-container>
        <div
          *ngIf="haveChanges; else close"
          romaClickDelete
          [type]="type"
          [message]="'general.withoutSave'"
          [buttonDelete]="'general.buttonExit'"
          (wantDelete)="closeDialog()"
          class="modal_close_button"
        >
          <img
            class="close_button"
            [src]="'assets/icons/gl_close.svg'"
            alt="close"
          />
          <span class="close_text">{{ 'forms.close' | translate }}</span>
        </div>
      </div>
      <ng-template #close>
        <div (click)="closeDialog()" class="modal_close_button">
          <img
            class="close_button"
            [src]="'assets/icons/gl_close.svg'"
            alt="close"
          />
          <span class="close_text">{{ 'forms.close' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </section>
  <section class="container form__container">
    <ng-template *ngIf="showSnackbarMexico">
      <div class="banner-snackbar">
        <roma-snackbar-mexico
          [type]="dataSnackbar.type"
          [message]="dataSnackbar.message"
          [textLink]="dataSnackbar.textLink"
          (onClickLink)="onLinkClickSnackbar($event)"
        ></roma-snackbar-mexico>
      </div>
    </ng-template>
    <form
      [formGroup]="form"
      class="form"
      [ngStyle]="{
        'margin-top': showSnackbarMexico && '2rem',
        'margin-bottom': '6rem'
      }"
    >
      <div class="form-content">
        <div class="bdg_header">
          <roma-budget-header
            [formHeader]="form"
            [mode]="mode"
            [budgetType]="type"
            [duplicate]="duplicate"
            [convert]="convert"
            [budgetTypeAmplitude]="budgetTypeAmplitude"
            [canEditContact]="canEditContact"
          >
          </roma-budget-header>
        </div>
        <div class="form-separator"></div>
        <section class="categories">
          <div class="categories__header">
            <h3>{{ 'budgets.settings.create.form.concepts' | translate }}</h3>
            <ng-container *ngIf="!isResponsive992">
              <button
                #seeMore="matMenuTrigger"
                type="button"
                class="add-field-btn see-more-btn"
                [matMenuTriggerFor]="inputsMenu"
                [ngClass]="{ 'is-open': seeMore.menuOpen }"
                (click)="sendAmplitudeEvent('lineConfiguration')"
              >
                {{ 'general.seeMoreFields' | translate }}
                <img
                  src="assets/icons/gl_arrow-down-blue.svg"
                  style="margin-top: -5px"
                />
              </button>
              <mat-menu
                #inputsMenu="matMenu"
                class="dropdown-wrapper bordered-wrapper"
              >
                <div *ngFor="let field of fieldsToDisplay" class="list-wrapper">
                  <div class="list-item">
                    <mat-checkbox
                      *ngIf="field.showFieldAsActivable"
                      (click)="changeInputsCheckBox(!field.checked, field)"
                      [checked]="field.checked"
                    >
                      {{ field.nameRef | translate }}
                    </mat-checkbox>
                  </div>
                </div>
              </mat-menu>
            </ng-container>
            <ng-container *ngIf="isResponsive992">
              <button
                type="button"
                class="add-field-btn"
                (click)="onShowFieldsList()"
              >
                {{ 'general.seeMoreFields' | translate }}
                <img
                  src="assets/icons/gl_arrow-down-blue.svg"
                  style="margin-top: -5px"
                />
              </button>
              <div *ngIf="showFieldsList" style="position: absolute">
                <div
                  class="mobile-list-wrapper"
                  (click)="onShowFieldsList()"
                ></div>
                <div class="list-box">
                  <h4>{{ 'budget.moreFields' | translate }}</h4>
                  <div
                    *ngIf="isLoading"
                    style="text-align: center; width: 100%"
                  >
                    <mat-progress-spinner
                      mode="indeterminate"
                      [diameter]="60"
                      style="margin: 40px auto"
                    ></mat-progress-spinner>
                  </div>
                  <div class="list-wrapper">
                    <div *ngFor="let item of fieldsToDisplay" class="list-item">
                      <mat-checkbox
                        *ngIf="item.showFieldAsActivable"
                        (click)="changeInputsCheckBox(!item.checked, item)"
                        [checked]="item.checked"
                      >
                        {{ item.nameRef | translate }}
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="text-center close-btn-wrapper">
                    <img
                      (click)="onShowFieldsList()"
                      class="close-btn"
                      src="/assets/icons/btn_close.svg"
                      alt="close"
                    />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <button>Ver más campos ></button> -->
          <div formArrayName="budgetCategories">
            <div
              cdkDropList
              class="drag-list"
              [cdkDropListData]="budgetCategories.controls"
              (cdkDropListDropped)="drop($event)"
            >
              <roma-budget-category-header
                *ngIf="displayCategoriesHeader()"
                [fieldsToDisplay]="fieldsToDisplay"
                (goToPrompt)="goTo($event)"
                [haveChanges]="haveChanges"
              ></roma-budget-category-header>
              <div
                *ngFor="
                  let concept of budgetCategories.controls;
                  index as cIndex
                "
              >
                <div
                  class="waybill_header_container"
                  cdkDrag
                  [cdkDragDisabled]="true"
                  *ngIf="isWaybill(concept); else basicItem"
                >
                  <div class="waybill-separator drag-item">
                    {{ 'permissions.titles.Waybills' | translate }}
                    {{ concept.value.description }}
                  </div>
                  <!-- form headers -->
                  <!-- *ngIf="itemHasHeader(cIndex)" -->
                  <roma-budget-category-header
                    (goToPrompt)="goTo($event)"
                    [haveChanges]="haveChanges"
                    [fieldsToDisplay]="fieldsToDisplay"
                  ></roma-budget-category-header>
                </div>
                <ng-template #basicItem>
                  <section class="drag-item concept-container" cdkDrag>
                    <div
                      *ngIf="
                        concept.value?.type === typesItem.CATEGORY;
                        else conceptTemplate
                      "
                    >
                      <div
                        *ngIf="
                          displayCategoryNameInput(cIndex);
                          else categorySeparator
                        "
                        class="group-category-input"
                        [formGroupName]="cIndex"
                      >
                        <input
                          class="form-control"
                          type="text"
                          placeholder="{{
                            'budgets.settings.create.form.categoryPlaceholder'
                              | translate
                          }}"
                          formControlName="description"
                          (keyup.enter)="updateEditCategory(cIndex, false)"
                          (blur)="updateEditCategory(cIndex, false)"
                          autofocus
                        />
                        <img
                          class="close_button"
                          [src]="'assets/icons/gl_close.svg'"
                          alt="close"
                          (click)="removeLine(cIndex)"
                        />
                      </div>
                      <ng-template #categorySeparator>
                        <div class="separator">
                          <span
                            class="separator__title"
                            (click)="updateEditCategory(cIndex, true)"
                            >{{ concept.value?.description }}</span
                          >
                          <div class="separator__buttons">
                            <img
                              class="button_edit"
                              [src]="'assets/icons/pen-line.svg'"
                              alt="close"
                              (click)="updateEditCategory(cIndex, true)"
                            />
                            <img
                              class="close_button"
                              [src]="'assets/icons/gl_close.svg'"
                              alt="close"
                              (click)="removeLine(cIndex)"
                            />
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <ng-template #conceptTemplate>
                      <roma-budget-category
                        *ngIf="!settingIsNull"
                        class="line"
                        [catalogue]="catalogue$ | async"
                        [taxes]="invoiceTaxes"
                        [allTaxes]="allTaxes"
                        [units]="units"
                        [isLoadingUnits]="isLoadingUnits"
                        [fieldsToDisplay]="fieldsToDisplay"
                        [categoryForm]="concept"
                        [settings]="AllSettings"
                        [mode]="mode"
                        (deleteCategoryEvent)="removeLine(cIndex)"
                        (refreshCatalogueEvent)="requestCatalogues($event)"
                        (onChange)="onChange()"
                        [budgetTypeAmplitude]="budgetTypeAmplitude"
                      >
                      </roma-budget-category>
                    </ng-template>
                    <div class="drag-handle" cdkDragHandle>
                      <img
                        [src]="'assets/icons/icon-park-outline_drag.svg'"
                        alt="drag"
                      />
                    </div>
                  </section>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="add_menu">
            <ng-container *ngIf="!isResponsive992">
              <button type="button" class="add_line_button" (click)="addLine()">
                {{ 'budget.addLine' | translate }}
              </button>
              <div
                class="add-menu-button"
                mat-button
                [matMenuTriggerFor]="addMenu"
              >
                <img [src]="'assets/icons/chevron-down.svg'" alt="" />
              </div>
              <mat-menu #addMenu="matMenu">
                <button mat-menu-item (click)="addLine('addLine_addLine')">
                  {{ 'budget.addLine' | translate }}
                </button>
                <button
                  *ngIf="!isMX"
                  mat-menu-item
                  (click)="addCategoryGroup()"
                >
                  {{ 'budget.addCategory' | translate }}
                </button>
              </mat-menu>
            </ng-container>
            <ng-container *ngIf="isResponsive992">
              <button type="button" class="add_line_button" (click)="addLine()">
                {{ 'budget.addLine' | translate }}
              </button>
              <div class="add-menu-button" (click)="onShowAddLineList()">
                <img [src]="'assets/icons/chevron-down.svg'" alt="" />
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="isResponsive992">
            <div *ngIf="showAddLineList" style="position: absolute">
              <div
                class="mobile-list-wrapper"
                (click)="onShowAddLineList()"
              ></div>
              <div class="list-box">
                <h4>{{ 'budget.addConcept' | translate }}</h4>
                <div *ngIf="isLoading" style="text-align: center; width: 100%">
                  <mat-progress-spinner
                    mode="indeterminate"
                    [diameter]="60"
                    style="margin: 40px auto"
                  ></mat-progress-spinner>
                </div>
                <div class="list-wrapper">
                  <div class="list-item">
                    <button (click)="addLine(); onShowAddLineList()">
                      {{ 'budget.addLine' | translate }}
                    </button>
                  </div>
                  <div *ngIf="!isMX" class="list-item">
                    <button (click)="addCategoryGroup(); onShowAddLineList()">
                      {{ 'budget.addCategory' | translate }}
                    </button>
                  </div>
                </div>
                <div class="text-center close-btn-wrapper">
                  <img
                    (click)="onShowAddLineList()"
                    class="close-btn"
                    src="/assets/icons/btn_close.svg"
                    alt="close"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </section>
        <div class="form-separator"></div>
        <mat-progress-spinner
          *ngIf="isLoadingSettings"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: auto"
        ></mat-progress-spinner>
        <roma-budget-footer
          *ngIf="!isLoadingSettings"
          [formFooter]="form"
          [settings]="AllSettings"
          [mode]="mode"
          [type]="type"
          [haveChanges]="haveChanges"
          (changedFooter)="changedModel($event, 'footer')"
          (paymentsMethods)="getPaymentsMethods($event)"
          (goToPrompt)="goTo($event)"
          [budgetTypeAmplitude]="budgetTypeAmplitude"
        >
        </roma-budget-footer>
        <ng-container *ngIf="type === 'WAL'">
          <div class="form-separator"></div>
          <div>
            <section class="files">
              <div class="files__header">
                <h3>
                  {{
                    'budgets.settings.create.form.sectionFilesTitle' | translate
                  }}
                </h3>
              </div>
              <div class="files__description">
                <h6>
                  {{
                    'budgets.settings.create.form.sectionFilesDescription'
                      | translate
                  }}
                </h6>
              </div>
              <div class="files__addImages">
                <roma-image-selector
                  [type]="'deal'"
                  [quantity]="5"
                  [feature]="featureRef.Deal.UploadFiles"
                  [fileArray]="imagesParsed"
                  [featureUser]="featureUserScreen"
                  (changeFileArray)="changeFileArray($event)"
                  [module]="idModuleRef.Deals"
                  (cantUploadMoreFiles)="cantUploadMoreFiles($event)"
                  [file-admit]="['png', 'jpg', 'jpeg']"
                >
                </roma-image-selector>
              </div>
            </section>
            <div class="form-separator"></div>
          </div>
        </ng-container>
      </div>
      <div
        class="button-container"
        [ngStyle]="{ 'z-index': showMoreMenu ? 1 : 3 }"
      >
        <div class="total" *ngIf="isResponsive992">
          <roma-total-footer
            [mode]="mode"
            [form]="form"
            [settings]="AllSettings"
          ></roma-total-footer>
        </div>

        <ng-container *ngIf="isMXandTypeBill; else defaultSave">
          <button
            type="button"
            [disabled]="isLoading"
            class="save-button outline"
            (click)="submitDraft()"
          >
            <mat-progress-spinner
              *ngIf="isLoading"
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="20"
            ></mat-progress-spinner>
            <ng-container *ngIf="!isLoading">
              {{
                'budgets.settings.create.form.save.draft'
                  | lowercase
                  | translate
              }}
            </ng-container>
          </button>
          <button
            type="button"
            [disabled]="
              isLoading || (formIsInvalid && form.invalid) || !canIssueInvoice
            "
            class="save-button"
            (mouseenter)="displayTooltip(true)"
            (mouseleave)="displayTooltip(false)"
            (click)="submitDraftAndIssue()"
          >
            <mat-progress-spinner
              *ngIf="isLoading"
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="20"
            ></mat-progress-spinner>
            <ng-container *ngIf="!isLoading">
              {{
                'budgets.settings.create.form.save.' + type
                  | lowercase
                  | translate
              }}
            </ng-container>
          </button>
        </ng-container>
        <ng-template #defaultSave>
          <div class="default-save">
            <base-button
              *ngIf="isResponsive"
              size="medium"
              buttonClass="tertiary"
              [icon]="previewIcon"
              [disabled]="formIsInvalid && form.invalid"
              [isLoading]="isLoading"
              class="width-100"
              (click)="showPreview('mobile')"
            >
              {{ 'budgets.title.preview' | translate }}
            </base-button>
            <base-button
              class="button-confirm"
              size="medium"
              [disabled]="formIsInvalid && form.invalid"
              [isLoading]="isLoading"
              (mouseenter)="displayTooltip(true)"
              (mouseleave)="displayTooltip(false)"
              (click)="submitBudget({})"
            >
              {{ 'budgets.settings.create.form.save.default' | translate }}
            </base-button>
          </div>
        </ng-template>
        <div class="tooltip-container">
          <div *ngIf="showToolTip" class="title-tooltip-wrapper"></div>
          <div
            class="help-tooltip"
            [ngClass]="{
              'extra-top':
                !(featuresProEnabled$ | async) &&
                freeLimitService.firstWayBillCreated$.value
            }"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
          >
            <div
              [innerHTML]="
                'budgets.settings.create.form.tooltip-invalid' | translate
              "
            ></div>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
