import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClockAbsence, ClockHour } from '@tacliatech/types';
import { TeamMember } from '@web-frontend/core/admin/clock-hour/clock-hour.type';
import { PaginateResponse } from '@web-frontend/shared/interfaces';
import { Observable, throwError } from 'rxjs';
import {
  IBankHoliday,
  IBankHolidayCalendar,
  IOfficialBankHoliday,
} from '../../interfaces/holiday';
import { take } from 'rxjs/operators';

export type CalendarResponse = ClockHour.Output | ClockAbsence.Output;

export type clockHourPaginateResponse = PaginateResponse<ClockHour.Output[]> & {
  totalHours: number;
  totalMinutes: number;
};

export type ClockHourMetrics = {
  workedHours: number | null;
  contractHours: number | null;
  remainingHours: number | null;
  extraHours: number | null;
  pendingRegisters: number | null;
};

@Injectable({
  providedIn: 'root',
})
export class ClockHourService {
  constructor(private http: HttpClient) {}

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http
      .get<clockHourPaginateResponse>(`:API_URL/search/clock-hours`, {
        params,
      })
      .pipe(
        take(1)
      );
  }

  findTodayRegisters(query: {
    idCompany: string;
    date: { year: number; month: number; day: number };
  }) {
    return this.http.post<any>(`:API_URL/clock-hours/todayRegisters`, query);
  }

  create(data: ClockHour.Schema) {
    return this.http.post(`:API_URL/clock-hours`, data);
  }

  updateOne(id: string, data: Partial<ClockHour.Schema>) {
    return this.http.put(`:API_URL/clock-hours/${id}`, data);
  }

  deleteOne(id: string) {
    return this.http.delete(`:API_URL/clock-hours/${id}`);
  }

  updateMany(ids: string[], data: Partial<ClockHour.Schema>) {
    return this.http.put(`:API_URL/clock-hours/update-many`, {
      ids: ids,
      ...data,
    });
  }

  deleteBulk(data: { ids: string[] }) {
    if (!data || !data.ids || data.ids.length === 0) {
      return throwError(
        () =>
          new HttpErrorResponse({
            error: { message: 'No IDs provided for bulk deletion' },
            status: 400,
            statusText: 'Bad Request',
          })
      );
    }
    return this.http.delete(
      `:API_URL/clock-hours/bulk?${data.ids
        .map((id) => `ids[]=${id}`)
        .join('&')}`
    );
  }

  searchOpenEntry(data) {
    return this.http.post(`:API_URL/clock-hours/open`, data);
  }

  getOpenTimeTracking() {
    return this.http.get(':API_URL/clock-hours/open-time-tracking', {
      params: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  }

  getUserMetrics(id: string, startDate: string, endDate: string) {
    return this.http.get<ClockHourMetrics>(
      `:API_URL/clock-hours/reports/${id}`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
  }
  getTeamMetrics(startDate?: string, endDate?: string, userName?: string) {
    const queryParams: any = {};
    if (startDate) queryParams.startDate = startDate;
    if (endDate) queryParams.endDate = endDate;
    if (userName) queryParams.userName = userName;

    return this.http.get<TeamMember[]>(':API_URL/clock-hours/reports', {
      params: queryParams,
    });
  }

  updateStatus(
    startDate: string,
    endDate: string,
    status: ClockHour.Status.Approved | ClockHour.Status.Rejected,
    ids: string[]
  ) {
    return this.http.put(`:API_URL/clock-hours/status`, {
      startDate,
      endDate,
      status,
      ids,
    });
  }

  getUserReport(
    userId: string,
    startDate: string,
    endDate: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);

    return this.http.get(`:API_URL/clock-hours/reports/${userId}`, {
      params,
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    });
  }

  getTeamReport(
    userIds: string[],
    startDate: string,
    endDate: string
  ): Observable<Blob> {
    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);

    userIds.forEach((id) => {
      params = params.append('userIds[]', id);
    });

    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });

    return this.http.get(`:API_URL/clock-hours/reports`, {
      params,
      headers,
      responseType: 'blob',
    });
  }

  getUserCalendar(
    userId: string,
    query: { [key: string]: any } = {}
  ): Observable<Record<string, CalendarResponse[]>> {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<Record<string, CalendarResponse[]>>(
      `:API_URL/clock-hours/calendar/${userId}`,
      {
        params,
      }
    );
  }

  getBankHolidaysCalendars() {
    return this.http.get(`:API_URL/clock-hours/bank-holidays-calendars`);
  }

  getBankHolidays(calendarId: string, year: number) {
    return this.http.get(
      `:API_URL/clock-hours/bank-holidays-calendars/${calendarId}/bank-holidays/${year}`
    );
  }

  deleteBankHoliday(calendarId: string, bankHolidayId: string) {
    return this.http.delete(
      `:API_URL/clock-hours/bank-holidays-calendars/${calendarId}/bank-holidays/${bankHolidayId}`
    );
  }

  createBankHolidaysCalendar(name: string): Observable<IBankHolidayCalendar> {
    return this.http.post<IBankHolidayCalendar>(
      `:API_URL/clock-hours/bank-holidays-calendars`,
      {
        name: name,
      }
    );
  }

  createBankHoliday(data: {
    calendarId: string;
    day: string;
    name: string;
  }): Observable<IBankHoliday> {
    return this.http.post<IBankHoliday>(
      `:API_URL/clock-hours/bank-holidays-calendars/${data.calendarId}/bank-holidays`,
      {
        day: data.day,
        name: data.name,
      }
    );
  }

  createOfficialBankHolidays(
    calendarId: string,
    officialBankHolidays: IOfficialBankHoliday[]
  ): Observable<IBankHoliday> {
    return this.http.post<IBankHoliday>(
      `:API_URL/clock-hours/bank-holidays-calendars/${calendarId}/bulk/official-bank-holiday`,
      {
        officialBankHolidays: officialBankHolidays,
      }
    );
  }

  getOfficialBankHolidaysByCountry(
    countryCode: string,
    year: number
  ): Observable<IOfficialBankHoliday[]> {
    return this.http.get<IOfficialBankHoliday[]>(
      `:API_URL/clock-hours/bank-holidays-calendars/official-bank-holidays/${countryCode}/${year}`
    );
  }

  getOfficialBankHolidaysByRegion(
    countryCode: string,
    regionCode: string,
    year: number
  ): Observable<IOfficialBankHoliday[]> {
    return this.http.get<IOfficialBankHoliday[]>(
      `:API_URL/clock-hours/bank-holidays-calendars/official-bank-holidays/${countryCode}/${regionCode}/${year}`
    );
  }

  deleteOfficialBankHoliday(calendarId: string, bankHolidayId: string) {
    return this.http.delete(
      `:API_URL/clock-hours/bank-holidays-calendars/${calendarId}/official-bank-holidays/${bankHolidayId}`
    );
  }

  updateConfig(
    id: string,
    geolocationMandatory: boolean,
    clockInWithStopwatchMandatory: boolean
  ) {
    return this.http.put(`:API_URL/companies/${id}/tracking-time-settings`, {
      geolocationMandatory,
      storageGeolocation: geolocationMandatory,
      clockInWithStopwatchMandatory,
    });
  }
}
