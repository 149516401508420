<div class="modal-container">
  <div class="close-button" (click)="onClose()">
    <img [src]="'assets/icons/gl_close.svg'" alt="Cerrar" />
  </div>
  <div class="row">
    <div class="col-12">
      <img
        class="icon"
        src="/assets/icons/no-location.svg"
        alt="{{ 'sidebar.clockHourTitle' | translate }}"
      />
    </div>
    <div class="col-12">
      <h2 class="title">
        {{ 'clockHour.geolocation.messageModal.title' | translate }}
      </h2>
      <p class="description">
        {{ 'clockHour.geolocation.messageModal.description' | translate }}
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button class="roma-button custom-button cancel" (click)="onActivate()">
      {{ 'general.cancel' | translate }}
    </button>
    <a
      class="roma-button custom-button"
      [href]="academyLink"
      target="_blank"
      *ngIf="academyLink?.length > 0"
    >
      {{ 'clockHour.geolocation.messageModal.cta' | translate }}
    </a>
  </div>
</div>
