import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeDisplayComponent } from './time-display.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContractFormModalModule } from '../contract-form-modal';
import { ContractFormModule } from '../contract-form';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ContractFormModalModule,
    ContractFormModule,
  ],
  declarations: [TimeDisplayComponent],
  exports: [TimeDisplayComponent],
})
export class TimeDisplayModule {}
