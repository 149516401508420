import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@web-frontend/environments';
import SentryTags from './sentry-tags';
import Package from '../package.json';

const envsAllowed = ['app'];

const hostname = window.location.hostname;
const isLocal = /^(localhost|172\.16|192\.168)/.test(hostname);
const env = isLocal ? 'local' : hostname.split('.')[0];
const version = Package?.version || 'unknown';

const envIsAllowed = (env: string): boolean => envsAllowed.includes(env);

export function InitSentry(): void {
  try {
    if (envIsAllowed(env)) {
      sentrySetup();
    }
  } catch (error) {
    console.error('Error initializing Sentry', error);
  }

  function sentrySetup() {
    Sentry.init({
      dsn: environment.dsnSentry,
      release: version,
      environment: env,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserApiErrorsIntegration({
          setTimeout: true,
          setInterval: true,
          requestAnimationFrame: true,
          XMLHttpRequest: true,
          eventTarget: true,
        }),
        Sentry.sessionTimingIntegration(),
        Sentry.httpClientIntegration({
          failedRequestStatusCodes: [409, [500, 599]],
        }),
        Sentry.browserProfilingIntegration(),
      ],
      sendClientReports: false,
      tracesSampleRate: 0.7,
      tracePropagationTargets: [
        `${environment.api.type}:${environment.api.host}/${environment.api.prefix}`,
      ],
      replaysSessionSampleRate: environment.production ? 0.3 : 1,
      replaysOnErrorSampleRate: environment.production ? 0.3 : 1,
      beforeSend(event, hint) {
        const urlPath = event.request?.url
          ? new URL(event.request.url).pathname
          : '';
        let squadTag = 'core';

        Object.entries(SentryTags).forEach(([squad, paths]) => {
          if (squadTag !== 'core') return;
          if ((paths as string[]).some((path) => urlPath.includes(path))) {
            squadTag = squad;
          }
        });
        event.tags = {
          ...event.tags,
          squad: squadTag,
          release: version,
        };
        return event;
      },
    });
  }
}
