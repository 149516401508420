import { getDateStrResum } from '../../../utils';

export enum DateFields {
  CURRENT_YEAR = 'current_year',
  PREVIOUS_YEAR = 'previous_year',
  CURRENT_MONTH = 'current_month',
  PREVIOUS_MONTH = 'previous_month',
}

const now = getDateStrResum();

const DATE_FIELD_LIST: {
  title: DateFields;
  calcValue: () => string;
}[] = [
  {
    title: DateFields.CURRENT_YEAR,
    calcValue: () => `${now.year}-01-01 - ${now.formattedDate}`,
  },
  {
    title: DateFields.PREVIOUS_YEAR,
    calcValue: () =>
      `${parseInt(now.year) - 1}-01-01 - ${parseInt(now.year) - 1}-12-31`,
  },
  {
    title: DateFields.CURRENT_MONTH,
    calcValue: () => `${now.year}-${now.month}-01 - ${now.formattedDate}`,
  },
  {
    title: DateFields.PREVIOUS_MONTH,
    calcValue: () => {
      if (parseInt(now.month) === 1) {
        return `${parseInt(now.year) - 1}-12-01 - ${
          parseInt(now.year) - 1
        }-12-31`;
      } else {
        return `${now.year}-${(parseInt(now.month) - 1)
          .toString()
          .padStart(2, '0')}-01 - ${now.year}-${(parseInt(now.month) - 1)
          .toString()
          .padStart(2, '0')}-${new Date(
          parseInt(now.year),
          parseInt(now.month) - 1,
          0
        ).getDate()}`;
      }
    },
  },
];

export const dateFieldList: {
  title: string;
  value: string;
  id: string;
}[] = DATE_FIELD_LIST.map((el) => {
  return {
    title: `budgets.settings.create.form.${el.title}`,
    value: el.calcValue(),
    id: el.title,
  };
});
