import { TranslateService } from '@ngx-translate/core';
import { Company, TypeBudget } from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { AssetService, FinalService } from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { ProductService } from '@web-frontend/shared/services/products';
import { STATUSES_BY_BUDGET_TYPE } from '@web-frontend/shared/utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { dateFieldList } from '../../../shared/components/globals/rm-select-date/rm-select-date.const';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';

export const SOURCE_WEB = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'budgets.inputPh',
    },
  }),
]);

const showModule$ = (
  companyModuleService: CompanyModuleService,
  idModule: Company.IdModule
) =>
  companyModuleService.idActiveModules$.pipe(
    map((res) => res.includes(idModule))
  );

const requestFinals$ = (finalService: FinalService) =>
  finalService.search({ applyPaginate: false }).pipe(
    map((res) => res.docs),
    map((res) => {
      return res.map((el) => {
        return {
          title: `${el.name} ${el.lastName ? el.lastName : ''}`,
          id: el._id,
          value: el._id,
        };
      });
    })
  );

const requestProducts$ = (productService: ProductService) =>
  productService.findAll().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name,
          id: el._id,
          value: el._id,
        };
      })
    )
  );

const STATUS_BILL$: Observable<RmFilter.SelectMultipleItem[]> = of(
  STATUSES_BY_BUDGET_TYPE.BILL.map((el) => {
    return {
      title: el.translate,
      id: `${el.id}`,
      value: el.id,
    };
  })
);

const STATUS_PROFORM$: Observable<RmFilter.SelectMultipleItem[]> = of(
  STATUSES_BY_BUDGET_TYPE.PROFM.map((el) => {
    return {
      title: el.translate,
      id: `${el.id}`,
      value: el.id,
    };
  })
);

const STATUS_BUDGETS$: Observable<RmFilter.SelectMultipleItem[]> = of(
  STATUSES_BY_BUDGET_TYPE.BDG.map((el) => {
    return {
      title: el.translate,
      id: `${el.id}`,
      value: el.id,
    };
  })
);

const STATUS_WAYBILL$: Observable<RmFilter.SelectMultipleItem[]> = of(
  STATUSES_BY_BUDGET_TYPE.WAL.map((el) => {
    return {
      title: el.translate,
      id: `${el.id}`,
      value: el.id,
    };
  })
);

const filtersByStatusType = {
  [TypeBudget.BILL]: STATUS_BILL$,
  [TypeBudget.PROFORM]: STATUS_PROFORM$,
  [TypeBudget.BDG]: STATUS_BUDGETS$,
  [TypeBudget.WAL]: STATUS_WAYBILL$,
};

export const SOURCE_WEB_OPTIONS = (data: {
  companyModuleService: CompanyModuleService;
  assetService: AssetService;
  i18n: TranslateService;
  finalService: FinalService;
  productService: ProductService;
  budgetType: TypeBudget;
  amplitudeType?: string;
  analyticsService?: AnalyticsService;
}) => {
  const controls: RmFilter.Control[] = [
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'customers[]',
      source$: requestFinals$(data.finalService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Finals),
      content: {
        label: 'filter.final',
        placeholder: 'filter.final',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'categories[]',
      source$: requestProducts$(data.productService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Finals),
      content: {
        label: 'filter.categories-budget',
        placeholder: 'filter.categories-budget',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'status[]',
      source$: filtersByStatusType[data.budgetType],
      content: {
        label: 'filter.status-budgets',
        placeholder: 'filter.status-budgets',
      },
    }),
    new RmFilter.SelectDateRange({
      type: 'SINGLE',
      keyRequest: ['from', 'to'],
      source$: of(dateFieldList),
      content: {
        label: 'budgets.settings.create.form.date_picker',
        placeholder: 'budgets.settings.create.form.date_picker',
        amplitudeType: data.amplitudeType,
      },
      analyticsService: data.analyticsService,
      show$: of(window.innerWidth < 768),
    }),
  ];

  return new RmFilter.Filter([...controls]);
};
