import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RmFilter } from '../rm-filter.types';
import { RmFilterModalDashboardDetail } from './rm-filter-modal-dashboard.types';
import { getDateStrResum, getDateStrResumStr } from '../../../../utils';
import { RangeSource } from '../../rm-select-date';
import {
  MatDatepickerInputEvent,
  MatDateRangePicker,
} from '@angular/material/datepicker';

@Component({
  selector: 'roma-rm-filter-modal-dhbd-detail',
  templateUrl: './rm-filter-modal-dhbd-detail.component.html',
  styleUrls: ['./rm-filter-modal-dhbd-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmFilterModalDhbdDetailComponent implements OnInit {
  @ViewChild('picker') datePicker!: MatDateRangePicker<Date>;

  private _select: RmFilter.Select | RmFilter.SelectDateRange;
  @Input()
  set select(value: RmFilter.Select | RmFilter.SelectDateRange) {
    this._select = value;
    this._select.data$.subscribe((data) => {
      this.dataFetch = data;
    });
  }

  get select(): RmFilter.Select | RmFilter.SelectDateRange {
    return this._select;
  }

  searchParams = {
    keyword: '',
  };

  startDate: Date | '';
  endDate: Date | '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: RmFilterModalDashboardDetail.Params,

    private dialogRef: MatDialogRef<
      RmFilterModalDhbdDetailComponent,
      RmFilterModalDashboardDetail.Result
    >,
    private cdRef: ChangeDetectorRef
  ) {}

  dataFetch: RmFilter.SelectMultipleItem[] = [];

  ngOnInit(): void {
    this.patchParams();
  }

  get isSelectDateRange() {
    return this.select.isSelectDateRange();
  }

  back() {
    this.dialogRef.close({
      source: this.select,
    });
  }

  search(evt: KeyboardEvent) {
    this.searchParams = {
      keyword: (evt.target as HTMLInputElement).value,
    };

    this.draw();
  }

  isComplete = (): boolean => {
    return !!(this.objectDate.from && this.objectDate.to);
  };

  existsValueInSelect = (value: string): boolean => {
    return this.dataFetch.some((el) => el.value === value);
  };

  generateStringDate(): string {
    const dateFormatterFrom = getDateStrResumStr(this.objectDate.from);
    const dateFrom = `${dateFormatterFrom.day}/${
      dateFormatterFrom.month
    }/${dateFormatterFrom.year.slice(-2)}`;

    const dateFormatterTo = getDateStrResumStr(this.objectDate.to);
    const dateTo = `${dateFormatterTo.day}/${
      dateFormatterTo.month
    }/${dateFormatterFrom.year.slice(-2)}`;

    return `${dateFrom} - ${dateTo}`;
  }

  objectDate: { from: string; to: string } = { from: '', to: '' };
  _textSelectRange: string;
  rangeDateChange(
    type: keyof typeof RangeSource,
    evt: MatDatepickerInputEvent<Date>
  ): void {
    const update = { ...this.objectDate };
    const value = evt.value;

    if (type === RangeSource.START) {
      this.startDate = value;
      update.from = value ? getDateStrResum(value).formattedDate : '';
      if (this.objectDate[1]) {
        update.to = null;
      }
    }
    if (type === RangeSource.END) {
      this.endDate = value;
      update.to = value ? getDateStrResum(value).formattedDate : '';
    }

    this.objectDate = { ...update };

    if (this.isComplete()) {
      const dateFormatted = this.generateStringDate();
      this._textSelectRange = dateFormatted;

      const dateSelected = [
        {
          title: `${dateFormatted}`,
          value: `${this.objectDate.from} - ${this.objectDate.to}`,
          id: `${dateFormatted}`,
        },
      ];
      this.select.setValue(dateSelected);
    }
  }

  get selectDateRangeText(): string {
    if (!this.select.isSelectDateRange())
      return 'budgets.settings.create.form.date_range';

    if (!this.isComplete()) return 'budgets.settings.create.form.date_range';
    if (
      this.existsValueInSelect(
        `${this.objectDate.from} - ${this.objectDate.to}`
      )
    )
      return 'budgets.settings.create.form.date_range';

    return this._textSelectRange;
  }

  clearDates(): void {
    this.objectDate = { from: '', to: '' };
    this._textSelectRange = '';
    this.startDate = '';
    this.endDate = '';
  }

  changeCheckbox(evt: MouseEvent, item: RmFilter.SelectMultipleItem) {
    let values = this.select.value();
    const value = item.value;
    this.clearDates();

    evt.stopPropagation();
    evt.preventDefault();

    if (values.includes(value)) {
      values = values.filter((el) => el !== value);
    } else {
      if (this.select.type === 'MULTIPLE') {
        values.push(value);
      } else {
        values = [value];
      }
    }

    this.select.data$
      .subscribe((el) => {
        const items = el.filter((item) => values.includes(item.value));
        this.select.setValue(items);
        this.draw();
      })
      .unsubscribe();
  }

  private patchParams() {
    this.select = this.data.source;
    const selectValue = this.select.value() as string[];
    if (this.select.isSelectDateRange() && selectValue.length) {
      const splitValue = selectValue[0].split(' - ');
      this.objectDate = { from: splitValue[0], to: splitValue[1] };
      this._textSelectRange = this.generateStringDate();
      if (!this.existsValueInSelect(selectValue[0])) {
        this.startDate = new Date(this.objectDate.from);
        this.endDate = new Date(this.objectDate.to);
      }
    }
    this.select.load();
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
