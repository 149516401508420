import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  ClockHour,
  COMPANY_ID_KEY,
  IUser,
  ValidateAllFormFields,
  ClockAbsence,
} from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { USER_ID_KEY } from '@web-frontend/shared/constants';
import {
  ClockHourService,
  StorageService,
  UserSearchService,
  ClockAbsenceService,
} from '@web-frontend/shared/services';
import { IBankHoliday } from '../../../../shared/interfaces/holiday';
import { Subscription } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { AddClockHour } from './add-clock-hour.types';
import { AddClockValidators } from './add-clock-hour.validators';

import { addHours } from 'date-fns';
import { CreatePersonService } from '../../create-persons';
import { RmSelect } from '../../globals/rm-select/rm-select.types';

import { ConfirmationOutput } from '@web-frontend/shared/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from '@web-frontend/shared/components/confirmation-modal/confirmation-modal.service';

import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { DeleteBySelectionModalComponent } from '../../delete-by-selection-modal/delete-by-selection-modal.component';
import AmplitudeEvents from '../../../../../types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-add-admin-clock-hour',
  templateUrl: './add-clock-hour-admin.component.html',
  styleUrls: ['./add-clock-hour-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddClockHourAdminComponent implements OnInit {
  mode: 'EDIT' | 'ADD' = 'ADD';

  form = this.fb.group({
    idCreatedBy: new FormControl(StorageService.GetItem(USER_ID_KEY), [
      Validators.required,
    ]),
    idOwner: new FormControl('', [Validators.required]),
    idCompany: new FormControl(StorageService.GetItem(COMPANY_ID_KEY), [
      Validators.required,
    ]),
    date: new FormControl('', [Validators.required]),
    histories: [[]],
    note: new FormControl(''),
  });

  initialForm: any = {};

  get haveChanges() {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  authors: RmSelect.Items = [];
  authorsCharged = false;
  loadingAuthors = false;
  notes: ClockHour.Note[] = [];
  histories: ClockHour.Histories = [];
  totalHoursClock = '00:00';
  wrongTime = false;
  hasOverlapError = false;
  equalTime = false;
  owner = [];
  private id!: string;
  private sub$ = new Subscription();
  idOwnerInvalid = false;
  flightRequest = false;
  holidays: IBankHoliday[];
  absences: ClockAbsence.Output[] = [];
  calendarId: string;
  today!: string;
  private dateFormatted: { year: number; month: number; day: number };

  constructor(
    private clockAbsenceService: ClockAbsenceService,
    private fb: FormBuilder,
    private i18n: TranslateService,
    private cdRef: ChangeDetectorRef,
    private clockHourService: ClockHourService,
    private amplitudeService: AmplitudeService,
    private userSearchService: UserSearchService,
    private createPersonService: CreatePersonService,
    private readonly confirmationModalService: ConfirmationModalService,
    private brazeService: BrazeService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<
      AddClockHourAdminComponent,
      AddClockHour.Result
    >,
    @Inject(MAT_DIALOG_DATA) private data: AddClockHour.Params,
    private toastService: ToastService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.getHolidays();
    this.attachParams();
    this.disableEditFields();
    this.calculateTotalHoursClock();
    this.watchNewAuthors();
    this.calculateToday();
    this.initialForm = { ...this.form.value };
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  private getHolidays(): void {
    this.sub$.add(
      this.clockHourService
        .getBankHolidaysCalendars()
        .pipe(
          filter((res) => Array.isArray(res) && res.length > 0),
          tap((res) => {
            this.calendarId = res[0].id;
          }),
          switchMap(() =>
            this.clockHourService.getBankHolidays(
              this.calendarId,
              this.data?.params?.date?.year || new Date().getFullYear()
            )
          )
        )
        .subscribe((res) => {
          this.holidays = Array.isArray(res) ? res : [];
          this.draw();
        })
    );
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private calculateToday() {
    this.today = new Date().toISOString().split('T')[0];
    this.draw();
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'authors':
        if (!this.authorsCharged) {
          this.authorsCharged = true;
          this.requestAuthors();
        }
        break;
    }
  }

  close(result: AddClockHour.Result) {
    this.dialogRef.close(result);
  }

  changeClockHistories(evt: ClockHour.Histories) {
    for (let i = 0; i < evt.length; i++) {
      let calculateDuration = false;

      if (isNaN(evt[i].startHour.hour)) {
        try {
          const hour = parseInt(evt[i].startDate);
          evt[i].startHour.hour = hour;
          evt[i].startHour.minute = 0;
          evt[i].startDate =
            evt[i].startHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].startHour.minute.toString().padStart(2, '0');
        } catch (e) {
          evt[i].startHour.hour = 0;
          evt[i].startHour.minute = 0;
          evt[i].startDate =
            evt[i].startHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].startHour.minute.toString().padStart(2, '0');
        }
        calculateDuration = true;
      } else {
        if (isNaN(evt[i].startHour.minute)) {
          evt[i].startHour.minute = 0;
          evt[i].startDate =
            evt[i].startHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].startHour.minute.toString().padStart(2, '0');
          calculateDuration = true;
        }
      }

      if (isNaN(evt[i].endHour.hour)) {
        try {
          const hour = parseInt(evt[i].endDate);
          evt[i].endHour.hour = hour;
          evt[i].endHour.minute = 0;
          evt[i].endDate =
            evt[i].endHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].endHour.minute.toString().padStart(2, '0');
        } catch (e) {
          evt[i].endHour.hour = 0;
          evt[i].endHour.minute = 0;
          evt[i].endDate =
            evt[i].endHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].endHour.minute.toString().padStart(2, '0');
        }
        calculateDuration = true;
      } else {
        if (isNaN(evt[i].endHour.minute)) {
          evt[i].endHour.minute = 0;
          evt[i].endDate =
            evt[i].endHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].endHour.minute.toString().padStart(2, '0');
          calculateDuration = true;
        }
      }

      if (evt[i].startDate.length < 5) {
        evt[i].startDate =
          evt[i].startHour.hour.toString().padStart(2, '0') +
          ':' +
          evt[i].startHour.minute.toString().padStart(2, '0');
        calculateDuration = true;
      }

      if (evt[i].endDate.length < 5) {
        evt[i].endDate =
          evt[i].endHour.hour.toString().padStart(2, '0') +
          ':' +
          evt[i].endHour.minute.toString().padStart(2, '0');
        calculateDuration = true;
      }

      if (calculateDuration) {
        evt[i].duration = ClockHour.getDifferenceHours(
          evt[i].endDate,
          evt[i].startDate
        );
      }
    }

    this.form.patchValue({
      histories: [...evt],
    });

    this.histories = evt;
    this.wrongTime = this.checkHistories();
    this.hasOverlapError = this.checkOverlapHistories();

    this.calculateTotalHoursClock();
  }

  checkHistories(): boolean {
    if (!this.histories || this.histories.length == 0) {
      return false;
    }
    let wrongTime = false;
    for (let i = 0; i < this.histories.length; i++) {
      if (this.histories[i].startHour.hour > this.histories[i].endHour.hour) {
        wrongTime = true;
        break;
      }
      if (
        this.histories[i].startHour.hour == this.histories[i].endHour.hour &&
        this.histories[i].startHour.minute > this.histories[i].endHour.minute
      ) {
        wrongTime = true;
        break;
      }
      if (
        this.histories[i].startHour.hour == this.histories[i].endHour.hour &&
        this.histories[i].startHour.minute == this.histories[i].endHour.minute
      ) {
        wrongTime = true;
        this.equalTime = true;
        this.toastService.show({
          text: this.i18n.instant('clockHour.timeTracking.error'),
          type: 'error',
          msDuration: 4000,
        });
        break;
      }
    }

    return wrongTime;
  }

  fetchSubmit(): void {
    if (this.mode === 'ADD') {
      this.resolveAddSubmit();
    } else {
      this.resolveEditSubmit();
    }
  }

  submit() {
    const isValid = this.checkValidators();

    if (!isValid) return;

    if (this.wrongTime) return;

    if (!this.histories || this.histories.length === 0) {
      this.toastService.show({
        text: this.i18n.instant('clockHour.timeTracking.error'),
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    const data = ClockHour.getConfirmModalData(
      this.i18n.instant('clockHour.hasHolidayModal.title'),
      this.i18n.instant('clockHour.hasHolidayModal.body')
    );

    const formData = this.fromFormValuesToServerValues();
    if (
      ClockHour.hasHolidays(this.holidays, formData?.date) ||
      ClockAbsence.hasAbsences(this.absences, formData?.date)
    ) {
      this.confirmationModalService
        .open({ data: data })
        .subscribe((response) => {
          if (response === ConfirmationOutput.Execute) {
            this.fetchSubmit();
          }
        });
    } else {
      this.fetchSubmit();
    }
  }

  changeAuthors(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        idOwner: first,
      });

      this.clockAbsenceService
        .getAbsencesPerUser(
          this.data?.params?.date?.year || new Date().getFullYear(),
          first as string
        )
        .subscribe((res: ClockAbsence.Output[]) => {
          this.absences = res;
        });

      this.idOwnerInvalid = false;
      this.calculateTotalHoursClock();
    } else {
      this.idOwnerInvalid = true;
    }

    if (this.data.mode === 'ADD') {
      this.setDateValidators();
      this.form.get('date').updateValueAndValidity();
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    if (this.form.get('idOwner').value) {
      this.idOwnerInvalid = false;
    } else {
      this.idOwnerInvalid = true;
    }
    return this.form.valid;
  }

  private resolveAddSubmit() {
    const data = this.fromFormValuesToServerValues();

    this.flightRequest = true;
    this.draw();

    this.sub$.add(
      this.clockHourService
        .create(data)
        .pipe(
          finalize(() => {
            this.flightRequest = false;
            this.draw();
          })
        )
        .subscribe(
          (res) => {
            this.toastService.show({
              text: this.i18n.instant('clockHour.createdClock'),
              type: 'success',
              msDuration: 4000,
            });
            this.analyticsService.trackEvent({
              eventName: AmplitudeEvents.trackingTimeTeam_create,
              sources: ['amplitude', 'braze'],
              eventProperties: {
                project: data?.histories.some((h) => h?.projectId),
                deal: data?.histories.some((h) => h?.dealId),
              },
            });
            this.close({ reload: true });
          },
          (error) => {
            if (error.status == 409) {
              if (
                error?.error?.code === 'TrackingTimeRegisterNotUniqueDayError'
              ) {
                this.form.get('date').setErrors({ clockHourRepeatDate: true });
                return;
              }
              this.toastService.show({
                text: this.i18n.instant('pro.clockHour.genericErrorToast'),
                type: 'error',
                msDuration: 4000,
              });
            }
          }
        )
    );
  }

  private resolveEditSubmit() {
    const data = this.fromFormValuesToServerValues();

    this.flightRequest = true;
    this.draw();

    this.sub$.add(
      this.clockHourService
        .updateOne(this.id, data)
        .pipe(
          finalize(() => {
            this.flightRequest = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.toastService.show({
            text: this.i18n.instant('clockHour.editedClock'),
            type: 'success',
            msDuration: 4000,
          });
          this.analyticsService.trackEvent({
            eventName: AmplitudeEvents.trackingTime_edit,
            sources: ['amplitude', 'braze'],
            eventProperties: {
              project: data?.histories.some((h) => h?.projectId),
              deal: data?.histories.some((h) => h?.dealId),
              event_location: 'user_list',
            },
          });

          this.close({ reload: true });
        })
    );
  }

  private fromFormValuesToServerValues(): ClockHour.Schema {
    const notes: ClockHour.Note[] = this.form.get('note').value
      ? [
          {
            _id: ``,
            text: this.form.get('note').value,
            createdAt: new Date(),
            idAuthor: StorageService.GetItem(USER_ID_KEY),
          },
        ]
      : [];

    // QUESTION, IN CREATE WHAT STATUS REQUIRE?
    const status =
      this.data.mode === 'ADD'
        ? ClockHour.Status.Pending
        : this.data.params?.status;

    const res: ClockHour.Schema = {
      idCreatedBy: this.form.get('idCreatedBy').value as string,
      idOwner: this.form.get('idOwner').value as string,
      idCompany: this.form.get('idCompany').value as string,
      date: ClockHour.fromStringDateToObjectDate(this.form.get('date').value),
      histories: this.form.get('histories').value as ClockHour.Histories,
      notes,
      deleted: false,
      approved: false,
      status,
    };

    return res;
  }

  delete() {
    this.sub$.add(
      this.clockHourService.deleteOne(this.id).subscribe((res) => {
        const eventData = {
          event: 'trackingTime_delete',
        };

        this.amplitudeService.sendEvent(eventData);

        this.close({ reload: true, closePreviousModal: true });

        this.toastService.show({
          text: this.i18n.instant('clockHour.deleteSuccess'),
          type: 'success',
          msDuration: 4000,
        });
      })
    );
  }

  private fromOutputParamToFormValue(params: ClockHour.Output) {
    return {
      ...params,
      date: addHours(
        new Date(params.date.year, params.date.month, params.date.day, 1),
        6
      )
        .toISOString()
        .split('T')[0],
    };
  }

  private attachParams() {
    this.mode = this.data.mode;

    if (this.mode === 'EDIT') {
      this.form.controls['date'].disable();
    }

    if (this.data?.params) {
      this.form.patchValue({
        ...this.fromOutputParamToFormValue(this.data.params),
      });

      if (this.data?.params?.notes?.length) {
        this.form.patchValue({
          note: ClockHour.getNotes(this.data.params),
        });
      }
      this.notes = this.data.params.notes;
      this.histories = this.data.params.histories;
      this.id = this.data.params._id;
      this.owner = [
        {
          title: this.data.params.owner.name,
          value: this.data.params.owner._id,
          id: this.data.params.owner._id,
        },
      ];

      this.calculateTotalHoursClock();
    } else {
      this.histories = [];
    }

    this.setDateValidators();
    this.draw();
    this.cdRef.markForCheck();
  }

  addAuthors() {
    this.createPersonService
      .open()
      .pipe(filter((res) => Boolean(res)))
      .subscribe((res) => {
        const user = res as IUser;
        const owner: RmSelect.Item = {
          title: user.name,
          value: user._id,
          id: user._id,
        };
        this.owner = [owner];
        this.changeAuthors([owner]);
      });
  }

  private resolveChangeAuthorAndAttachParams(params: ClockHour.Output) {
    this.mode = 'EDIT';
    this.notes = params.notes;
    this.histories = params.histories;
    this.id = params._id;

    this.calculateTotalHoursClock();

    this.form.get('date').clearAsyncValidators();
    this.form.get('date').setValidators([Validators.required]);

    this.form
      .get('date')
      .setAsyncValidators([
        AddClockValidators.checkIfIsRepeat(
          this.clockHourService,
          params.idOwner
        ),
      ]);

    this.form.get('date').updateValueAndValidity();

    this.checkValidators();

    this.draw();
  }

  private setDateValidators() {
    this.form.get('date').setValidators([Validators.required]);
    if (this.form.value?.idOwner) {
      this.form
        .get('date')
        .setAsyncValidators([
          AddClockValidators.checkIfIsRepeat(
            this.clockHourService,
            this.form.get('idOwner').value,
            this.data?.params?._id ? this.data.params._id : ''
          ),
        ]);
    }
  }

  private calculateTotalHoursClock() {
    this.totalHoursClock = ClockHour.getTotalHourFromHistories(
      [this.histories],
      0
    );

    this.draw();
  }

  private watchNewAuthors() {
    this.sub$.add(
      this.createPersonService.refreshList$
        .pipe(filter((res) => Boolean(res)))
        .subscribe(() => {
          this.requestAuthors();
        })
    );
  }

  private disableEditFields() {
    if (this.mode === 'EDIT') {
      this.form.get('idOwner').disable();
      this.draw();
    }
  }

  private requestAuthors() {
    this.loadingAuthors = true;
    this.draw();

    this.sub$.add(
      this.userSearchService
        .search({ applyPaginate: false })
        .pipe(
          finalize(() => {
            this.loadingAuthors = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.authors = res?.docs.map((res) => {
            return {
              title: res?.name,
              value: res?._id,
              id: res?._id,
            };
          });
          this.draw();
        })
    );
  }

  toMinutes = (hour: number, minute: number): number => hour * 60 + minute;

  areIntervalsOverlapping = (
    firstStart: number,
    firstEnd: number,
    secondStart: number,
    secondEnd: number
  ): boolean => {
    return firstStart < secondEnd && secondStart < firstEnd;
  };

  checkOverlapHistories(): boolean {
    if (!this.histories || this.histories.length < 2) {
      return false;
    }

    const intervals = this.histories.map((history) => ({
      startMinutes: this.toMinutes(
        history.startHour.hour,
        history.startHour.minute
      ),
      endMinutes: this.toMinutes(history.endHour.hour, history.endHour.minute),
    }));

    return intervals.some((interval, i) =>
      intervals
        .slice(i + 1)
        .some(({ startMinutes, endMinutes }) =>
          this.areIntervalsOverlapping(
            interval.startMinutes,
            interval.endMinutes,
            startMinutes,
            endMinutes
          )
        )
    );
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
