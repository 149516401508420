import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RmSelectRegisterModule } from '../globals/rm-select-register';
import { BudgetPreviewerModule } from '@web-frontend/shared/components/budget-previewer/budget-previewer.module';
import { ShowBudgetComponent } from './show-budget.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RmBadgeModule } from '@web-frontend/shared/components/globals/rm-badge';
import { PermissionsModule } from '../permissions';
import { ModalSendModule } from '../modal-send';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ClickSequenceModule } from '@web-frontend/shared/directives/click-sequence/click-sequence.module';
import { PaymentsModule } from '@web-frontend/core/admin/payments/payments.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignatureWaybillModule } from '../signature-waybill';
import { BaseButtonModule } from '../../../common/components/button/baseButton.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EquipmentNotesModule } from '../equipments-notes';

@NgModule({
  declarations: [ShowBudgetComponent],
  imports: [
    BudgetPreviewerModule,
    ClickSequenceModule,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    RmBadgeModule,
    PermissionsModule,
    ModalSendModule,
    MatDialogModule,
    MatMenuModule,
    PaymentsModule,
    PdfViewerModule,
    SignatureWaybillModule,
    MatProgressSpinnerModule,
    RmSelectRegisterModule,
    BaseButtonModule,
    MatCheckboxModule,
    EquipmentNotesModule,
  ],
  providers: [],
  exports: [],
})
export class ShowBudgetModule {}
