import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

import { IConfig, NgxMaskModule } from 'ngx-mask';

import { NgSelectModule } from '@ng-select/ng-select';

import { ApiInterceptorService } from './shared/interceptors/api';
import { TimeoutInterceptorService } from './shared/interceptors/timeout';

import { AuthGuardModule } from './shared/guards/auth/auth.module';
import { PermissionModule } from './shared/guards/permission/permission.module';
import { I18nRoot } from './shared/i18n';

import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { environment } from '../environments';
import { ClockAbsenceModule } from './core/admin/clock-absence/clock-absence.module';
import { ClockHourModule } from './core/admin/clock-hour/clock-hour.module';
import { PaymentPrototipeModule } from './core/admin/payments-prototype/payment-prototipe.module';
import { AddressMapModule } from './shared/components/address-map/address-map.module';
import { MobileClockHourModule } from './shared/components/clock-hours/mobile-clock-hour/mobile-clock-hour.module';
import { ModalErrorConnectionModule } from './shared/components/modal-error-connection/modal-error-connection.module';
import { ModalErrorMaintenanceModule } from './shared/components/modal-error-maintenance/modal-error-maintenance.module';
import { ModalErrorNotFoundModule } from './shared/components/modal-error-not-found/modal-error-not-found.module';
import { initializeAppFactory } from './shared/providers';
import { AuthService } from './shared/services';
import { CompanyModuleService } from './shared/services/company';
import { PreviewModule } from './shared/services/generate-document/preview/preview.module';
import { PermissionService } from './shared/services/permissions';
import { TutorialService } from './shared/services/tutorial';
import { CapacitorUpdateModule } from './shared/services/version';
import { InvoiceModule } from './invoice/invoice.module';
import { microfrontendsLoader } from './microfrontends/microfrontends.loader';
import { LoadingModule } from './shared/components/loading';

import * as Sentry from '@sentry/angular-ivy';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localeEn);
registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatButtonModule,
    PreviewModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    AuthGuardModule,
    PermissionModule,
    I18nRoot,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    ClockHourModule,
    ClockAbsenceModule,
    CapacitorUpdateModule,
    PaymentPrototipeModule,
    MobileClockHourModule,
    MatNativeDateModule,
    AddressMapModule,
    ModalErrorConnectionModule,
    ModalErrorNotFoundModule,
    ModalErrorMaintenanceModule,
    InvoiceModule,
    LoadingModule,
    Sentry.TraceModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptorService,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, CompanyModuleService, PermissionService, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: microfrontendsLoader,
      deps: [HttpClient],
      multi: true,
    },
    TutorialService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
