import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClickLogoutService } from '@web-frontend/shared/directives/click-logout';

import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class TimeoutInterceptorService implements HttpInterceptor {
  constructor(private clickLogoutService: ClickLogoutService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          err.error.message === 'Unauthorized'
        ) {
          this.clickLogoutService.logout();
        }
        if (err instanceof HttpErrorResponse && err?.status >= 500) {
          Sentry.captureException(err?.name ?? 'HttpErrorResponse', {
            level: 'info',
            tags: {
              error: err?.message,
              endpoint: err?.url,
              status: err?.status,
              body: JSON.stringify(request?.body ?? {}),
            },
          });
        }
        return throwError(err);
      })
    );
  }
}
