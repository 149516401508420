<div class="safe-area-padding">
  <div class="modal__header">
    <div class="modal__header__top">
      <img
        class="modal__header__top__back"
        src="/assets/icons/gl_arrow-full-left.svg"
        alt="link"
        (click)="back()"
      />
      <div class="modal__header__top__title">
        {{ select.content.label | translate }}
      </div>
    </div>
    <div class="modal__header__body">
      <input
        type="text"
        class="modal__header__body__input"
        [placeholder]="'general.search' | translate"
        (keyup)="search($event)"
      />
    </div>
  </div>
  <div class="modal__body">
    <ng-container *ngIf="select.isLoading$ | async">
      <div class="modal__body__container__spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!(select.isLoading$ | async)">
      <ng-container
        *ngFor="
          let item of select.data$
            | async
            | filterByParams: searchParams.keyword
        "
      >
        <div class="link__list__item" (click)="changeCheckbox($event, item)">
          <mat-checkbox
            class="link__list__item__checkbox"
            [checked]="select.value().includes(item.value)"
          >
            <div class="link__list__item__text">
              {{ item.title | translate }}
            </div>
          </mat-checkbox>
        </div>
      </ng-container>
      <ng-container *ngIf="isSelectDateRange">
        <div class="link__list__item" (click)="datePicker.open()">
          <div class="link__list__item__text__blue">
            <img src="\assets\icons\gl_calendar_blue.svg" />
            {{ selectDateRangeText | translate }}
          </div>
        </div>
        <mat-form-field
          appearance="fill"
          style="position: absolute; visibility: hidden; top: 0px"
        >
          <mat-date-range-input [rangePicker]="picker">
            <input
              #startDateInput
              matStartDate
              [value]="startDate"
              (dateChange)="rangeDateChange('START', $event)"
            />
            <input
              #endDateInput
              matEndDate
              [value]="endDate"
              (dateChange)="rangeDateChange('END', $event)"
            />
          </mat-date-range-input>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
</div>
