import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AdditionalInfoBudget,
  BudgetCommonData,
  BudgetPreferences,
  BudgetsSetting,
  BudgetStatus,
  Feature,
  FeatureUser,
  HeaderBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  IEquipmentNote,
  IFinal,
  IHeaderBudget,
  IItemBudget,
  IStatus,
  ItemBudget,
  MessageBudget,
  PartOfServiceGlobal,
  PaymentInfoBudget,
  STATUS_BUDGET_INVOICED,
  TacliaPayments,
  TypeAmplitudeBudget,
  TypeBudget,
  TypeItemBudget,
  WaybillStatus,
} from '@tacliatech/types';
import {
  ACCEPTED_STATUS_BY_BUDGET_TYPE,
  CANCELLED_STATUS_BY_BUDGET_TYPE,
  DEFAULT_STATUS_BY_BUDGET_TYPE,
  INVOICED_STATUS_BY_BUDGET_TYPE,
  OVERDUE_STATUS_BY_BUDGET_TYPE,
  PAID_STATUS_BY_BUDGET_TYPE,
  REJECTED_STATUS_BY_BUDGET_TYPE,
  STATUSES_BY_BUDGET_TYPE,
} from '@web-frontend/shared/utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { BudgetUtilisService } from '@web-frontend/shared/components/create-budget/budget-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { finalize, map } from 'rxjs/operators';
import {
  AuthService,
  DealService,
  FinalService,
  ProjectService,
  SandboxService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { User } from '@web-frontend/shared/class';
import { ProductService } from '@web-frontend/shared/services/products';
import { formatNumber } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalSendComponent } from '../modal-send';
import { SenderEmail } from '@web-frontend/core/admin/budgets/budget-edit/budgets-edit.component';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import {
  DeleteBySelectionModalComponent,
  DeleteDialogData,
} from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { CreateBudgetService } from '@web-frontend/shared/components/create-budget/create-budget.service';
import { SharePaymentLinkModalService } from '../create-sale/share-payment-link-modal/share-payment-link-modal.service';
import { PaymentsService } from '@web-frontend/core/admin/payments/services/payments.service';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { BudgetPreviewerComponent } from '../budget-previewer/budget-previewer.component';
import { CreateSignatureWaybillService } from '../signature-waybill-modal';
import { Signature } from '../signature';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RmSelect } from '@web-frontend/shared/components/globals/rm-select/rm-select.types';
import { InvoiceService } from '@web-frontend/invoice/invoice.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { CatalogueService } from '../../services/catalogue';
import { ShareFile, ShareService } from '../../services/share';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { environment } from '../../../../environments';
import { PlatformService } from '../platform-disable-content/platform.service';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags.service';
import { Share } from '@capacitor/share';

@Component({
  selector: 'roma-show-budget',
  templateUrl: './show-budget.component.html',
  styleUrls: ['./show-budget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowBudgetComponent implements OnInit, OnDestroy {
  public innerWidth = 0;
  isResponsive = false;
  size1024 = false;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth <= 768;
    this.size1024 = this.innerWidth >= 768 && this.innerWidth <= 1024;
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(): Observable<boolean> | boolean {
    return this.showSave ? false : true;
  }

  dates_formats = this.budgetService.findAllDateFormats();
  DEFAULT_DATE_FORMAT = this.budgetService.date_format;
  numbers_format = this.budgetService.findAllNumberFormats();
  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';
  type: TypeBudget;
  TYPES_OF_BUDGETS = TypeBudget;
  idBudget: string;
  title: string;
  isLoading = true;
  isLoadingSettings = false;
  budget: BudgetCommonData = new BudgetCommonData();
  settings: BudgetPreferences;
  AllSettings: IBudgetsSetting;
  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;
  items: ItemBudget[] = [];
  header: IHeaderBudget;
  messages: MessageBudget = new MessageBudget();
  typePayments: PaymentInfoBudget[] = [];
  addicionalInfo: AdditionalInfoBudget = new AdditionalInfoBudget();
  showTotal = false;
  mode: 'EDIT' | 'ADD';
  category: string;
  project: string;
  deal: string;
  final: IFinal;
  status: IStatus;
  finalsEmail: SenderEmail[] = [];
  featureRef = Feature;
  featureRefUser = FeatureUser;
  openMoreOptions = false;
  openConvertToMenu = false;
  isCreatingPaymentLink = false;
  stripeObject: TacliaPayments.IStripe;
  resize$ = this.sandBoxService.screenBusChannel$;

  colorStatus: string;
  total: string;
  currencySimbol: string;

  budgetTypeAmplitude: TypeAmplitudeBudget;
  previewDocument = undefined;
  pdfSrc: string;
  uploadClientSignature = false;
  uploadCompanySignature = false;
  partOfService!: PartOfServiceGlobal.PartOfServiceResponse;
  saveLoading = false;
  showSave = false;
  canEditStatus = true;
  statusList: RmSelect.Items;
  statusSelected: RmSelect.Items;
  rEq: any[] = [];
  rEt: any[] = [];
  iVa: any[] = [];
  budgetData: any;
  canExitComponent = new Subject<boolean>();
  hasFeatureSignDocuments$ = this.permissionService.hasFeatureFn(
    Feature.Deal.SignDocuments
  );
  isLoadingShare = false;
  isCountryValid = false;

  form: FormGroup = this.fb.group({
    signCompany: new FormControl(),
    signClient: new FormControl(),
  });

  private sub$ = new Subscription();
  featureFlagEmail = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private route: ActivatedRoute,
    private budgetService: BudgetService,
    private i18n: TranslateService,
    private budgetUtilisService: BudgetUtilisService,
    private projectService: ProjectService,
    public productService: ProductService,
    private dealService: DealService,
    private finalService: FinalService,
    private router: Router,
    private dialog: MatDialog,
    public permissionService: PermissionService,
    private toastService: ToastService,
    private createBudgetService: CreateBudgetService,
    private sharePaymentLinkModalService: SharePaymentLinkModalService,
    private paymentsService: PaymentsService,
    private sandBoxService: SandboxService,
    private createSignatureWaybill: CreateSignatureWaybillService,
    private fb: FormBuilder,
    private usersService: UserService,
    private invoiceService: InvoiceService,
    private cataloguesService: CatalogueService,
    private readonly shareService: ShareService,
    private readonly analyticsService: AnalyticsService,
    private readonly platformService: PlatformService,
    private readonly featureFlags: FeatureFlagsService
  ) {
    this.validateCountry();
  }
  validateCountry(): void {
    this.isCountryValid = this.usersService.validateCountryES();
  }

  showDivComments = false;
  toggleDivComments(): void {
    this.showDivComments = !this.showDivComments;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(() => {
      this.getSettings();
      this.onResize();
    });
    this.watchStripeObject();
    this.resolveStatusList();
    this.getEmailFeatureFlag();
  }

  printPDF(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_options_print`,
    });
    window.open(this.pdfSrc, '_blank').print();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  setItems(): ItemBudget[] {
    const itms: ItemBudget[] = [];

    this.budget.items.forEach((element) => {
      itms.push(new ItemBudget(element));
    });

    return itms;
  }

  get featureUsedCreateByType(): string {
    const featureMap = {
      [TypeBudget.BILL]: this.featureRefUser.Bill.create,
      [TypeBudget.BUDGET]: this.featureRefUser.Quote.create,
      [TypeBudget.PROFORM]: this.featureRefUser.Proform.create,
      [TypeBudget.WAL]: this.featureRefUser.Waybills.create,
    };

    return (
      featureMap[this.type] || this.featureRef.SystemPermission.DefaultAllow
    );
  }

  get featureUsedUpdateByType(): string {
    const featureMap = {
      [TypeBudget.BILL]: this.featureRefUser.Bill.update,
      [TypeBudget.BUDGET]: this.featureRefUser.Quote.update,
      [TypeBudget.PROFORM]: this.featureRefUser.Proform.update,
      [TypeBudget.WAL]: this.featureRefUser.Waybills.update,
    };

    return (
      featureMap[this.type] || this.featureRef.SystemPermission.DefaultAllow
    );
  }

  get featureUsedDeleteByType(): string {
    const featureMap = {
      [TypeBudget.BILL]: this.featureRefUser.Bill.delete,
      [TypeBudget.BUDGET]: this.featureRefUser.Quote.delete,
      [TypeBudget.PROFORM]: this.featureRefUser.Proform.delete,
      [TypeBudget.WAL]: this.featureRefUser.Waybills.delete,
    };

    return (
      featureMap[this.type] || this.featureRef.SystemPermission.DefaultAllow
    );
  }

  getSettings() {
    this.isLoadingSettings = true;

    const id = StorageService.CompanyId;

    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(id)
        .pipe(
          finalize(() => {
            this.isLoadingSettings = false;
          })
        )
        .subscribe(
          (resp: IBudgetsSetting) => {
            this.resolveRouteParams();
            if (resp) {
              this.AllSettings = resp;
              this.settings = resp.budgetPreferences;
              this.defaultSettings = resp;
            } else {
              this.defaultSettings.idOwner = id;
              this.settings = this.defaultPreferences;
              this.createDefaultPreferences(this.defaultSettings);
            }
            this.currencySimbol = this.getCurrencySimbol();

            this.isLoadingSettings = false;
            this.draw();
          },
          () => {
            this.isLoadingSettings = false;
          }
        )
    );
  }

  createDefaultPreferences(defaultSettings: BudgetsSetting) {
    try {
      this.authService.user$.subscribe((res: User) => {
        // Fill company data default
        this.defaultSettings.data_account.company_name = res?.company?.name;
        this.defaultSettings.data_account.email = res?.company?.email;

        //Create default settings
        this.budgetService
          .createOneSetting(defaultSettings)
          .subscribe((data) => {
            this.defaultSettings = data;
            this.AllSettings = this.defaultSettings;
          });
      });
    } catch (error) {
      throw new Error('ERROR CREATING DEFAULT SETTING');
    }
  }

  private resolveRouteParams() {
    const paramsMap = this.route.snapshot.queryParamMap;
    if (paramsMap.keys.length) {
      this.type = paramsMap.get('type') as TypeBudget;

      const amplitudeMap = {
        [TypeBudget.BILL]: TypeAmplitudeBudget.BILL,
        [TypeBudget.BUDGET]: TypeAmplitudeBudget.BUDGET,
        [TypeBudget.PROFORM]: TypeAmplitudeBudget.PROFORM,
        [TypeBudget.WAL]: TypeAmplitudeBudget.WAL,
      };

      this.budgetTypeAmplitude = amplitudeMap[this.type];

      if (paramsMap.has('budget')) {
        this.idBudget = paramsMap.get('budget');
        this.mode = 'EDIT';
      } else {
        this.mode = 'ADD';
        this.header = new HeaderBudget();
        this.items = [];

        this.budget.items = [];
        this.budget.company = StorageService.CompanyId;
      }

      this.title = this.getTitle(this.type);
      this.loadData(this.type, paramsMap.get('budget'));
    }

    this.draw();
  }

  getTitle(type: TypeBudget): string {
    const titleMap = {
      [TypeBudget.BUDGET]: 'budgets.title.budget',
      [TypeBudget.PROFORM]: 'budgets.title.proform',
      [TypeBudget.BILL]: 'budgets.title.bill',
      [TypeBudget.WAL]: 'budgets.title.waybill',
    };

    return titleMap[type] || titleMap[TypeBudget.BILL];
  }

  back(): void {
    if (this.type) {
      this.router.navigateByUrl(`/admin/budgets?type=${this.type}`);
    }
  }

  getStatusColor(id: number): string {
    const statusByType: IStatus[] = STATUSES_BY_BUDGET_TYPE[this.type];
    return statusByType.find((status) => status.id === id)?.color || '#E3EBFA';
  }

  // LOAD INITIAL Object
  // -----------------------------------------------------
  loadData(type: TypeBudget, id?: string): void {
    if (!id) {
      this.findMax();
      return;
    }

    const loadMap = {
      [TypeBudget.BUDGET]: this.fillBudget,
      [TypeBudget.PROFORM]: this.fillProform,
      [TypeBudget.BILL]: this.fillBill,
      [TypeBudget.WAL]: this.fillWaybill,
    };

    const loadFunction = loadMap[type];

    if (loadFunction) {
      loadFunction.call(this, id);
    }
  }

  get billTitle(): string {
    const { prefix, numberDoc } = this.budget.header;
    const prefixTitle = prefix || (this.isMX ? '' : '0');
    return `${prefixTitle}${numberDoc ? '-' + numberDoc : ''}`;
  }

  async fillBill(id: string): Promise<void> {
    this.isLoading = true;

    const budget = await this.invoiceService.findOne(id);
    const { prefix } = budget.header;
    this.budget = {
      ...budget,
      header: {
        ...budget.header,
        prefix,
      },
    };
    this.fillData();
    this.isLoading = false;
    this.draw();
    this.animateTotal();
  }

  fillWaybill(id: string): void {
    this.isLoading = true;

    this.budgetService
      .findOneWaybill(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((data) => {
        this.budget = data;
        this.fillData();
        this.animateTotal();
      });
  }

  private fillBudget(id?: string) {
    this.isLoading = true;

    this.budgetService
      .findOneBudget(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((data) => {
        this.budget = data;
        this.fillData();
        this.animateTotal();
      });
  }

  animateTotal() {
    setTimeout(() => {
      this.showTotal = true;
      this.draw();
    }, 1000);
  }

  findMax() {
    this.budgetService.findMax(this.type).subscribe((data) => {
      this.budget.header.prefix =
        this.AllSettings?.invoiceSettings?.prefixNumber ?? '000';

      if (!this.AllSettings?.invoiceSettings) {
        this.budget.header.numberDoc = data.maxNumber?.toString();
      }

      if (data?.maxNumber == null) {
        this.budget.header.numberDoc = this.AllSettings?.invoiceSettings?.starterNumber?.toString();
      } else {
        if (
          this.AllSettings?.invoiceSettings?.starterNumber >= data.maxNumber
        ) {
          this.budget.header.numberDoc = (
            this.AllSettings?.invoiceSettings?.starterNumber + 1
          )?.toString();
        } else {
          this.budget.header.numberDoc = data.maxNumber?.toString();
        }
      }

      this.budgetUtilisService.refreshBudget.emit(
        this.budget as IBudgetCommonData
      );
      this.animateTotal();
    });
  }

  private fillProform(budgetId?: string): void {
    this.isLoading = true;
    this.draw();

    this.budgetService
      .findOneProform(budgetId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((data) => {
        this.budget = data;
        this.fillData();
        this.animateTotal();
      });
  }

  private fillData(): void {
    this.getProject(this.budget.addicionalInfo?.project_id);
    this.getCategory(this.budget.addicionalInfo?.category_id);
    this.getDeal(this.budget.header?.idDeal);
    this.getFinal(this.budget.header?.contact_id);
    this.resolveStatus(this.budget.status);
    this.items = this.setItems();
    this.header = this.budget.header;
    this.messages = this.budget.messages;
    this.typePayments = this.budget.typePayments;
    this.addicionalInfo = this.budget.addicionalInfo;
    this.total = this.formatCustomNumber(this.budget.total);
    this.showDocument();
  }

  private getProject(id: string): void {
    id &&
      this.projectService
        .search({ 'ids[]': id, existId: id })
        .subscribe((res) => {
          this.project = res.docs.find((data) => data._id === id)?.name;
          this.draw();
        });
  }

  private getCategory(id: string): void {
    id &&
      this.productService.findOneCategory(id).subscribe((res) => {
        this.category = res?.name;
        this.draw();
      });
  }

  private getDeal(id: string): void {
    id &&
      this.dealService.findOne(id).subscribe((res) => {
        this.deal = res?.name;
        this.draw();
      });
  }

  private getFinal(id: string): void {
    id &&
      this.finalService.findOneCache(id).subscribe((res) => {
        this.final = res;
        this.draw();
      });
  }

  get my_format_date() {
    const format = this.dates_formats.find(
      (format) => format.id === parseInt(this.settings?.date_format)
    );

    return format ? format.text : this.DEFAULT_DATE_FORMAT;
  }

  set format_decimals(num) {
    num;
  }
  get format_decimals() {
    const format = this.numbers_format.find(
      (format) => format.id === this.settings?.number_format
    );
    return format;
  }

  formatCustomNumber(paramNumber: number): string {
    let locale = '';

    if (this.format_decimals?.decimal_separator == ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }

    const decimals = (this.settings?.decimals || 1).toString();

    let digitsInfo = '1.';

    digitsInfo = digitsInfo + decimals + '-' + decimals;

    const formattedNumber = formatNumber(paramNumber, locale, digitsInfo);
    return formattedNumber;
  }

  getCurrencySimbol() {
    const format = this.currencys.find(
      (format) => format.id === this.settings?.currency
    );
    return format ? format.value : this.DEFAULT_CURRENCY_SYMBOL;
  }

  resolveStatus(id: BudgetStatus | WaybillStatus): void {
    const statusByType: IStatus[] = STATUSES_BY_BUDGET_TYPE[this.type];
    this.status = statusByType.find((status) => status.id === id);
    this.resolveStatusList();
    this.colorStatus = this.getStatusColor(this.status?.id);
    this.draw();
  }

  showMoreOptionMenu(): void {
    this.openMoreOptions = true;
  }

  closeMoreOptionMenu(): void {
    this.openMoreOptions = false;
  }

  showConvertToMenu(): void {
    this.openConvertToMenu = true;
  }

  closeConvertToMenu(): void {
    this.openConvertToMenu = false;
  }
  downloadBlob(obj: Blob | MediaSource | any, filename: string): void {
    const url = URL.createObjectURL(obj.body || obj);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getDownloadBody = () => {
    return {
      document: this.budget?.header?.numberDoc,
      type: this.type,
      id: this.budget?._id,
      lang: this.i18n.currentLang,
      fileFormat: 'pdf',
      fileName: this.billTitle,
      user: {
        _id: StorageService.UserId,
        company: {
          _id: StorageService.CompanyId,
          name: this.AllSettings?.data_account?.company_name || '',
        },
      },
      template:
        this.budget.header?.sequence_id !== undefined ? 'plantilla1' : 'budget',
    };
  };
  downloadDocument(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_options_download`,
    });

    if (this.isMX && this.type === TypeBudget.BILL) {
      this.invoiceService
        .findPdf({ id: this.budget?._id })
        .then((res) => {
          this.downloadBlob(res, this.billTitle);
        })
        .catch((error) => {
          console.error('ERROR DOWNLOADING', error);
          this.toastService.show({
            text: this.i18n.instant('budget.downloadError'),
            type: 'error',
            msDuration: 4000,
          });
        });
    } else {
      const api = this.getDownloadBody();
      this.budgetService
        .downloadDocument(api)
        .toPromise()
        .then(() => {
          this.toastService.show({
            text: this.i18n.instant('budget.documentDownloaded'),
            type: 'success',
            msDuration: 4000,
          });
        })
        .catch((error) => {
          console.error('ERROR DOWNLOADING', error);
          this.toastService.show({
            text: this.i18n.instant('budget.downloadError'),
            type: 'error',
            msDuration: 4000,
          });
        });
    }
  }

  getBudgetData(): void {
    this.budgetService
      .findBudgetAdd(this.budget?.header?.contact_id, this.type)
      .subscribe(
        (resp) => {
          this.budgetData = resp;
          this.showDocument();
          this.draw();
        },
        () => {
          this.draw();
        }
      );
  }

  resolveDataSend() {
    const dataSend = {
      type: this.type,
      logo: this.AllSettings.logo,
      company_name: this.AllSettings.data_account?.company_name,
      fiscalName: this.AllSettings.data_account?.fiscalName,
      tradename: this.AllSettings.data_account?.tradename,
      prefix: this.budget.header?.prefix,
      phone: this.AllSettings.data_account?.phone,
      address: this.AllSettings.billing_addres?.address,
      email: this.AllSettings.data_account?.email,
      final_name: this.budgetData?.name,
      final_nif: this.budgetData?.nif,
      final_email: this.budgetData?.email,
      final_fiscalName: this.budgetData?.fiscalName,
      final_address: this.budgetData?.address,
      final_address_additional: this.budgetData?.address_additional,
      phones: this.budgetData?.phones,
      numberDoc: this.budget.header?.numberDoc,
      date: this.budget.header?.date,
      dueDate: this.budget.header?.dueDate,
      items: this.budget?.items,
      subtotal: this.budget?.subtotal,
      iva_total: this.budget?.iva_total,
      total: this.budget?.total,
      showPrices:
        this.type !== TypeBudget.WAL ? true : this.budget.header?.showPrices,
      showSignatures:
        this.type !== TypeBudget.WAL
          ? false
          : this.budget.header?.showSignatures,
      ivap: this.iVa,
      retp: this.rEt,
      reqp: this.rEq,
      line1: this.budget?.messages?.line1,
      textShow: undefined,
      discount: this.budget?.discount,
      hasDiscount: this.budget?.proFeatures?.hasDiscount,
      signClient: this.budget?.signClient,
      signCompany: this.budget?.signCompany,
    };
    return dataSend;
  }

  pdfError = false;

  showDocument(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view`,
    });

    const api: any = {
      document: this.budget?.header?.numberDoc,
      type: this.type,
      id: this.budget?._id,
      lang: this.i18n.currentLang,
      fileFormat: 'pdf',
      user: {
        _id: StorageService.UserId,
        company: {
          _id: StorageService.CompanyId,
          name: this.AllSettings?.data_account?.company_name || '',
        },
      },
    };

    Object.assign(api, {
      budget: this.budget,
      dataSend: this.resolveDataSend(),
      ...(!!this.budget?.personalization?.distribution && {
        distribution: this.budget?.personalization?.distribution,
      }),
      ...(!!this.budget?.personalization?.color && {
        color: this.budget?.personalization?.color,
      }),
    });

    this.invoiceService
      .findPdf(api)
      .then((res) => {
        const url = URL.createObjectURL(res.body || res);
        this.pdfSrc = url;
      })
      .catch(() => {
        this.toastService.show({
          text: this.i18n.instant('budget.downloadError'),
          type: 'error',
          msDuration: 4000,
        });
        this.pdfError = true;
      })
      .finally(() => {
        this.isLoading = false;
        this.draw();
      });
  }

  editDocument(isButton: boolean): void {
    const event = isButton
      ? `${this.budgetTypeAmplitude}_view_edit`
      : `${this.budgetTypeAmplitude}_view_options_edit`;

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: event,
    });

    const data = {
      budget: this.budget,
      type: this.type,
      mode: TypeOfCRUD.UPDATE,
    };

    this.createBudgetService.open(data).subscribe((d) => {
      const event = d?.event;
      const eventActions = {
        refresh: () => {
          this.resolveRouteParams();
          this.pdfSrc = '';
        },
      };

      eventActions[event]?.();
    });
  }

  duplicateDocument(): void {
    const data = {
      type: this.type,
      mode: TypeOfCRUD.UPDATE,
      budget: this.budget,
      duplicate: true,
    };
    this.createBudgetService.open(data).pipe(map((res) => !res));
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_options_duplicate`,
    });
  }

  convertBudget(type: TypeBudget): void {
    if (
      [TypeBudget.BILL, TypeBudget.BUDGET].includes(type) &&
      this.type === TypeBudget.BUDGET &&
      !this.isMfMobile
    ) {
      this.router.navigate([`/admin/documents/create/${TypeBudget.BILL}`], {
        queryParams: { fromBudgetId: this.budget._id },
      });

      return;
    }

    const budget = structuredClone(this.budget);
    // To update status
    const id_from = budget._id;
    const type_from = this.type;
    budget.header.prefix = undefined;
    budget.header.numberDoc = undefined;
    budget.convertFromId = budget._id;
    delete budget._id;
    budget.status = DEFAULT_STATUS_BY_BUDGET_TYPE[type].id;
    const data = {
      type: type,
      mode: TypeOfCRUD.UPDATE,
      budget,
      convert: true,
      id_from,
      type_from,
    };
    this.createBudgetService
      .open(data)
      .pipe(map((res) => !res))
      .subscribe(console.log);
  }

  deleteDocument(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_options_delete_start`,
    });

    const confirmLabel = this.isResponsive ? 'general.deleteAlt' : undefined;

    const data = {
      showBody: false,
      title: 'budgets.modal.wantDelete',
      confirmLabel,
    };

    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      data: data,
      panelClass: 'delete-by-selection-modal',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'EXECUTE') {
        this.isLoading = true;
        this.draw();

        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: `${this.budgetTypeAmplitude}_view_options_delete_delete`,
        });

        try {
          this.invoiceService.deleteV2(this.budget._id, this.type).then(() => {
            this.toastService.show({
              text: this.i18n.instant('budgets.settings.edit.successDeleted'),
              type: 'success',
              msDuration: 4000,
            });

            this.isLoading = false;
            this.draw();

            this.back();
          });
        } catch (error) {
          this.isLoading = false;
          this.draw();

          console.error('Error', error);
          this.toastService.show({
            text: this.i18n.instant('budgets.settings.edit.errorToDelete'),
            type: 'error',
            msDuration: 4000,
          });
        }
      }
    });
  }

  canConvert(): boolean {
    return (
      this.type !== TypeBudget.BILL &&
      this.budget.status !== CANCELLED_STATUS_BY_BUDGET_TYPE[this.type].id &&
      this.budget.status !== INVOICED_STATUS_BY_BUDGET_TYPE[this.type].id
    );
  }

  canConvertToBill(): boolean {
    return (
      this.type === TypeBudget.BUDGET ||
      this.type === TypeBudget.PROFORM ||
      this.type === TypeBudget.WAL
    );
  }

  canConvertToProform(): boolean {
    return (
      this.type === TypeBudget.BUDGET &&
      this.budget.status !== STATUS_BUDGET_INVOICED.id
    );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  getDocumentUrl(): ShareFile {
    const url = `${environment.documentsBucket}/${this.type}/${StorageService.CompanyId}/${this.budget?._id}`;
    return { uri: url };
  }

  async getDocumentShareFile(): Promise<ShareFile> {
    const canShare = (await Share.canShare()).value;
    if (
      this.platformService.isPc() ||
      this.platformService.isMobileSafari() ||
      !canShare
    )
      return Promise.resolve(this.getDocumentUrl());

    try {
      let document: Blob;
      if (this.isMXandBILL) {
        document = await this.invoiceService.findPdf({ id: this.budget?._id });
      } else {
        const api = this.getDownloadBody();
        document = await this.budgetService.seeDocument(api).toPromise();
      }
      const fileName = `${this.billTitle}.pdf`;
      return { blob: document, fileName };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  shareDocument(): void {
    if (this.isLoadingShare) return;
    this.isLoadingShare = true;

    this.getDocumentShareFile()
      .then((file) => {
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: this.platformService.isPc()
            ? `${this.budgetTypeAmplitude}_view_send_whatsapp`
            : `${this.budgetTypeAmplitude}_view_share`,
        });
        this.shareService.share(file);
      })
      .catch(() => {
        this.toastService.show({
          text: 'general.navigatorCompatibilityError',
          type: 'error',
          msDuration: 4000,
        });
      })
      .finally(() => {
        this.isLoadingShare = false;
        this.draw();
      });
  }

  getEmailFeatureFlag(): void {
    this.featureFlags
      .getValue('show_send_email', false)
      .then((featureFlagEmail: boolean) => {
        this.featureFlagEmail = featureFlagEmail;
      });
  }

  async sendDocument(): Promise<void> {
    if (!this.budget?._id) return;
    let final: IFinal = { ...this.final };

    if (!final?.emailArr?.length && !final?.email) {
      final = await this.finalService
        .findOne(this.budget.header?.contact_id)
        .toPromise();
    }
    if (!final?.emailArr?.length && final?.email) {
      final.emailArr = [final.email];
    }

    const config: MatDialogConfig = new MatDialogConfig();
    config.panelClass = 'send-document-modal';
    config.data = {
      type: this.type,
      budget: this.budget,
      settings: this.AllSettings,
      email: final?.emailArr,
      budgetTypeAmplitude: this.budgetTypeAmplitude,
      user: {
        _id: StorageService.UserId,
        company: { _id: StorageService.CompanyId },
      },
    };
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_emailStart`,
    });

    const dialogRef = this.dialog.open(ModalSendComponent, config);

    dialogRef.afterClosed().subscribe(() => {
      this.draw();
    });
  }

  resolveEmails(): string {
    const emails = this.finalsEmail.find(
      (value) => value.id === this.budget.header?.contact
    )?.email;
    return emails;
  }

  createOrCopyPaymentLink(): void {
    if (this.type !== TypeBudget.BILL) return;

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_charge`,
    });

    this.sub$.add(
      this.sharePaymentLinkModalService.updatingBill$.subscribe((loading) => {
        this.isCreatingPaymentLink = loading;
        this.draw();
      })
    );

    this.sub$.add(
      this.sharePaymentLinkModalService.billUpdated$.subscribe((bill) => {
        this.budget.status = bill.status;
        this.resolveStatus(this.budget.status);
        this.draw();
      })
    );

    if (this.budget?.paymentLink?.url) {
      this.sharePaymentLinkModalService.open({
        data: {
          url: this.budget.paymentLink.url,
          idSale: this.budget._id,
          module: 'budgets',
          finalEmail: this.budget.header?.final || null,
          type: this.budgetTypeAmplitude,
        },
      });

      this.budget.header.final;
    } else {
      this.isCreatingPaymentLink = true;

      this.budgetService
        .createPaymentLinkForBill(this.budget._id)
        .pipe(
          finalize(() => {
            this.isCreatingPaymentLink = false;
            this.draw();
          })
        )
        .subscribe(
          (paymentLink) => {
            this.budget.paymentLink = {
              id: paymentLink.id,
              url: paymentLink.url,
            };

            this.sharePaymentLinkModalService.open({
              data: {
                url: paymentLink.url,
                idSale: this.budget._id,
                module: 'budgets',
                type: this.budgetTypeAmplitude,
              },
            });
          },
          (error) => {}
        );
    }
  }

  watchStripeObject(): void {
    this.sub$.add(
      this.paymentsService.stripeObject$.subscribe((res) => {
        this.stripeObject = res;
        this.draw();
      })
    );
  }

  get isMobile(): boolean {
    return window.screen.width < 768;
  }

  get isMfMobile(): boolean {
    return window.screen.width < 900 || window.screen.height < 768;
  }

  get showEnablePaymentsBanner(): boolean {
    return (
      ![
        TacliaPayments.StatusAccount.APPROVED,
        TacliaPayments.StatusAccount.APPROVED_WITH_WARNING,
        TacliaPayments.StatusAccount.REJECTED,
        TacliaPayments.StatusAccount.SUSPENDED,
      ].includes(this.stripeObject?.onboarding?.status) && !this.isMobile
    );
  }

  get stripeAccountIsApproved(): boolean {
    const approvalStates = [
      TacliaPayments.StatusAccount.APPROVED,
      TacliaPayments.StatusAccount.APPROVED_WITH_WARNING,
    ];

    return approvalStates.includes(this.stripeObject?.onboarding?.status);
  }

  get stripeAccountRestricted(): boolean {
    return [
      TacliaPayments.StatusAccount.REJECTED,
      TacliaPayments.StatusAccount.SUSPENDED,
    ].includes(this.stripeObject?.onboarding?.status);
  }

  showPreview(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.width = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'previewer-modal';
    dialogConfig.data = {
      budget: this.budget,
      settings: this.AllSettings,
      type: this.type,
      mode: this.mode,
    };
    this.showTotal = false;

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: 'card_preview',
    });

    this.dialog.open(BudgetPreviewerComponent, dialogConfig);
  }

  showMessageEdited(): void {
    this.toastService.show({
      text: this.i18n.instant('budgets.settings.create.successEdit'),
      type: 'success',
      msDuration: 4000,
    });
  }

  saveBudget(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.saveLoading = true;
      const serviceMap = {
        [TypeBudget.WAL]: 'updateOneWaybill',
        [TypeBudget.BILL]: 'updateOneBill',
        [TypeBudget.BUDGET]: 'updateOne',
        [TypeBudget.PROFORM]: 'updateOneProform',
      };

      const service = serviceMap[this.type];

      this.sub$.add(
        this.budgetService[service](this.budget._id, this.budget).subscribe(
          () => {
            this.saveLoading = false;
            this.showSave = false;

            this.showMessageEdited();
            this.draw();
            resolve();
          },
          (error) => {
            this.saveLoading = false;
            this.draw();
            reject(error);
          }
        )
      );

      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: `${this.budgetTypeAmplitude}_view_save`,
      });
    });
  }

  private resolveAmplitudeInChangeSignature(
    evt: 'create' | 'delete',
    type: 'COMPANY' | 'CLIENT'
  ) {
    const amplitudeEvents = {
      COMPANY: `waybill_view_companySign_${evt}`,
      CLIENT: `waybill_view_finalSign_${evt}`,
    };

    const event = amplitudeEvents[type];

    if (event) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: event,
      });
    }
  }

  async changeSignature(
    evt: Signature.SignatureChange,
    type: 'COMPANY' | 'CLIENT'
  ): Promise<void> {
    const key: keyof PartOfServiceGlobal.PartOfServiceSchema =
      type === 'COMPANY' ? 'signCompany' : 'signClient';

    const updateSignatureState = (value: boolean) => {
      const actions = {
        COMPANY: () => (this.uploadCompanySignature = value),
        CLIENT: () => (this.uploadClientSignature = value),
      };

      actions[type]();
      this.draw();
    };

    updateSignatureState(true);

    if (evt.data === 'noData') {
      this.showSave = true;
      this.budget[key] = null;

      this.resolveAmplitudeInChangeSignature('delete', type);
      updateSignatureState(false);
      this.pdfSrc = '';
      await this.saveBudget();
      this.getBudgetData();
      return;
    }

    this.createSignatureWaybill
      .open({
        data: {
          source: this.budget[key],
          type,
          previewMode: !evt?.featureOn,
        },
      })
      .pipe(
        map(async (res) => {
          if (res.success) {
            this.resolveAmplitudeInChangeSignature(
              res?.source ? 'create' : 'delete',
              type
            );
            if (this.budget[key] != res?.source) {
              this.pdfSrc = '';
              this.showSave = true;
              this.budget[key] = res?.source;
              await this.saveBudget();
              this.getBudgetData();
            }
          }
        }),
        finalize(() => {
          updateSignatureState(false);
        })
      )
      .subscribe(() => {
        updateSignatureState(false);
      });
  }

  invoiceWaybill(): void {
    if (this.canConvertToBill()) {
      const budget = structuredClone(this.budget);
      const budgetsItems: IItemBudget[] = [];
      const waybill = {
        type: TypeItemBudget.WAYBILL,
        description: `${budget.header.prefix} - ${budget.header.numberDoc}`,
      };
      budget.items.splice(0, 0, waybill);
      budgetsItems.push(...budget.items);
      budget.items = budgetsItems.flat();
      delete budget._id;
      budget.header.prefix = undefined;
      budget.header.numberDoc = undefined;
      budget.status = undefined;
      const data = {
        type: TypeBudget.BILL,
        mode: TypeOfCRUD.CREATE,
        budget,
        entries: [this.budget],
        canEditContact: false,
        invoicing: true,
      };
      this.createBudgetService
        .open(data)
        .pipe(map((res) => !res))
        .subscribe(console.log);

      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: `${this.budgetTypeAmplitude}_view_convertToBill`,
      });
    }
  }

  setAvailableStatusesWhenOverdue(): void {
    const budgetProformFilter = [
      PAID_STATUS_BY_BUDGET_TYPE,
      OVERDUE_STATUS_BY_BUDGET_TYPE,
      CANCELLED_STATUS_BY_BUDGET_TYPE,
      INVOICED_STATUS_BY_BUDGET_TYPE,
      ACCEPTED_STATUS_BY_BUDGET_TYPE,
      REJECTED_STATUS_BY_BUDGET_TYPE,
    ];

    const statusFilters = {
      [TypeBudget.BILL]: [
        PAID_STATUS_BY_BUDGET_TYPE,
        OVERDUE_STATUS_BY_BUDGET_TYPE,
      ],
      [TypeBudget.PROFORM]: budgetProformFilter,
      [TypeBudget.BUDGET]: budgetProformFilter,
    };

    const applicableStatuses = statusFilters[this.type] || [];
    this.statusList = this.statusList?.filter((status) =>
      applicableStatuses.some(
        (filter) => status.value === filter[this.type]?.id
      )
    );
  }

  resolveStatusList(): void {
    this.statusList = STATUSES_BY_BUDGET_TYPE[this.type]?.map((s) => {
      return {
        title: s.translate,
        value: s.id,
        id: String(s.id),
      };
    });

    if (
      this.status?.id === OVERDUE_STATUS_BY_BUDGET_TYPE[this.type]?.id &&
      this.type !== TypeBudget.WAL
    ) {
      this.setAvailableStatusesWhenOverdue();
    } else {
      this.statusList = this.statusList?.filter(
        (status) =>
          status.value !== OVERDUE_STATUS_BY_BUDGET_TYPE[this.type]?.id ||
          this.status?.id === OVERDUE_STATUS_BY_BUDGET_TYPE[this.type]?.id
      );
    }

    this.updateSelectedStatus(this.status?.id);
  }

  updateSelectedStatus(idStatusAdded: number): void {
    const item = this.statusList?.find((s) => s.value === idStatusAdded);
    this.statusSelected = item ? [item] : [];
    this.draw();
  }

  changeStatus(evt: RmSelect.Items): void {
    if (evt[0].value === OVERDUE_STATUS_BY_BUDGET_TYPE[this.type]?.id) return;
    this.budget.status = evt[0].value as BudgetStatus | WaybillStatus;
    this.resolveStatus(this.budget.status);
    this.showSave = true;
    this.draw();

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: `${this.budgetTypeAmplitude}_view_editStatus`,
    });
    this.saveBudget();
  }

  changeSent(): void {
    const value = !this.budget.sent;
    this.budget.sent = value;

    this.saveBudget().then(() => {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: `${this.budgetTypeAmplitude}_${
          value ? 'mark' : 'unmark'
        }_as_sent`,
      });

      this.showSave = true;
    });
  }

  openCheckClose() {
    const data: DeleteDialogData = {
      showBody: false,
      title: 'budgets.modal.wantExit',
      confirmLabel: 'budgets.modal.btnConfirmExit',
    };

    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'EXECUTE') {
        this.canExitComponent.next(true);
      }
      if (res == 'CANCEL') {
        this.canExitComponent.next(false);
      }
      if (res == 'CANCEL-X') {
        this.canExitComponent.next(false);
      }
    });
  }

  isTypeBillDraft(): boolean {
    return !this.isMX ? true : this.isBill ? this.budget.draft : true;
  }

  isMXandDraft(): boolean {
    return this.isMX && this.isBill && this.budget.draft;
  }

  async isBillValidForIssue() {
    //check budget fields
    let isValid = !(
      !this.budget.header.sequence_id ||
      !this.budget.purposeUse ||
      !this.budget.invoiceType ||
      this.budget.typePayments?.length === 0 ||
      !this.budget.typePayments[0].name
    );

    if (!isValid) {
      this.toastService.show({
        text: this.i18n.instant('budgets.error.issueInvoice'),
        type: 'error',
        msDuration: 4000,
      });
      return false;
    }

    //check catalogue fields
    const catalogue = await this.cataloguesService.findAll().toPromise();
    isValid =
      !this.budget.items.some((item) => !item.concept || !item.price) &&
      !catalogue
        .filter((fil) =>
          this.budget.items.map((it) => it.concept).includes(fil.name)
        )
        .some((it) => !it.productKey || !it.measurementUnit);

    if (!isValid) {
      this.toastService.show({
        text: this.i18n.instant('budgets.error.noLinesError'),
        type: 'error',
        msDuration: 4000,
      });
      return false;
    }

    const final = await this.finalService
      .findOne(this.budget.header.contact_id)
      .toPromise();
    isValid =
      !final?.fiscalName ||
      !final?.address ||
      !final?.fiscalRegime ||
      !final.nif;
    if (!isValid) {
      this.toastService.show({
        text: this.i18n.instant('budgets.error.client_data'),
        type: 'error',
        msDuration: 4000,
      });
      return false;
    }

    if (!this.budgetService.validateBDGSettings()) {
      this.toastService.show({
        text: this.i18n.instant('budgets.error.warning_bdgsettings'),
        type: 'error',
        msDuration: 4000,
      });
      return false;
    }
    return true;
  }

  async onClickIssueInvoice(): Promise<void> {
    try {
      if (!(await this.isBillValidForIssue())) {
        return;
      }
      this.saveLoading = true;
      this.budget.issueAt = new Date().toISOString();
      await this.invoiceService.update({
        ...this.budget,
        type: TypeBudget.BILL,
        duplicate: false,
      });

      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: AmplitudeEvents.billDraftGenerateBill,
      });

      this.showMessageEdited();
    } catch (err) {
      this.toastService.show({
        text: this.i18n.instant('budgets.error.issueInvoice'),
        type: 'error',
        msDuration: 4000,
      });
    } finally {
      this.saveLoading = false;
      this.loadData(TypeBudget.BILL, this.budget._id);
    }
  }

  get canIssueInvoice() {
    return this.budgetService.validateBDGSettings();
  }

  getStatus(id: number): string {
    const statusByType: IStatus[] = STATUSES_BY_BUDGET_TYPE[this.type];
    const statusText = statusByType.find((status) => status.id === id)
      ?.translate;
    return statusText ? this.i18n.instant(statusText) : '';
  }

  get isMX(): boolean {
    return this.usersService.validateCountryMX();
  }

  get canChangeStatus(): boolean {
    return !this.isMX;
  }

  get isBill(): boolean {
    return this.type === TypeBudget.BILL;
  }

  get isMXandBILL(): boolean {
    return this.isMX && this.isBill;
  }

  get getDate() {
    return this.isMXandBILL ? this.budget.header.date : this.budget.createdAt;
  }

  get showComments(): boolean {
    return [TypeBudget.BILL, TypeBudget.BUDGET].includes(
      this.type as TypeBudget
    );
  }

  async setNotes(values: IEquipmentNote[]): Promise<void> {
    const notes = values.map((note) => {
      return {
        _id: note._id,
        text: note.text,
        lastName: note.name,
        createdAt: note.createdAt,
        idAuthor: note.idAuthor,
      };
    });
    this.budget.notes = notes;
    await this.invoiceService.updateV2({
      ...this.budget,
      type: this.type,
      duplicate: false,
    });
  }
}
