import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
} from '@angular/core';
import { MicrofrontendComponent } from '../microfrontend.component';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  AnalyticsService,
  Source,
} from '../../shared/services/analytics/analytics.service';
import { ToastService } from '../../shared/services/toast/toast.service';
import { HIDE_ACCOUNTING_SERVICE_BANNER } from '../../../types-legacy';
import { StorageService } from '../../shared/services';

export enum EventType {
  sendAnalyticsEvent = 'sendAnalyticsEvent',
  showToast = 'showToast',
  closeBanner = 'close_banner',
}

type EventPayload = { event: string } | { path: string; params?: Params };

type SendEventPayload =
  | { id: string }
  | { type: 'info' | 'success' | 'error'; text: string; description?: string };

type EventData = {
  type: EventType;
  payload?: EventPayload;
};

type SendEventData = {
  type: EventType;
  payload?: SendEventPayload;
};

@Component({
  selector: 'roma-mf-accounting-service',
  template: `<mf-accounting-service
    class="mf-accounting-service"
    *ngIf="showBanner"
  ></mf-accounting-service>`,
  styles: [
    `
      :host {
        .mf-accounting-service {
          margin-bottom: 20px;
          margin-right: -40px;
          margin-left: -40px;
          margin-top: -60px;
          @media screen and (max-width: 768px) {
            margin-bottom: 16px;
            margin-top: -40px;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfAccountingServiceComponent extends MicrofrontendComponent<
  EventData,
  SendEventData
> {
  showBanner = !JSON.parse(
    StorageService.GetItem(HIDE_ACCOUNTING_SERVICE_BANNER)
  );

  @Input() appName = 'mf-accounting-service';

  //avoid script loading if banner is hidden
  @Input() appUrl: string = this.showBanner
    ? localStorage.getItem('mf-accounting-service')
    : undefined;

  academyLink = '';

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(AppName) appName: string,
    @Inject(AppUrl) appUrl: string,
    private _httpClient: HttpClient,
    private _el: ElementRef
  ) {
    super(_httpClient, _el);
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
  }

  onInit(): void {}

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  handleCustomEvent(event: CustomEvent<EventData>): void {
    const { type, payload } = event.detail;
    const eventsActionsMap = {
      [EventType.sendAnalyticsEvent]: this.handleAnalyticsEvent,
      [EventType.showToast]: this.handleShowToast,
      [EventType.closeBanner]: this.handleCloseBanner,
    };

    const action = eventsActionsMap[type];
    if (action) {
      action.call(this, payload);
    }
  }

  async handleAnalyticsEvent({
    event,
    sources,
    properties = {},
  }: {
    event: string;
    sources: Source[];
    properties?: { [key: string]: string };
  }): Promise<void> {
    this.analyticsService.trackEvent({
      sources: sources,
      eventName: event,
      eventProperties: properties,
    });
  }

  handleShowToast({
    type,
    text,
  }: {
    type: 'info' | 'success' | 'error';
    text: string;
  }): void {
    this.toastService.show({
      text,
      type,
      msDuration: 4000,
    });
  }

  handleCloseBanner(): void {
    StorageService.SetItem(HIDE_ACCOUNTING_SERVICE_BANNER, true);
    this.showBanner = false;
    this.draw();
  }

  onDestroy(): void {}
}
