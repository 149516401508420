import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ActivityCalendarModule } from '@web-frontend/shared/components/activity-calendar';

import { TableModule } from '@web-frontend/shared/components/table/table.module';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { ExpensesComponent } from './expenses.component';
import { CreateExpenseModule } from '@web-frontend/shared/components/create-expense';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { NotResultFoundModule } from '@web-frontend/shared/components/not-result-found';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';
import { EmptyStateModule } from '../../../common/components/emptyState/empty-state.module';

@NgModule({
  declarations: [ExpensesComponent],
  imports: [
    CommonModule,
    TableModule,
    FilterModule,
    TranslateModule.forChild(),
    BasicMessageModule,
    MatButtonModule,
    MatIconModule,
    LoadingModule,
    CreateExpenseModule,
    MatDialogModule,
    ActivityCalendarModule,
    MatButtonToggleModule,
    NotResultFoundModule,
    RmFilterModule,
    RomaToolTipModule,
    MatProgressSpinnerModule,
    StripeSnackbarSharedModule,
    PermissionsModule,
    DownloadBtnModule,
    EmptyStateModule,
  ],
  exports: [ExpensesComponent],
  providers: [DatePipe],
})
export class ExpensesModule {}
