<div *ngIf="showButton">
  <form
    class="equipment-notes-footer"
    [formGroup]="form"
    (ngSubmit)="addNotes()"
  >
    <roma-user-avatar
      width="28"
      height="28"
      style="border-radius: 50%; margin-right: 8px"
    ></roma-user-avatar>
    <textarea
      #commentText
      name=""
      id=""
      cols="30"
      rows="1"
      class="note-input"
      [placeholder]="placeholder | translate"
      formControlName="text"
      maxlength="255"
      (blur)="blurTextArea()"
    >
    </textarea>
    <div class="actions" *ngIf="commentText.value">
      <button
        type="submit"
        class="roma-button save-btn"
        [disabled]="!form.valid"
        (mouseover)="overSubmit = true"
        (mouseout)="overSubmit = false"
      >
        {{ 'general.save' | translate }}
        <!-- <mat-icon>{{ editMode.active ? 'edit' : 'add' }}</mat-icon> -->
      </button>
      <button class="cancel-btn" (click)="commentText.value = ''">
        {{ 'activity.create.cancel' | translate }}
      </button>
    </div>
  </form>
</div>
<div *ngIf="notes.length" class="equipment-notes-body">
  <div *ngFor="let note of notes; let i = index" class="note-item">
    <div>
      <roma-user-avatar
        [user]="getUserByName(note?.name)"
        width="28"
        height="28"
        style="border-radius: 50%; margin-right: 8px"
      ></roma-user-avatar>
      <span class="name"
        ><b>{{ note.name }}</b></span
      >&nbsp;
      <span class="dateText"
        >{{ note.createdAt | date: company_date_format }} -
        {{ getCommentTime(note.createdAt) }}</span
      >
    </div>

    <div>
      <p class="note-item-text">
        {{ note.text }}
      </p>
    </div>
    <div class="container-actions" *ngIf="note.edit">
      <span (click)="editNote(i, note)">{{ 'general.edit' | translate }}</span>
      <span (click)="deleteNote(i)">{{ 'general.delete' | translate }}</span>
    </div>
  </div>
</div>
