import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TutorialService } from '@web-frontend/shared/services/tutorial';

@Component({
  selector: 'roma-location-message-modal',
  templateUrl: './location-message-modal.component.html',
  styleUrls: ['./location-message-modal.component.scss'],
})
export class LocationMessageModalComponent implements OnInit {
  academyLink: string;

  constructor(
    private dialogRef: MatDialogRef<LocationMessageModalComponent>,
    private tutorialService: TutorialService
  ) {}

  ngOnInit() {
    this.getModuleResources();
  }

  getModuleResources(): void {
    this.tutorialService.get('clock-hour-settings').subscribe((res) => {
      this.academyLink = res?.academy;
    });
  }

  onClose(): void {
    this.dialogRef.close('closed');
  }

  onActivate(): void {
    this.dialogRef.close('activated');
  }
}
