import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'roma-google-maps-link',
  templateUrl: './google-maps-link.component.html',
  styleUrls: ['./google-maps-link.component.scss'],
})
export class GoogleMapsLinkComponent {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() street?: string;
  @Input() state?: string;
  @Input() city?: string;
  @Input() maxWidth?: string;

  constructor(private i18n: TranslateService) {}

  get googleMapsUrl(): string {
    return `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;
  }

  get googleDirection(): string {
    const arrayDir = [this.street, this.state, this.city].filter(Boolean);
    return arrayDir.join(', ') || '';
  }
}
