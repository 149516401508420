import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';

import { RmSelectRegister } from './rm-select-register.types';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureUser, Lang } from '@tacliatech/types';
import { RmSelect } from '../rm-select/rm-select.types';
import { UserService } from '@web-frontend/shared/services';

@Component({
  selector: 'rm-select-register',
  templateUrl: './rm-select-register.component.html',
  styleUrls: ['./rm-select-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmSelectRegisterComponent implements OnInit {
  public innerWidth = 0;
  isResponsive = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  @ViewChild('listWrapper') listWrapper: ElementRef;

  @ViewChild('inputFilterSearch')
  set input(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._input = value;
      // this.setFocusInputSearch();
      this.patchFilterArgParams();
      this.watchInputSearch();
    }
  }

  @Input()
  placeholderText = '';

  @Input()
  icon?: string;

  @Input()
  mode: RmSelectRegister.Type = 'SINGLE';

  @Input()
  displayMultipleActive = false;

  @Input()
  isAbsolute = false;

  @Input()
  isClearable = false;

  @Input()
  listWidth = '';

  @Input()
  listCustomStyle = '';

  @Input()
  borderLeft = false;

  @Input()
  addButtonText = 'general.addUser';

  @Input()
  addButtonIcon = '/assets/icons/gl_plus.svg';

  @Input()
  addIconStyle = '';

  @Input()
  isAddOpened = false;

  @Input()
  addButtonClickKeepOpen = false;

  @Input()
  hasError = false;

  @Input()
  fullWidthList = false;

  @Input()
  allOptionsEnabled = true;

  @Input()
  isBadgeType = false;

  @Input()
  badgeColor = '#000000';

  @Input()
  addButtonFeatureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  checkedRight = false;

  @Input()
  changeDropDownIcon = true;

  _panelClicked = false;
  _isLoading = false;
  _firstLoad = true;
  @Input()
  @HostBinding('class.is-loading')
  set isLoading(value: boolean) {
    this._isLoading = value;
    //if is changed is loaded to false for second time (latter than load), we open the panel
    if (this._isLoading == false) {
      if (this._firstLoad) {
        this._firstLoad = false;
        this._panelClicked = false;
      } else {
        if (this._panelClicked && !this.disableOpenPanelOnLoading) {
          this.showPanelElement = true;
        }
        this._panelClicked = false;
      }
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  get iconDropdown() {
    return this.focusOutA && this.changeDropDownIcon
      ? 'assets/icons/active-up-arrow.svg'
      : 'assets/icons/gl_arrow_down.svg';
  }

  @Input()
  @HostBinding('class.is-disabled')
  disabled!: boolean;

  @Input()
  items: RmSelectRegister.Items = [];

  @Input()
  activeItems: RmSelectRegister.Items = [];

  @Input()
  displayedItems: RmSelectRegister.Items = [];

  @Input()
  inputHeight = '44px';

  @Input()
  maxLength?: number;

  @Input()
  listStyle = {};

  @Input()
  listStyleItem = {};

  @Input()
  type = '';

  @Input()
  isLowerCase = true;

  @Output()
  changeItems = new EventEmitter<RmSelectRegister.Items>();

  @Output()
  disabledClickEvent = new EventEmitter();

  @Output()
  panelOpen = new EventEmitter();

  @Output()
  addElement = new EventEmitter<MouseEvent>();

  @Input()
  showPanelElement = false;

  @Input()
  disableOpenPanelOnLoading = false;

  @Input()
  onlySelect = true;

  selectedIndex = 0;
  checkBoxItems: any[] = [];
  filterArgs: { title: string } = { title: '' };

  private _input: ElementRef<HTMLInputElement>;
  private sub$ = new Subscription();

  @Input()
  loadDefaultItem = true;
  firstLoad = true;
  focusOutA = false;
  focusOutB = false;
  anuleFocusOut = false;
  menuIsOpen = false;

  get stringSelectedItem(): RmSelectRegister.Item {
    const input = this._input.nativeElement.value;
    return {
      id: input,
      value: input,
      title: input,
    };
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
    private i18n: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // this.resolveItemsToDisplay();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
    this.watchTranslation();
  }

  public showItemFreeToAdd(
    hasInputFilled: boolean,
    hasItems: boolean
  ): boolean {
    return (
      !this.userService.validateCountryMX() &&
      !this.onlySelect &&
      hasInputFilled &&
      !hasItems
    );
  }

  private resolveLanguage() {
    const lang = this.i18n.currentLang as Lang;

    this.draw();
  }

  private watchTranslation() {
    this.sub$.add(
      this.i18n.onLangChange.subscribe(() => {
        this.resolveLanguage();
      })
    );
  }

  changeSelectedIndex(increment: number) {
    if (
      this.selectedIndex + increment < 0 ||
      this.selectedIndex + increment >= this.items.length
    ) {
      return;
    }
    this.selectedIndex = this.selectedIndex + increment;
    this.listWrapper.nativeElement.scrollTop += increment * 40;
  }

  clickPanel(evt: MouseEvent, open?: boolean, from?: string) {
    if (this.disabled) {
      this.disabledClickEvent.emit();
      return;
    }
    this._panelClicked = true;
    if (evt) {
      if (open !== undefined && typeof open === 'boolean') {
        this.showPanelElement = open;
      } else {
        this.showPanelElement = !this.showPanelElement;
        this.panelOpen.emit();
      }

      if (this.showPanelElement) {
        this.focusOutA = true;
        this.focusOutB = false;
      }
      this.draw();
      this._input.nativeElement.focus();
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  anuleFocusOutTimeout() {
    this.anuleFocusOut = true;
    setTimeout(() => {
      this.anuleFocusOut = false;
    }, 200);
  }

  async focusOut(from: string) {
    await this.delay(100);

    if (this.anuleFocusOut) {
      return;
    }

    if (from === 'users') {
      this.focusOutA = true;
    }

    if (from === 'status') {
      this.focusOutB = true;
    }

    if (this.focusOutA && this.focusOutB) {
      this.showPanelElement = false;
    }
    this.draw();
  }

  changeFilteredSelect() {
    const filteredItems = this.items.filter((item) => {
      return item.title
        .toLowerCase()
        .includes(this.filterArgs.title.toLowerCase());
    });
    if (filteredItems?.length > 0) {
      this.changeSelect(filteredItems[this.selectedIndex]);
      return;
    }
    if (!this.onlySelect) {
      this.changeSelect(this.stringSelectedItem);
    }
  }

  changeSelect(item: RmSelectRegister.Item) {
    this.loadDefaultItem = false;
    if (item?.value) {
      this.activeItems = [item];
    }

    this.showPanelElement = false;
    this.changeItems.next(this.activeItems);
    this.draw();
  }

  changeCheckBox(item) {
    if (!this.isChecked(item)) {
      this.activeItems.push(item);
    } else {
      this.activeItems.splice(
        this.activeItems.findIndex(function (i) {
          return i.id === item.id;
        }),
        1
      );
    }

    this.changeItems.next(this.activeItems);
    this.draw();
  }

  changeCheckBoxByClick(evt, item: RmSelect.Item): void {
    if (this.searchCheckFilters(item)) {
      const index = this.activeItems.findIndex(
        (active) => active.id === item.id
      );
      this.activeItems.splice(index, 1);
    } else {
      this.activeItems.push(item);
    }
    this.changeItems.next(this.activeItems);
    this.draw();
  }

  sliceTitle(title: string, quantity: number): string {
    const titleType = title.split(' ');
    let parsedTitle = titleType[0].slice(0, quantity);
    for (let i = 0; i < titleType.length; i++) {
      if (i > 0) {
        const element = titleType[i];
        parsedTitle += ` ${element}`;
      }
    }
    return parsedTitle;
  }

  isChecked(item) {
    return this.activeItems.map((it) => it.id).includes(item.id);
  }

  closePanel(evt: boolean) {
    if (this.addButtonClickKeepOpen && this.isAddOpened) return;

    this.showPanelElement = evt;
    this.draw();
  }

  searchCheckFilters(item) {
    if (
      this.activeItems.filter((element) => element.id == item.id).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  addElementHandle(evt: MouseEvent) {
    if (evt) {
      if (!this.addButtonClickKeepOpen) {
        this.showPanelElement = false;
      }
      this.addElement.next(evt);
    }
  }

  clearInput(mode: 'in' | 'out') {
    this.activeItems = [];
    this.filterArgs.title = '';
    this.draw();

    if (mode === 'in') {
      this.changeItems.next(this.activeItems);
    }
  }

  private draw() {
    this.cdr.detectChanges();
  }

  private setFocusInputSearch() {
    if (this._input) {
      this._input.nativeElement.focus();
    }
  }

  private patchFilterArgParams() {
    if (this._input && this.filterArgs.title) {
      this._input.nativeElement.value = this.filterArgs.title;
    }
  }

  private watchInputSearch() {
    this.sub$.add(
      fromEvent(this._input.nativeElement, 'input')
        .pipe(
          distinctUntilChanged(),
          debounce(() => timer(450)),
          map((res) => (res.target as HTMLTextAreaElement).value as string)
        )
        .subscribe(this.changeKeyword.bind(this))
    );
  }

  private changeKeyword(keyword: string) {
    const title = this.isLowerCase
      ? keyword.toString().toLocaleLowerCase()
      : keyword.toString();
    this.filterArgs = {
      title: title,
    };
    this.draw();
  }
}

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  constructor(public i18n: TranslateService) {}
  transform(items: RmSelectRegister.Items, filter: { title: string }): any {
    if (!items?.length || !filter?.title) {
      return items;
    }
    const sanitizedTitle = filter.title.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return items.filter((item) =>
      this.i18n.instant(item.title).match(new RegExp(sanitizedTitle, 'i'))
    );
  }
}
