import { Injectable } from '@angular/core';
import {
  FileSelectDefaultOptions,
  FileSelectOptions,
} from './file-select.types';

import { FilePicker, PickFilesResult } from '@capawesome/capacitor-file-picker';

@Injectable({
  providedIn: 'root',
})
export class FileSelectService {
  constructor() {}

  private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private filePickerResultToFormData(filePickerResult: PickFilesResult) {
    const formData = new FormData();
    for (let i = 0; i < filePickerResult.files.length; i++) {
      const data = filePickerResult.files[i].data;
      const blob = this.b64toBlob(data, filePickerResult.files[i].mimeType);
      const rawFile = new File([blob], filePickerResult.files[i].name, {
        type: filePickerResult.files[i].mimeType,
      });
      formData.append('file[]', rawFile, filePickerResult.files[i].name);
    }
    return formData;
  }

  private validateExtensions(mimeTypes: string[]): string {
    const isImage = mimeTypes.every((type) => type.includes('image/'));
    const isMedia = mimeTypes.every((type) => type.includes('video/'));
    const isFile = mimeTypes.every((type) => type.includes('application/'));

    if (isImage) return 'image';
    if (isMedia) return 'media';
    if (isFile) return 'files';
    return 'unknown';
  }

  async openSelector(
    paramsOptions: FileSelectOptions = FileSelectDefaultOptions
  ): Promise<FormData> {
    const type = this.validateExtensions(paramsOptions.extensions);
    let result;
    try {
      if (type === 'image') {
        result = await FilePicker.pickImages({
          readData: true,
        });
      } else if (type === 'media') {
        result = await FilePicker.pickMedia({
          readData: true,
        });
      } else {
        result = await FilePicker.pickFiles({
          readData: true,
        });
      }

      return this.filePickerResultToFormData(result);
    } catch (e) {
      return null;
    }
  }
}

interface RomaFile {
  /**
   * The Blob instance of the file.
   *
   * Only available on Web.
   */
  blob?: Blob;
  /**
   * The Base64 string representation of the data contained in the file.
   *
   * Is only provided if `readData` is set to `true`.
   */
  data?: string;
  /**
   * The duration of the video in milliseconds.
   *
   * Only available on Android and iOS.
   *
   * @since 0.5.3
   */
  duration?: number;
  /**
   * The height of the image or video in pixels.
   *
   * Only available on Android and iOS.
   *
   * @since 0.5.3
   */
  height?: number;
  /**
   * The mime type of the file.
   */
  mimeType: string;
  /**
   * The last modified timestamp of the file in milliseconds.
   *
   * @since 0.5.9
   */
  modifiedAt?: number;
  /**
   * The name of the file.
   */
  name: string;
  /**
   * The path of the file.
   *
   * Only available on Android and iOS.
   */
  path?: string;
  /**
   * The size of the file in bytes.
   */
  size: number;
  /**
   * The width of the image or video in pixels.
   *
   * Only available on Android and iOS.
   *
   * @since 0.5.3
   */
  width?: number;
}
