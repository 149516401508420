<div class="safe-area-padding">
  <div class="modal__header">
    <img
      class="modal__header__close"
      src="/assets/icons/gl_close.svg"
      alt="link"
      (click)="close()"
    />
    <div class="modal__header__title">
      {{ 'general.filters' | translate }}
    </div>
    <span
      *ngIf="source.toJson().length"
      class="modal__header__clean"
      (click)="source.clean()"
    >
      {{ 'general.cleanFilters' | translate }}
    </span>
  </div>
  <div class="modal__body">
    <div class="link__list">
      <ng-container *ngFor="let item of source.get()">
        <ng-container *ngIf="item.isSelect(item)">
          <div
            *ngIf="item?.show$ ? (item.show$ | async) : true"
            class="link__list__item wrapper-pro-feature"
            romaProSelect
            [feature]="item.feature"
            (click)="clickDetailItem($event, item)"
          >
            <div class="link__list__item__text">
              {{ item.content.label | translate }}
            </div>
            <span class="link__list__item__counter">
              <ng-container *ngIf="item.value()?.length">
                ({{ item.value()?.length }})
              </ng-container>
            </span>
            <img
              class="link__list__item__arrow"
              src="/assets/icons/gl_arrow-right.svg"
              alt="link"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="item.isSelectDateRange(item)">
          <div
            *ngIf="item?.show$ ? (item.show$ | async) : true"
            class="link__list__item wrapper-pro-feature"
            romaProSelect
            [feature]="item.feature"
            (click)="clickDetailItem($event, item)"
          >
            <div class="link__list__item__text">
              {{ item.content.label | translate }}
            </div>
            <span class="link__list__item__counter">
              <ng-container *ngIf="item.value()?.length">
                ({{ item.value()?.length }})
              </ng-container>
            </span>
            <img
              class="link__list__item__arrow"
              src="/assets/icons/gl_arrow-right.svg"
              alt="link"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="item.isRangeDateInput(item)">
          <div class="link__list__item link__list__item--pd-7">
            <mat-form-field appearance="fill">
              <mat-date-range-input [rangePicker]="picker">
                <input
                  #startDateInput
                  matStartDate
                  (dateChange)="rangeDateChange('START', $event, item)"
                />
                <input
                  #endDateInput
                  matEndDate
                  (dateChange)="rangeDateChange('END', $event, item)"
                />
              </mat-date-range-input>

              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>
                  <img
                    class="range__icon range__icon--calendar"
                    src="assets/icons/gl_calendar.svg"
                  />
                </mat-icon>
              </mat-datepicker-toggle>

              <mat-datepicker-toggle
                *ngIf="item.isComplete()"
                matSuffix
                (click)="
                  cleanDateRangeDate(
                    $event,
                    picker,
                    startDateInput,
                    endDateInput,
                    item
                  )
                "
              >
                <mat-icon matDatepickerToggleIcon>
                  <img
                    class="range__icon range__icon--close"
                    src="assets/icons/gl_close-input.svg"
                  />
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <div
                *ngIf="!item.isComplete()"
                class="fake-placeholder"
                (click)="picker.open()"
              >
                {{ 'filter.dateRange' | translate }}
              </div>
              <div
                *ngIf="item.isComplete()"
                class="fake-inputs-values"
                (click)="picker.open()"
              >
                {{
                  focusRangeInputStartValue + ' - ' + focusRangeInputEndValue
                }}
              </div>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="item.isInput(item)">
          <div
            *ngIf="item.type === 'STRING'"
            class="link__list__item link__list__item--pd-bottom-0 link__list__item--hidden-line"
          >
            <div
              class="wrapper-pro-feature"
              romaProSelect
              [feature]="item.feature"
            >
              <input
                type="text"
                class="link__list__item__input"
                [value]="item.value()"
                [placeholder]="item.content.placeholder"
                (keyup)="changeInputItem($event, item)"
                (click)="$event.stopPropagation()"
              />
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="item.isHour(item)">
          <div class="link__list__item link__list__item--block">
            <div class="link__list__item__text">
              {{ item.content.label | translate }}
            </div>
            <div class="link__list__item__description">
              {{ item.content.description | translate }}
            </div>
            <mat-radio-group
              class="radio__group"
              [value]="item.getValue()[0][0]"
              (change)="changeRadioGroup($event, item)"
            >
              <div
                *ngFor="let element of hoursTypes"
                class="radio__group__item"
              >
                <mat-radio-button
                  class="hours__radio__button"
                  [value]="element.value"
                >
                  {{ element.title | translate }}
                </mat-radio-button>
                <div
                  class="hours__container__input"
                  [ngClass]="{
                    'hours__container__input--disabled':
                      focusInputElValue !== element.value
                  }"
                >
                  <input
                    #inputHour
                    romaOnlyNumber
                    [id]="element.value"
                    class="hours__input"
                    type="text"
                    placeholder="00"
                    maxlength="4"
                    [value]="item.getValue()[0][0] ? inputHour?.value : null"
                    (keyup)="changeRadioInput($event, element, item)"
                    (click)="$event.stopPropagation()"
                  />

                  <div class="hours__container__input__text">
                    {{ 'general.hours' | translate }}
                  </div>
                </div>
              </div>
            </mat-radio-group>
          </div>
        </ng-container>
        <!-- control checkbox -->
        <ng-container *ngIf="item.isCheckbox(item)">
          <div
            [ngClass]="
              getItemClassCheckBox(
                'link__list__item link__list__item--hidden-line',
                item
              )
            "
          >
            <mat-checkbox
              color="primary"
              [checked]="item.value()"
              (click)="changeCheckbox(item); $event.stopPropagation()"
            >
              {{ item.content.label | translate }}
            </mat-checkbox>
          </div>
        </ng-container>

        <!-- control input range -->
        <ng-container *ngIf="item.isInputRange()">
          <div
            class="link__list__item link__list__item--pd-bottom-0 link__list__item--hidden-line"
          >
            <div
              class="control control__input wrapper-pro-feature"
              romaProSelect
              [feature]="item.feature"
            >
              <input
                #input
                type="number"
                [placeholder]="item.content.placeholder | translate"
                [value]="item.value()?.value"
                (keyup)="changeInputRange($event, item)"
                (click)="$event.stopPropagation()"
              />
              <button
                class="pro-feature-element"
                mat-icon-button
                [matMenuTriggerFor]="appMenu"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #appMenu="matMenu" class="menu__input__range">
                <mat-checkbox
                  *ngFor="let option of inputRangeOptions"
                  [checked]="item?.value()?.order === option.value"
                  (click)="changeCheckboxInputRange($event, item, option.value)"
                >
                  {{ option.title | translate }}
                </mat-checkbox>
              </mat-menu>
            </div>
          </div>
        </ng-container>

        <!-- control date -->
        <ng-container *ngIf="item.isDate()">
          <div
            class="link__list__item link__list__item--pd-7 wrapper-pro-feature"
            romaProSelect
            [feature]="item.feature"
          >
            <mat-form-field
              appearance="fill"
              (click)="$event.stopPropagation()"
            >
              <input
                #singleDate
                matInput
                [matDatepicker]="singlePicker"
                (dateChange)="changeDate($event, item)"
              />

              <mat-datepicker-toggle matSuffix [for]="singlePicker">
                <mat-icon
                  matDatepickerToggleIcon
                  (click)="singlePicker.open(); $event.stopPropagation()"
                >
                  <img
                    class="calendar-icon"
                    src="assets/icons/gl_calendar.svg"
                  />
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle
                *ngIf="item.isComplete()"
                matSuffix
                [for]="singlePicker"
              >
                <mat-icon
                  matDatepickerToggleIcon
                  (click)="cleanDate($event, singlePicker, singleDate, item)"
                >
                  <img
                    class="calendar-icon"
                    src="assets/icons/gl_close-input.svg"
                  />
                </mat-icon>
              </mat-datepicker-toggle>

              <mat-datepicker #singlePicker></mat-datepicker>
              <div
                *ngIf="!item.isComplete()"
                class="fake-placeholder"
                (click)="singlePicker.open()"
              >
                {{ item.content.placeholder | translate }}
              </div>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="modal__footer">
    <div *ngIf="hasChanges()" class="submit__button" (click)="submit()">
      {{ 'filter.apply' | translate }}
    </div>
  </div>
</div>
