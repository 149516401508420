<ng-container *ngFor="let item of source.get()">
  <!-- control select  -->
  <ng-container *ngIf="item.isSelect(item)">
    <div
      *ngIf="item?.show$ ? (item.show$ | async) : true"
      class="control control__select wrapper-pro-feature"
      romaProSelect
      [feature]="item.feature"
    >
      <rm-select
        #select
        [mode]="item.type"
        [isLoading]="item.isLoading$ | async"
        [items]="item.data$ | async"
        [placeholderText]="item.content.placeholder | translate"
        (click)="clickSelectItem($event, select, item)"
        (changeItems)="changeSelectItems($event, item)"
      >
      </rm-select>
    </div>
  </ng-container>

  <!-- control select date range -->
  <ng-container *ngIf="item.isSelectDateRange(item)">
    <div
      *ngIf="item?.show$ ? (item.show$ | async) : true"
      class="control control__select wrapper-pro-feature"
      romaProSelect
      [feature]="item.feature"
    >
      <rm-select
        #select
        [mode]="item.type"
        [isLoading]="item.isLoading$ | async"
        [items]="item.data$ | async"
        [placeholderText]="item.content.placeholder | translate"
        (click)="clickSelectItem($event, select, item)"
        (changeItems)="changeSelectItems($event, item)"
      >
      </rm-select>
    </div>
  </ng-container>

  <!-- control input hour  -->
  <ng-container *ngIf="item.isHour(item)">
    <div
      *ngIf="item?.show$ ? (item.show$ | async) : true"
      class="control__input__hour wrapper-pro-feature"
      #matMenuForHour="matMenuTrigger"
      [matMenuTriggerFor]="menu"
    >
      <div
        class="control__input__hour__text"
        [ngClass]="{
          'control__input__hour__text--dark': !item.isEmpty()
        }"
      >
        <ng-container *ngIf="item.isEmpty()">
          {{ item.content.placeholder | translate }}
        </ng-container>
        <ng-container *ngIf="!item.isEmpty()">
          <ng-container *ngIf="item.getValue()[0][0] === 'EQUAL'">
            {{ 'general.equal' | translate }}
          </ng-container>
          <ng-container *ngIf="item.getValue()[0][0] === 'MAJOR'">
            {{ 'general.major2' | translate }}
          </ng-container>
          <ng-container *ngIf="item.getValue()[0][0] === 'MINOR'">
            {{ 'general.minor2' | translate }}
          </ng-container>
          {{ ' ' + item.getValue()[0][1] }}
        </ng-container>
      </div>
      <div class="control__input__options">
        <img src="assets/icons/gl_arrow_down.svg" />
      </div>
      <mat-menu
        #menu="matMenu"
        class="filter-options"
        (click)="$event.stopPropagation()"
      >
        <mat-radio-group
          [value]="item.getValue()[0][0]"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngFor="let element of hoursTypes; let i = index">
            <mat-radio-button
              [value]="element.value"
              (change)="changeRadioGroup($event, item)"
              (click)="$event.stopPropagation()"
            >
              {{ element.title | translate }}
            </mat-radio-button>
            <input
              #hourInput
              romaOnlyNumber
              [id]="element.value"
              class="hours-input"
              [ngClass]="{
                hidden: focusInputElValue !== element.value
              }"
              type="text"
              placeholder="00h"
              maxlength="4"
              (click)="$event.stopPropagation()"
              (input)="changeInputHour($event, item)"
            />
          </ng-container>
        </mat-radio-group>
        <button [disabled]="item.isEmpty()" (click)="applyItemHour($event)">
          {{ 'general.apply' | translate }}
        </button>
      </mat-menu>
    </div>
  </ng-container>

  <!-- control input -->
  <ng-container *ngIf="item.isInput(item)">
    <div *ngIf="item.type === 'SEARCH'" class="control control__input__search">
      <img src="assets/icons/gl_search.svg" />
      <input
        #input
        type="text"
        class="search-input"
        [attr.keyRequest]="item.getKeyRequest()?.[0]"
        [placeholder]="item.content.placeholder | translate"
        [title]="
          item?.content?.placeholderHover
            ? (item.content.placeholderHover | translate)
            : (item.content.placeholder | translate)
        "
      />
    </div>
    <div
      *ngIf="item.type === 'NUMBER'"
      class="control control__input wrapper-pro-feature"
      romaProSelect
      [feature]="item.feature"
    >
      <input
        #input
        type="number"
        [placeholder]="item.content.placeholder | translate"
        (keyup)="changeInputItem($event, item)"
        (click)="$event.stopPropagation()"
      />
    </div>
    <div
      *ngIf="item.type === 'STRING'"
      class="control control__input wrapper-pro-feature"
      romaProSelect
      [feature]="item.feature"
    >
      <input
        #input
        type="text"
        [placeholder]="item.content.placeholder | translate"
        (keyup)="changeInputItem($event, item)"
        (click)="$event.stopPropagation()"
      />
    </div>
  </ng-container>

  <!-- control divider  -->
  <ng-container *ngIf="item.isDivider(item)">
    <div class="control__divider"></div>
  </ng-container>

  <!-- control date range -->
  <ng-container *ngIf="item.isRangeDateInput(item)">
    <mat-form-field appearance="fill">
      <mat-date-range-input [rangePicker]="picker">
        <input
          #startDateInput
          matStartDate
          (dateChange)="rangeDateChange('START', $event, item)"
        />
        <input
          #endDateInput
          matEndDate
          (dateChange)="rangeDateChange('END', $event, item)"
        />
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>
          <img
            class="calendar-icon"
            width="20px"
            src="assets/icons/gl_calendar-input.svg"
          />
        </mat-icon>
      </mat-datepicker-toggle>

      <mat-datepicker-toggle
        class="calendar-clean"
        *ngIf="item.isComplete()"
        matSuffix
        (click)="
          cleanDateRangeDate($event, picker, startDateInput, endDateInput, item)
        "
      >
        <mat-icon matDatepickerToggleIcon>
          <img class="calendar-icon" src="assets/icons/gl_close-input.svg" />
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <div
        *ngIf="!item.isComplete()"
        class="fake-placeholder"
        (click)="picker.open()"
      >
        {{ 'filter.dateRange' | translate }}
      </div>
      <div
        *ngIf="item.isComplete()"
        class="fake-inputs-values"
        (click)="picker.open()"
      >
        {{ startDateInput.value + ' - ' + endDateInput.value }}
      </div>
    </mat-form-field>
  </ng-container>

  <!-- control checkbox -->
  <ng-container *ngIf="item.isCheckbox(item)">
    <div class="control control__checkbox">
      <mat-checkbox
        color="primary"
        class="ml-2 checkbox-search"
        [checked]="item.value()"
        (click)="changeCheckbox(item)"
      >
        {{ item.content.label | translate }}
      </mat-checkbox>
    </div>
  </ng-container>

  <!-- control input range -->
  <ng-container *ngIf="item.isInputRange()">
    <div
      class="control control__input__range wrapper-pro-feature"
      romaProSelect
      [feature]="item.feature"
    >
      <input
        #input
        romaOnlyNumber
        [placeholder]="item.content.placeholder | translate"
        (keyup)="changeInputRange($event, item)"
        (click)="$event.stopPropagation()"
      />
      <button
        class="pro-feature-element"
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #appMenu="matMenu" class="menu__input__range">
        <mat-checkbox
          *ngFor="let option of inputRangeOptions"
          [checked]="item?.value()?.order === option.value"
          (click)="changeCheckboxInputRange($event, item, option.value)"
        >
          {{ option.title | translate }}
        </mat-checkbox>
      </mat-menu>
    </div>
  </ng-container>

  <!-- control date -->
  <ng-container *ngIf="item.isDate()">
    <!-- wrapper-pro-feature -->
    <div
      class="control control__date wrapper-pro-feature"
      romaProSelect
      [feature]="item.feature"
    >
      <mat-form-field appearance="fill" (click)="$event.stopPropagation()">
        <input
          #singleDate
          matInput
          [matDatepicker]="singlePicker"
          (dateChange)="changeDate($event, item)"
        />

        <mat-datepicker-toggle
          class="calendar-clean"
          *ngIf="singleDate.value"
          matSuffix
          [for]="singlePicker"
        >
          <mat-icon
            matDatepickerToggleIcon
            (click)="cleanDate($event, singlePicker, singleDate, item)"
          >
            <img class="calendar-icon" src="assets/icons/gl_close-input.svg" />
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="singlePicker">
          <mat-icon
            matDatepickerToggleIcon
            (click)="singlePicker.open(); $event.stopPropagation()"
          >
            <img
              class="calendar-icon"
              width="20px"
              src="assets/icons/gl_calendar-input.svg"
            />
          </mat-icon>
        </mat-datepicker-toggle>

        <mat-datepicker #singlePicker></mat-datepicker>
        <div
          *ngIf="singleDate.value === ''"
          class="fake-placeholder"
          (click)="singlePicker.open()"
        >
          {{ item.content.placeholder | translate }}
        </div>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
