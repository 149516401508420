import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { geocodeResponse } from '../types/geocode-response.type';
import { AddressParts } from '../types/geocode-response.type';

@Injectable()
export class GeocodeApiService {
  private API_KEY_MAP!: string;

  constructor(private httpClient: HttpClient) {
    if (Capacitor.getPlatform() !== 'web') {
      this.API_KEY_MAP = environment.apiKeyMapAndroid;
    } else {
      this.API_KEY_MAP = environment.apiKeyMapWeb;
    }
  }

  public getAddressGeocodeInfo(address: string): Observable<geocodeResponse> {
    return this.httpClient.get<geocodeResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${
        this.API_KEY_MAP
      }&address=${encodeURIComponent(address)}`
    );
  }

  public getAddressFromLatLng(
    lat: string,
    lng: string
  ): Observable<geocodeResponse> {
    return this.httpClient.get<geocodeResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${this.API_KEY_MAP}&latlng=${lat},${lng}`
    );
  }

  public formatGeocodeAddress(response: geocodeResponse): AddressParts {
    const getAddressComponent = (type: string) =>
      response?.results[0]?.address_components.find((component) =>
        component.types.includes(type)
      )?.long_name || '';

    return {
      street: `${getAddressComponent('route')}, ${getAddressComponent(
        'street_number'
      )}`,
      state: getAddressComponent('administrative_area_level_1'),
      postalCode: getAddressComponent('postal_code'),
      country: getAddressComponent('country'),
      city: getAddressComponent('locality'),
    };
  }
}
