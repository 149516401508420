import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { TableModule } from '@web-frontend/shared/components/table';

import { BudgetsRoutingModule } from './budgets-routing.module';
import { BudgetsComponent } from './budgets.component';
import { BudgetListModule } from '@web-frontend/shared/components/budget-list/budget-list.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { BudgetSettingsModule } from '@web-frontend/shared/components/budget-setttings/budget-settings.module';
import { BudgetsEditModule } from './budget-edit/budgets-edit.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatesModule } from '../rates/rates.module';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { NotResultFoundModule } from '@web-frontend/shared/components/not-result-found';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { SelectSequenceModule } from '@web-frontend/shared/components/select-sequence/select-sequence.module';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { ClickSequenceModule } from '@web-frontend/shared/directives/click-sequence/click-sequence.module';
import { OpenSettingsModule } from '@web-frontend/shared/directives/open-settings/open-settings.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentsModule } from '../payments/payments.module';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { CreateBudgetModule } from '@web-frontend/shared/components/create-budget/create-budget.module';
import { BudgetMobileListModule } from '@web-frontend/shared/components/budget-mobile-list/budget-mobile-list.module';
import { ShowBudgetModule } from '@web-frontend/shared/components/show-budget/show-budget.module';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';
import { BasicSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/basic-snackbar/basic-snackbar.module';
import { ConfirmationModalModule } from '@web-frontend/shared/components/confirmation-modal';
import { UnsaveModule } from '@web-frontend/shared/guards/unsaved';
import { BillDigitalCertificateModule } from '@web-frontend/shared/components/bill-digital-certificate/bill-digital-certificate.module';
import { SnackbarModule } from '@web-frontend/shared/services/snackbar/snackbar.module';
import { TotalSummaryModule } from './total-summary/total-summary.module';
import { EmptyStateModule } from '@web-frontend/common/components/emptyState/empty-state.module';
import { RmSelectDateModule } from '../../../shared/components/globals/rm-select-date';
import { MfAccountingServiceModule } from '../../../microfrontends/mf-accounting-service/mf-accounting-service.module';

@NgModule({
  declarations: [BudgetsComponent],
  imports: [
    CommonModule,
    BudgetsRoutingModule,
    FilterModule,
    LoadingModule,
    BasicMessageModule,
    TableModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    BudgetListModule,
    BudgetSettingsModule,
    MatGridListModule,
    BudgetsEditModule,
    RatesModule,
    NgSelectModule,
    PermissionsModule,
    NotResultFoundModule,
    RmFilterModule,
    SelectSequenceModule,
    RomaToolTipModule,
    ClickSequenceModule,
    MatProgressSpinnerModule,
    PaymentsModule,
    StripeSnackbarSharedModule,
    BasicSnackbarSharedModule,
    CreateBudgetModule,
    BudgetMobileListModule,
    ShowBudgetModule,
    CurrencyFormatModule,
    DownloadBtnModule,
    ConfirmationModalModule,
    UnsaveModule,
    BillDigitalCertificateModule,
    SnackbarModule,
    OpenSettingsModule,
    TotalSummaryModule,
    EmptyStateModule,
    RmSelectDateModule,
    MfAccountingServiceModule,
  ],
  exports: [BudgetsComponent],
  providers: [DatePipe],
})
export class BudgetsModule {}
