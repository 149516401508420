import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BudgetsSetting,
  BUSINESS_TYPES,
  BusinessType,
  CUSTOMER_DATA,
  ValidateAllFormFields,
} from '@tacliatech/types';

import {
  CustomerService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';

import { Subscription } from 'rxjs';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { InvoiceService } from '@web-frontend/invoice/invoice.service';

@Component({
  selector: 'roma-budget-account-data',
  templateUrl: './budget-account-data.component.html',
  styleUrls: ['./budget-account-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetAccountDataComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  budget: BudgetsSetting;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  private sub$ = new Subscription();
  isLoading = true;

  businessTypes = BUSINESS_TYPES;
  defaultBusiness: BusinessType | string;
  fiscalRegimes: any[] = [];
  mexicanCompany = false;
  fiscalRegimesReady = false;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private i18n: TranslateService,
    private customerService: CustomerService,
    private toastService: ToastService,
    private userService: UserService,
    private invoiceService: InvoiceService
  ) {}

  form: FormGroup = this.fb.group({
    company_name: new FormControl(null),
    tradename: new FormControl(null),
    email: new FormControl(null),
    fiscalName: new FormControl(null),
    owner_id: new FormControl(StorageService.CompanyId),
    phone: new FormControl(null),
    business_type: new FormControl(null),
    fiscal_regime: new FormControl(null),
  });

  ngOnInit(): void {
    this.checkMode();
    this.checkCountry();
  }

  get showSnackbarMexico() {
    return !['company_name', 'fiscalName', 'fiscal_regime'].every((field) => {
      let value = this.form.get(field).value;
      return value && value !== '';
    });
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  async checkCountry() {
    if (this.userService.validateCountryMX()) {
      this.mexicanCompany = true;
      this.fiscalRegimesReady = false;
      await this.invoiceService.getTaxRegimeList().then((res) => {
        this.fiscalRegimes = res;
        this.fiscalRegimesReady = true;
        if (this.budget?.data_account?.fiscalRegime) {
          const initialFiscalRegime = this.fiscalRegimes.find(
            (item) => item.id === this.budget?.data_account?.fiscalRegime
          );
          if (initialFiscalRegime) {
            this.form.get('fiscal_regime').setValue(initialFiscalRegime.id);
          }
        }
      });
    }
  }

  checkMode() {
    if (this.mode === 'ADD') {
      this.budget = new BudgetsSetting();
    } else {
      const businessType = this.resolveBusinessType(
        this.budget.data_account.business_type
      );

      this.form.patchValue({
        company_name: this.budget?.data_account?.company_name,
        tradename: this.budget?.data_account?.tradename,
        email: this.budget?.data_account?.email,
        fiscalName: this.budget?.data_account?.fiscalName,
        phone: this.budget?.data_account?.phone,
        business_type: businessType?.id,
      });
    }
  }

  resolveBusinessType(type: BusinessType | string) {
    this.defaultBusiness = type;
    return this.businessTypes.find((b) => b.id == type);
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }
  isFormValid() {
    return this.form.valid;
  }

  validateForm() {
    ValidateAllFormFields(this.form);
  }
  submit() {
    const isValid = this.checkValidators();

    if (isValid) {
      const values = this.form.value;
      this.setObject(values);

      const obs$ =
        this.mode === 'EDIT'
          ? this.budgetService.updateOneSettings(this.id, this.budget)
          : this.budgetService.createOneSetting(this.budget);

      this.sub$.add(
        obs$.subscribe(
          (res) => {
            this.budgetUtilisService.refreshBudgetSettings.emit(res);
            this.draw();

            this.updateCustomer();
            if (this.mode === 'EDIT') {
              this.toastService.show({
                text: this.i18n.instant('budgets.settings.successEdit'),
                type: 'success',
                msDuration: 4000,
              });
            } else {
              this.mode = 'EDIT';
              this.toastService.show({
                text: this.i18n.instant('budgets.settings.successCreated'),
                type: 'success',
                msDuration: 4000,
              });
            }
          },
          (error) => {
            this.toastService.show({
              text: this.i18n.instant('activity.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
    } else {
      this.form.markAllAsTouched();
    }
  }
  numberOnly(event) {
    const rawValue = event.target.value;
    const newValue = rawValue.replace(/[^0-9]/g, '');
    event.target.value = newValue;
  }

  updateCustomer() {
    const customerId = StorageService.CustomerId;
    const customerData = StorageService.customerData;
    this.form.get('business_type').value !== this.defaultBusiness
      ? this.customerService
          .updateOne(customerId, {
            business_type: this.form.get('business_type').value,
          })
          .subscribe((c) => {
            this.defaultBusiness = c.business_type;
            StorageService.SetItem(CUSTOMER_DATA, {
              ...JSON.parse(customerData),
              business_type: c.business_type,
            });
          })
      : false;
  }

  setObject(values) {
    this.budget.data_account.company_name = values.company_name;
    this.budget.data_account.tradename = values.tradename;
    this.budget.data_account.email = values.email;
    this.budget.data_account.phone = values.phone;
    this.budget.data_account.fiscalName = values.fiscalName;
    this.budget.idOwner = values.owner_id;
    this.budget.data_account.business_type = values.business_type;
    if (this.mexicanCompany) {
      this.budget.data_account.fiscalRegime = values.fiscal_regime;
    }
  }
}
