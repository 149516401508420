import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';

import { RmSelect } from '../rm-select/rm-select.types';
import { TranslateService } from '@ngx-translate/core';
import {
  MatDatepickerInputEvent,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { getDateStrResum, getDateStrResumStr } from '../../../utils';
import { dateFieldList, DateFields } from './rm-select-date.const';
import { RmSelectRegisterComponent } from '../rm-select-register';
import { AnalyticsService } from '../../../services/analytics/analytics.service';

export enum RangeSource {
  START = 'START',
  END = 'END',
}

@Component({
  selector: 'rm-select-date',
  templateUrl: './rm-select-date.component.html',
  styleUrls: ['./rm-select-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmSelectDateComponent implements OnInit {
  @ViewChild('picker') datePicker!: MatDateRangePicker<Date>;
  @ViewChild('pickerMobile') datePickerMobile!: MatDateRangePicker<Date>;
  @ViewChild('selectRegister') selectRegister!: RmSelectRegisterComponent;

  @Input()
  amplitudeType?: string;

  @Output() dateChange = new EventEmitter<{ from: string; to: string }>();

  startDate: Date | '';
  endDate: Date | '';

  dateList: RmSelect.Items = [];
  dateSelected: RmSelect.Items = [];
  selectPlaceHolder = 'budgets.settings.create.form.date_picker';
  openMobileViewType = false;
  isAddOpened = false;

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly i18n: TranslateService,
    private readonly elementRef: ElementRef,
    private readonly analyticsService: AnalyticsService
  ) {}

  get isMobile(): boolean {
    return window.innerWidth < 768 || window.innerHeight < 768;
  }

  ngOnInit(): void {
    this.dateList = dateFieldList;
  }

  public clearAll(): void {
    this.dateSelected = [];
    this.clearRange();
  }

  clearRange(): void {
    this.startDate = '';
    this.endDate = '';
    this.objectDate = { from: '', to: '' };
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.markForCheck();
  }

  addDate(): void {
    this.isAddOpened = true;
    this.datePicker?.open();
  }

  repositionPicker() {
    setTimeout(() => {
      const overlay = document.querySelector(
        '.cdk-overlay-pane'
      ) as HTMLElement;
      if (overlay) {
        overlay.style.position = 'fixed';
        overlay.style.bottom = '150px';
        overlay.style.left = (window.innerWidth - 296) / 2 + 'px';
      }
    }, 100);
  }

  changeDate(evt: RmSelect.Items = null): void {
    this.clearRange();
    if (evt?.length === 0) {
      this.objectDate = { from: '', to: '' };
      this.dateChange.emit(this.objectDate);
      return;
    }
    const eventMap = {
      [`budgets.settings.create.form.${DateFields.CURRENT_MONTH}`]: `${this.amplitudeType}_datepicker_currentmonth`,
      [`budgets.settings.create.form.${DateFields.CURRENT_YEAR}`]: `${this.amplitudeType}_datepicker_currentyear`,
      [`budgets.settings.create.form.${DateFields.PREVIOUS_MONTH}`]: `${this.amplitudeType}_datepicker_lastmonth`,
      [`budgets.settings.create.form.${DateFields.PREVIOUS_YEAR}`]: `${this.amplitudeType}_datepicker_lastyear`,
    };
    if (this.amplitudeType) {
      this.analyticsService.trackEvent({
        eventName: eventMap[evt?.[0].title],
        sources: ['amplitude'],
      });
    }
    this.objectDate = {
      from: evt?.[0].value.toString().split(' - ')?.[0],
      to: evt?.[0].value.toString().split(' - ')?.[1],
    };
    this.dateChange.emit(this.objectDate);
  }

  objectDate: { from: string; to: string } = { from: '', to: '' };
  isComplete = (): boolean => {
    return !!(this.objectDate?.from && this.objectDate?.to);
  };

  onOpen(): void {
    this.analyticsService.trackEvent({
      eventName: `${this.amplitudeType}_datepicker_filter_start`,
      sources: ['amplitude'],
    });
  }

  rangeDateChange(
    type: keyof typeof RangeSource,
    evt: MatDatepickerInputEvent<Date>
  ): void {
    const update = { ...this.objectDate };
    const value = evt.value;

    if (type === RangeSource.START) {
      this.startDate = value;
      update.from = value ? getDateStrResum(value).formattedDate : '';
      if (this.objectDate[1]) {
        update.to = null;
      }
    }
    if (type === RangeSource.END) {
      this.endDate = value;
      update.to = value ? getDateStrResum(value).formattedDate : '';
    }

    this.objectDate = { ...update };

    if (this.isComplete()) {
      this.analyticsService.trackEvent({
        eventName: `${this.amplitudeType}_datepicker_daterange`,
        sources: ['amplitude'],
      });
      this.openMobileViewType = false;
      this.selectRegister.showPanelElement = false;
      const dateFormatterFrom = getDateStrResumStr(this.objectDate.from);
      const dateFrom = `${dateFormatterFrom.day}/${
        dateFormatterFrom.month
      }/${dateFormatterFrom.year.slice(-2)}`;

      const dateFormatterTo = getDateStrResumStr(this.objectDate.to);
      const dateTo = `${dateFormatterTo.day}/${
        dateFormatterTo.month
      }/${dateFormatterFrom.year.slice(-2)}`;

      const dateFormatted = `${dateFrom} - ${dateTo}`;

      this.dateSelected = [
        {
          title: `${dateFormatted}`,
          value: `${dateFormatted}`,
          id: `${dateFormatted}`,
        },
      ];
      this.dateChange.emit(this.objectDate);
    }
  }
}

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: RmSelect.Items, filter: { title: string }): any {
    if (!items?.length || !filter?.title) {
      return items;
    }

    return items.filter((item) =>
      item.title.match(new RegExp(filter.title, 'i'))
    );
  }
}
