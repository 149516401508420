import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { MfAccountingServiceComponent } from './mf-accounting-service.component';
import { MfAccountingServiceRoutingModule } from './mf-accounting-service-routing.module';

const microfrontendKey = 'mf-accounting-service';

@NgModule({
  declarations: [MfAccountingServiceComponent],
  imports: [CommonModule, MfAccountingServiceRoutingModule],
  exports: [MfAccountingServiceComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppName,
      useValue: microfrontendKey,
    },
    {
      provide: AppUrl,
      useFactory: () => {
        return localStorage.getItem(microfrontendKey);
      },
    },
  ],
})
export class MfAccountingServiceModule {}
