import { Injectable } from '@angular/core';
import { environment } from '@web-frontend/environments';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';

import io from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: SocketIOClient.Socket | null | undefined;
  private socketConnectedSource = new BehaviorSubject<boolean>(false);

  socketConnected$ = this.socketConnectedSource.asObservable();

  init(): void {
    this.createSocket();
    this.connect();
  }

  createSocket(): void {
    const socket = io(environment.api.socketIoConfig.url, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
    });

    this.socket = socket;
  }

  connect(): void {
    if (this.socket?.connected === false) {
      this.socket.connect();
    }

    this.socket?.on('connect', () => {
      this.socketConnectedSource.next(true);
    });

    this.socket?.on('disconnect', () => {
      this.socketConnectedSource.next(false);
    });
  }

  listen<T = unknown>(eventName: string): Observable<T> {
    return new Observable((obs) => {
      const socket = this.socket;

      const handler = (data: T) => {
        obs.next(data);
      };

      socket?.on(eventName, handler);

      return {
        unsubscribe: () => {
          socket?.off(eventName, handler);
        },
      };
    });
  }

  emit<T = unknown>(eventName: string, data: T): void {
    try {
      if (!this.socket?.connected) {
        this.socket?.connect();
      }
      this.socket?.emit(eventName, {
        ...data,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  off(): void {
    try {
      if (this.socket) {
        this.socket.removeAllListeners();
        this.socket.disconnect();
        this.socket = null;
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
}
