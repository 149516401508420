import { SafeResourceUrl } from '@angular/platform-browser';
import { StorageService } from '../../../shared/services';

export interface EmptyStateContent {
  title?: string;
  description?: string;
  items?: string[];
  firstButton?: string;
  firstButtonClass?: string;
  firstButtonIcon?: string;
  secondButton?: string;
  secondButtonClass?: string;
  secondButtonIcon?: string;
  hideSecondaryOnMobile?: boolean;
  containerButtonClass?: string;
  image?: string;
  videoId?: SafeResourceUrl;
  containerLeftClass?: string;
}

const getLang = (): string => {
  return StorageService.GetItem('USER_LANG')?.toLowerCase() || 'en';
};

export const contentMap: { [key: string]: EmptyStateContent } = {
  bill: {
    title: 'emptyState.bill.title',
    firstButton: 'emptyState.bill.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    image: `assets/images/emptyState/invoice_${getLang()}.svg`,
    items: [
      'emptyState.bill.item1',
      'emptyState.bill.item2',
      'emptyState.bill.item3',
    ],
  },
  billempty: {
    firstButton: 'emptyState.bill.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    containerLeftClass: 'full-width',
    containerButtonClass: 'full-width',
    image: `assets/images/emptyState/invoice_${getLang()}.svg`,
  },
  bdg: {
    title: 'emptyState.bdg.title',
    firstButton: 'emptyState.bdg.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    image: getLang() === 'en' && 'assets/images/emptyState/quote_en.svg',
    videoId: getLang() === 'es' && 'UVYSSmWotm4',
    items: [
      'emptyState.bdg.item1',
      'emptyState.bdg.item2',
      'emptyState.bdg.item3',
    ],
  },
  bdgempty: {
    firstButton: 'emptyState.bdg.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    containerLeftClass: 'full-width',
    containerButtonClass: 'full-width',
    image: getLang() === 'en' && 'assets/images/emptyState/quote_en.svg',
    videoId: getLang() === 'es' && 'UVYSSmWotm4',
  },
  profm: {
    title: 'emptyState.profm.title',
    firstButton: 'emptyState.profm.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    image: getLang() === 'en' && 'assets/images/emptyState/proform_en.svg',
    videoId: getLang() === 'es' && 'J8iv__e0W3Y',
    items: [
      'emptyState.profm.item1',
      'emptyState.profm.item2',
      'emptyState.profm.item3',
    ],
  },
  profmempty: {
    firstButton: 'emptyState.profm.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    containerLeftClass: 'full-width',
    containerButtonClass: 'full-width',
    image: getLang() === 'en' && 'assets/images/emptyState/proform_en.svg',
    videoId: getLang() === 'es' && 'J8iv__e0W3Y',
  },
  wal: {
    title: 'emptyState.wal.title',
    firstButton: 'emptyState.wal.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    image: getLang() === 'en' && 'assets/images/emptyState/waybill_en.svg',
    videoId: getLang() === 'es' && 'bVQNuaPwMGI',
    items: [
      'emptyState.wal.item1',
      'emptyState.wal.item2',
      'emptyState.wal.item3',
    ],
  },
  walempty: {
    firstButton: 'emptyState.wal.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    containerLeftClass: 'full-width',
    containerButtonClass: 'full-width',
    image: getLang() === 'en' && 'assets/images/emptyState/waybill_en.svg',
    videoId: getLang() === 'es' && 'bVQNuaPwMGI',
  },
  catalogue: {
    title: 'emptyState.catalogue.title',
    firstButton: 'emptyState.catalogue.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    image: `assets/images/emptyState/catalogue_${getLang()}.svg`,
    items: [
      'emptyState.catalogue.item1',
      'emptyState.catalogue.item2',
      'emptyState.catalogue.item3',
    ],
  },
  expense: {
    title: 'emptyState.expense.title',
    firstButton: 'emptyState.expense.firstButton',
    firstButtonClass: 'primary',
    firstButtonIcon: 'assets/icons/gl_plus_white.svg',
    image: `assets/images/emptyState/expense_${getLang()}.svg`,
    items: [
      'emptyState.expense.item1',
      'emptyState.expense.item2',
      'emptyState.expense.item3',
    ],
  },
};
