import {
  Component,
  ElementRef,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MicrofrontendComponent } from '../microfrontend.component';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  AnalyticsService,
  Source,
} from '../../shared/services/analytics/analytics.service';
import { TutorialService } from '../../shared/services/tutorial';
import { VideoService } from '../../shared/components/modal-video/video.service';
import { GeolocationService } from '../../shared/services';

export enum EventType {
  openAcademyLink = 'openAcademyLink',
  openAcademyLinkGeolocation = 'openAcademyLinkGeolocation',
  showYoutubeTutorial = 'showYoutubeTutorial',
  stopwatchStopped = 'stopwatchStopped',
  downloadTimeEntries = 'downloadTimeEntries',
  getCurrentLocationNative = 'getCurrentLocationNative',
  sendAnalyticsEvent = 'sendAnalyticsEvent',
}

type EventPayload = { event: string } | { path: string; params?: Params };

type SendEventPayload = { id: string };

type EventData = {
  type: EventType;
  payload?: EventPayload;
};

type SendEventData = {
  type: EventType;
  payload?: SendEventPayload;
};

@Component({
  selector: 'roma-mf-time-tracking',
  template: `<mf-time-tracking></mf-time-tracking>`,
  styles: [],
})
export class MfTimeTrackingComponent extends MicrofrontendComponent<
  EventData,
  SendEventData
> {
  @Input() appName = 'mf-time-tracking';
  @Input() appUrl: string = localStorage.getItem('mf-time-tracking');

  @Output() onClickDownload: EventEmitter<void> = new EventEmitter();
  @Output() onUpdateStopwatch: EventEmitter<void> = new EventEmitter();

  academyLink = '';
  academyLinkGeolocation = '';

  constructor(
    private readonly analyticsService: AnalyticsService,
    @Inject(AppName) appName: string,
    @Inject(AppUrl) appUrl: string,
    private _httpClient: HttpClient,
    private _el: ElementRef,
    private tutorialService: TutorialService,
    private video: VideoService,
    private geolocationService: GeolocationService
  ) {
    super(_httpClient, _el);
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
  }

  onInit(): void {
    this.loadAcademyLink();
    this.loadAcademyLinkGeolocation();
  }

  private async loadAcademyLink(): Promise<void> {
    try {
      const clockHoursMine = await this.tutorialService
        .get('clock-hours-mine')
        .toPromise();
      this.academyLink = clockHoursMine?.academy;
    } catch (error) {}
  }

  private async loadAcademyLinkGeolocation(): Promise<void> {
    try {
      const clockHourSettings = await this.tutorialService
        .get('clock-hour-settings')
        .toPromise();
      this.academyLinkGeolocation = clockHourSettings?.academy;
    } catch (error) {}
  }

  handleCustomEvent(event: CustomEvent<EventData>): void {
    const { type, payload } = event.detail;
    const eventsActionsMap = {
      [EventType.openAcademyLink]: this.handleOpenAcademyLink,
      [EventType.openAcademyLinkGeolocation]: this
        .handleOpenAcademyLinkGeolocation,
      [EventType.showYoutubeTutorial]: this.handleShowYoutubeTutorial,
      [EventType.stopwatchStopped]: this.handleStopwatchStopped,
      [EventType.downloadTimeEntries]: this.handleDownloadTimeEntries,
      [EventType.getCurrentLocationNative]: this.handleGetCurrentLocationNative,
      [EventType.sendAnalyticsEvent]: this.handleAnalyticsEvent,
    };

    const action = eventsActionsMap[type];
    if (action) {
      action.call(this, payload);
    }
  }

  async handleAnalyticsEvent({
    event,
    sources,
    properties = {},
  }: {
    event: string;
    sources: Source[];
    properties?: { [key: string]: string };
  }): Promise<void> {
    this.analyticsService.trackEvent({
      sources: sources,
      eventName: event,
      eventProperties: properties,
    });
  }

  handleOpenAcademyLink(): void {
    if (!this.academyLink) return;
    window.open(`${this.academyLink}`, '_blank');
  }

  handleOpenAcademyLinkGeolocation(): void {
    if (!this.academyLinkGeolocation) return;
    window.open(`${this.academyLinkGeolocation}`, '_blank');
  }

  handleShowYoutubeTutorial(): void {
    this.video.open('clock-hours-mine');
  }

  handleDownloadTimeEntries(): void {
    this.onClickDownload.emit();
  }

  handleStopwatchStopped(): void {
    this.onUpdateStopwatch.emit();
  }

  handleGetCurrentLocationNative(): void {
    this.geolocationService
      .handleGetGeolocationInMobile()
      .then((position) =>
        this.dispatchLocationEvent(position?.lat, position?.lng)
      )
      .catch(() => this.dispatchLocationEvent(0, 0));
  }

  private dispatchLocationEvent(lat = 0, lng = 0): void {
    const event = new CustomEvent('currentLocationNative', {
      detail: {
        type: 'currentLocationNative',
        location: { lat, lng },
      },
    });
    window.dispatchEvent(event);
  }

  onDestroy(): void {}
}
