<div class="wrapper__body">
  <div class="wrapper__body__content">
    <!-- content scrolleable, init feature here -->
    <form [formGroup]="form">
      <div class="reference__text" *ngIf="dealData?._id">
        {{ 'deals.dealDetail.ref' | translate }}
        <span class="reference__number">{{ dealData?._id }}</span>
      </div>
      <!-- deal title -->
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <input
          #titleInput
          type="text"
          class="title-input"
          id="name"
          autofocus
          aria-describedby="titleHelp"
          formControlName="name"
          [placeholder]="'deals.createForm.titlePlaceholder' | translate"
          [ngClass]="{ 'is-invalid': fieldTitle.displayError || false }"
          (focusout)="titleInputChange(titleInput.value)"
        />
      </div>

      <roma-field-error-display
        #fieldTitle
        fieldName="name"
        [errorMsg]="'deals.createForm.titleError'"
        [form]="form"
      >
      </roma-field-error-display>
      <!-- deal description -->
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <textarea
          #descriptionInput
          type="text"
          class="description-input"
          formControlName="description"
          [placeholder]="'deals.createForm.descriptionPlaceholder' | translate"
          maxLength="1000"
          (focusout)="descriptionInputChange(descriptionInput.value)"
        ></textarea>
      </div>

      <!-- Productos y servicios -->
      <ng-container
        *ngIf="(idActiveModules$ | async).includes(idModuleRef.Sales)"
      >
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="{
            type: 'every',
            features: [featureUserScreen, featureRefUser.Catalogue.show]
          }"
        >
          <div class="wrapper__body__content__title">
            {{ 'deals.dealDetail.productsServices' | translate }}
          </div>
          <roma-rates-select-table
            [dealProducts]="dealProducts"
            (changeProducts)="changeProducts($event)"
          ></roma-rates-select-table>
        </div>
      </ng-container>

      <div class="wrapper__body__content__divider"></div>

      <div class="wrapper__body__content__title">
        {{ 'service_card.detail_service' | translate }}
      </div>

      <mat-progress-spinner
        *ngIf="isLoadingDetailOfDeal"
        mode="indeterminate"
        [diameter]="40"
        style="margin: 0px auto"
      ></mat-progress-spinner>

      <!-- items detail service-->
      <ng-container *ngIf="!isLoadingDetailOfDeal">
        <!-- final -->
        <ng-container
          *ngIf="(idActiveModules$ | async).includes(idModuleRef.Finals)"
        >
          <div
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="featureUserScreen"
          >
            <div
              class="wrapper__body__content__field wrapper-pro-feature-user"
              romaProSelect
              [featureUser]="featureRefUser.Final.show"
            >
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  <img src="assets/icons/gl_user-outline.svg" />
                  {{ 'activity.create.form.idFinal' | translate | uppercase }}
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <button
                  type="button"
                  class="add-field-btn"
                  *ngIf="!showFinalsSelect"
                  (click)="showFinalsSelect = true"
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -5px"
                  />
                </button>
                <rm-select
                  *ngIf="showFinalsSelect"
                  [isClearable]="true"
                  [placeholderText]="'activity.create.form.idFinal' | translate"
                  [items]="finalsSelectItems"
                  [isLoading]="finalsLoading"
                  [disabled]="finalsLoading"
                  [activeItems]="selectedFinal"
                  [isAbsolute]="true"
                  [hasError]="
                    controls?.idFinal?.errors?.required &&
                    controls?.idFinal?.touched &&
                    controls?.idFinal?.dirty &&
                    !selectedFinal?.length
                  "
                  inputHeight="36px"
                  type="users"
                  [addButtonText]="'general.addFinal'"
                  [addButtonFeatureUser]="featureRefUser.Final.create"
                  (changeItems)="changeSelectItem($event, 'idFinal')"
                  (click)="onClickSelect('finals')"
                  (addElement)="addFinals()"
                  style="width: 100%"
                >
                </rm-select>

                <input type="hidden" formControlName="idFinal" />
              </div>
            </div>
          </div>
        </ng-container>
        <!--/ final -->

        <!-- date -->
        <div
          [ngClass]="{ is__date__active: showDateField }"
          romaProSelect
          class="wrapper__body__content__field wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <div class="wrapper__body__content__field__label">
            <label class="field-label">
              <img src="assets/icons/gl_calendar-outline.svg" />
              {{ 'activity.create.form.dateTime' | translate | uppercase }}
            </label>
          </div>
          <div class="wrapper__body__content__field__content">
            <div class="wrapper__body__content__field__content__header">
              <button
                *ngIf="!showDateField"
                type="button"
                class="add-field-btn"
                (click)="toggleDateField()"
              >
                {{ 'general.add2' | translate }}
                <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
              </button>

              <ng-container *ngIf="showDateField">
                <mat-form-field appearance="outline" class="deal__date">
                  <input
                    #input
                    matInput
                    [matDatepicker]="picker"
                    [(ngModel)]="initDate"
                    (ngModelChange)="trackChangeDateIfIsValid($event)"
                    placeholder="--/--/----"
                    formControlName="initDate"
                    (focus)="picker.open()"
                  />
                  <mat-datepicker
                    #picker
                    (closed)="input.blur()"
                  ></mat-datepicker>
                </mat-form-field>
                <!-- start hour -->
                <div
                  class="deal__hour__wrapper"
                  [class]="{
                    'deal__hour__wrapper--active': dealDate.showInitHourPanel
                  }"
                >
                  <input
                    type="text"
                    class="deal__hour__wrapper__input"
                    placeholder="--:--"
                    mask="Hh:m0"
                    [dropSpecialCharacters]="false"
                    formControlName="initHour"
                    (focus)="openHour('START')"
                  />
                  <span
                    *ngIf="dealDate.showInitHourPanel"
                    class="deal__hour__overlay_elements"
                    (click)="dealDate.showInitHourPanel = false"
                  ></span>
                  <div class="deal__hour__wrapper__elements">
                    <div
                      *ngFor="
                        let hour of filterHours(
                          form.controls['initHour'].value,
                          'initHour'
                        )
                      "
                      class="deal__hour__wrapper__elements__item"
                      (click)="selectHour('START', hour)"
                    >
                      {{ hour }}
                    </div>
                  </div>
                </div>

                <div class="deal__line"></div>

                <!-- end hour -->
                <div
                  class="deal__hour__wrapper"
                  [class]="{
                    'deal__hour__wrapper--active': dealDate.showEndHourPanel
                  }"
                >
                  <input
                    type="text"
                    class="deal__hour__wrapper__input"
                    placeholder="--:--"
                    mask="Hh:m0"
                    [dropSpecialCharacters]="false"
                    formControlName="endHour"
                    (focus)="openHour('END')"
                  />
                  <span
                    *ngIf="dealDate.showEndHourPanel"
                    class="deal__hour__overlay_elements"
                    (click)="dealDate.showEndHourPanel = false"
                  ></span>
                  <div class="deal__hour__wrapper__elements">
                    <div
                      *ngFor="
                        let hour of filterHours(
                          form.controls['endHour'].value,
                          'endHour'
                        )
                      "
                      class="deal__hour__wrapper__elements__item"
                      (click)="selectHour('END', hour)"
                    >
                      {{ hour }}
                    </div>
                  </div>
                </div>

                <img
                  class="__hour_clear"
                  *ngIf="form.value?.initHour && form.value?.endHour"
                  src="assets/icons/gl_close.svg"
                  (click)="clearHourInputs()"
                />
              </ng-container>
            </div>
            <!-- <div
              *ngIf="showDateField"
              class="wrapper__body__content__field__content__body"
            >
              <div
                class="wrapper__body__content__field__content__body__repeat"
                [ngClass]="{
                  'wrapper__body__content__field__content__body__repeat--blocked': isDealRecurrence
                }"
              >
                <mat-checkbox formControlName="isAllDay">
                  {{ 'Todo el día' | translate }}
                </mat-checkbox>

                <img src="assets/icons/repeat.svg" />
                <span (click)="openModalRecurrence()">
                  {{ labelRecurrence }}
                </span>
              </div>
            </div> -->
          </div>
        </div>
        <!--/ date -->

        <!-- taskers or users assigned -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <div class="wrapper__body__content__field">
            <div class="wrapper__body__content__field__label">
              <label class="field-label">
                <img src="assets/icons/gl_contacto.svg" />
                {{ 'filter.assigned' | translate | uppercase }}
              </label>
            </div>
            <div class="wrapper__body__content__field__content">
              <rm-responsive-select
                mode="MULTIPLE"
                inputHeight="36px"
                listWidth="260px"
                class="alt-dropdown"
                [activeItems]="selectedAssigned"
                [isLoading]="assignedLoading"
                [items]="assignedSelectItems"
                [primaryDesign]="false"
                [placeholderText]="
                  'activity.create.form.searchOwner' | translate
                "
                (changeItems)="changeSelectItem($event, 'taskers')"
                (click)="onClickSelect('taskers')"
                [addButtonText]="'general.addUser'"
                [addButtonFeatureUser]="featureRefUser.User.create"
                (addElement)="addUsers()"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="taskers" />
            </div>
          </div>
        </div>
        <!--/ taskers or users assigned -->

        <!-- assets -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="{
            type: 'every',
            features: [featureUserScreen, featureRefUser.Address.show]
          }"
        >
          <div
            *ngIf="
              (idActiveModules$ | async).includes(idModuleRef.Assets) &&
              checkboxItems.showAssetInput
            "
            class="wrapper__body__content__field"
          >
            <div class="wrapper__body__content__field__label">
              <label class="field-label">
                <img src="assets/icons/gl_direcciones.svg" />
                {{ 'activity.create.form.asset' | translate | uppercase }}
              </label>
            </div>
            <div class="wrapper__body__content__field__content">
              <rm-responsive-select
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                class="alt-dropdown"
                [activeItems]="selectedAssets"
                [isLoading]="assetsLoading"
                [placeholderText]="'activity.create.form.asset' | translate"
                [items]="assetSelectItems"
                [primaryDesign]="false"
                (click)="onClickSelect('assets')"
                (changeItems)="changeSelectItem($event, 'asset')"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="asset" />
            </div>
          </div>
        </div>
        <!--/ assets -->

        <!-- projects -->
        <!-- idProject -->
        <ng-container
          *ngIf="
            (idActiveModules$ | async).includes(idModuleRef.Projects) &&
            checkboxItems.showProjectInput
          "
        >
          <div
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Project.show]
            }"
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  <img src="assets/icons/gl_proyectos.svg" />
                  {{ 'activity.create.form.idProject' | translate | uppercase }}
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <rm-responsive-select
                  mode="SINGLE"
                  inputHeight="36px"
                  listWidth="260px"
                  class="alt-dropdown"
                  [activeItems]="selectedProjects"
                  [isLoading]="projectsLoading"
                  [placeholderText]="
                    'activity.create.form.idProject' | translate
                  "
                  [items]="projectsSelectItems"
                  [primaryDesign]="false"
                  (click)="onClickSelect('projects')"
                  (changeItems)="changeSelectItem($event, 'idProject')"
                >
                </rm-responsive-select>
                <input type="hidden" formControlName="idProject" />
              </div>
            </div>
          </div>
        </ng-container>
        <!--/ projects -->
        <!-- status -->
        <ng-container *ngIf="checkboxItems.showStatusInput">
          <div
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="featureUserScreen"
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  <img src="assets/icons/gl_kanban-outline.svg" />
                  {{ 'general.status' | translate | uppercase }}
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <rm-status-select
                  mode="SINGLE"
                  inputHeight="36px"
                  listWidth="260px"
                  [items]="statusSelectItems"
                  [activeItems]="selectedStatus"
                  [placeholderText]="selectedStatusTitle"
                  [titleText]="'general.status' | translate"
                  [isLoading]="statusLoading"
                  (click)="onClickSelect('status')"
                  (changeItems)="changeSelectItem($event, 'status')"
                ></rm-status-select>
                <input type="hidden" formControlName="status" />
              </div>
            </div>
          </div>
        </ng-container>
        <!--/ status -->

        <!-- responsible -->
        <ng-container *ngIf="checkboxItems.showReporterInput">
          <div
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="featureUserScreen"
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  <img src="assets/icons/gl_contacto.svg" />
                  {{ 'imports.columns.responsible' | translate | uppercase }}
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <rm-responsive-select
                  mode="SINGLE"
                  inputHeight="36px"
                  listWidth="260px"
                  class="alt-dropdown"
                  [activeItems]="selectedResponsibles"
                  [isLoading]="responsiblesLoading"
                  [placeholderText]="'imports.columns.responsible' | translate"
                  [items]="responsiblesSelectItems"
                  [primaryDesign]="false"
                  (click)="onClickSelect('responsible')"
                  (changeItems)="changeSelectItem($event, 'reporter')"
                >
                </rm-responsive-select>
                <input type="hidden" formControlName="reporter" />
              </div>
            </div>
          </div>
        </ng-container>
        <!--/ responsible -->

        <!-- equipment -->
        <ng-container
          *ngIf="
            (idActiveModules$ | async).includes(idModuleRef.Equipments) &&
            checkboxItems.showEquipmentInput
          "
        >
          <div
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Equipment.show]
            }"
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  <img src="assets/icons/gl_contacto.svg" />
                  {{ 'sidebar.equipment' | translate | uppercase }}
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <rm-responsive-select
                  mode="MULTIPLE"
                  inputHeight="36px"
                  listWidth="260px"
                  class="alt-dropdown"
                  [isLoading]="isEquipmentsLoading"
                  [activeItems]="selectedEquipments"
                  [placeholderText]="'sidebar.equipment' | translate"
                  [items]="equipmentsSelectItems"
                  [primaryDesign]="false"
                  (click)="onClickSelect('equipment')"
                  (changeItems)="changeSelectItem($event, 'idEquipments')"
                >
                </rm-responsive-select>
                <input type="hidden" formControlName="reporter" />
              </div>
            </div>
          </div>
        </ng-container>
        <!--/ equipment -->

        <!-- product -->
        <ng-container *ngIf="checkboxItems.showProductInput">
          <div
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="featureUserScreen"
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  <img src="assets/icons/gl_contacto.svg" />
                  {{ 'hiring.type.category' | translate | uppercase }}
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <rm-responsive-select
                  mode="SINGLE"
                  inputHeight="36px"
                  listWidth="260px"
                  class="alt-dropdown"
                  [activeItems]="selectedProducts"
                  [isLoading]="isProductsLoading"
                  [placeholderText]="'hiring.form.placeholder_prod' | translate"
                  [items]="productsSelectItems"
                  [primaryDesign]="false"
                  (click)="onClickSelect('product')"
                  (changeItems)="changeSelectItem($event, 'product_id')"
                >
                </rm-responsive-select>
                <input type="hidden" formControlName="product_id" />
              </div>
            </div>
          </div>
        </ng-container>
        <!--/ product -->

        <!-- view more option -->
        <button
          #seeMore="matMenuTrigger"
          type="button"
          class="add-field-btn see-more-btn"
          [matMenuTriggerFor]="inputsMenu"
          [ngClass]="{ 'is-open': seeMore.menuOpen }"
        >
          {{ 'general.seeMore' | translate }}
          <img
            src="assets/icons/gl_arrow-down-blue.svg"
            style="margin-top: -5px"
          />
        </button>
        <mat-menu
          #inputsMenu="matMenu"
          class="dropdown-wrapper bordered-wrapper"
        >
          <div class="list-wrapper">
            <div class="list-item">
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showStatusInput')"
                [checked]="checkboxItems.showStatusInput"
              >
                {{ 'tasks.status' | translate }}
              </mat-checkbox>
            </div>
            <div class="list-item">
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showReporterInput')"
                [checked]="checkboxItems.showReporterInput"
              >
                {{ 'task.owner' | translate }}
              </mat-checkbox>
            </div>
            <div
              *ngIf="
                (idActiveModules$ | async).includes(idModuleRef.Equipments)
              "
              class="list-item"
              romaProFeature
              [featureUserHide]="featureRefUser.Equipment.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showEquipmentInput')"
                [checked]="checkboxItems.showEquipmentInput"
              >
                {{ 'sidebar.equipment' | translate }}
              </mat-checkbox>
            </div>
            <div
              *ngIf="(idActiveModules$ | async).includes(idModuleRef.Assets)"
              class="list-item"
              romaProFeature
              [featureUserHide]="featureRefUser.Address.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showAssetInput')"
                [checked]="checkboxItems.showAssetInput"
              >
                {{ 'task.asset' | translate }}
              </mat-checkbox>
            </div>
            <div
              class="list-item"
              *ngIf="(idActiveModules$ | async).includes(idModuleRef.Projects)"
              romaProFeature
              [featureUserHide]="featureRefUser.Project.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showProjectInput')"
                [checked]="checkboxItems.showProjectInput"
              >
                {{ 'activity.create.form.idProject' | translate }}
              </mat-checkbox>
            </div>
            <div class="list-item">
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showProductInput')"
                [checked]="checkboxItems.showProductInput"
              >
                {{ 'catalogue.create.category' | translate }}
              </mat-checkbox>
            </div>
          </div>
        </mat-menu>
        <!--/ view more option -->
      </ng-container>
      <!-- end items detail service-->

      <div class="wrapper__body__content__divider"></div>

      <div class="custom__properties__container row">
        <!-- custom-properties -->
        <h4
          class="subtitle"
          (click)="showCustomProperties = !showCustomProperties"
          style="cursor: pointer"
        >
          {{ 'settings.home-customization.customFieldsTitle' | translate }}
          <img
            *ngIf="!showCustomProperties"
            src="assets/icons/gl_up-arrow.svg"
          />
          <img
            *ngIf="showCustomProperties"
            src="assets/icons/gl_down_arrow.svg"
          />
        </h4>
        <!-- Edit custom properties -->
        <roma-edit-custom-properties
          *ngIf="type === 'EDIT'"
          [smSize]="true"
          [showCustomProperties]="showCustomProperties"
          [idSchema]="dealData?._id"
          [typeRef]="typePropertyRef.Deal"
          [parentForm]="form"
          [customProperties]="dealData?.customProperties"
          [feature]="featureRef.Deal.HandleCustomProperties"
          [featureUser]="featureUserScreen"
          [options]="{ showAlertMessage: false }"
          [updateOnEdit]="false"
          (propertiesRequired)="propertiesRequired($event)"
          (updateLoading)="updateLoading($event)"
          (propertiesLoading)="propertiesLoading($event)"
          (onValueChanged)="eventValueChanged($event)"
          (closeModal)="close()"
          [showPropertiesMessage]="
            customPropertiesLoaded && customProperties.length === 0
          "
        ></roma-edit-custom-properties>
        <!-- Add custom properties -->
        <roma-append-custom-properties
          *ngIf="type === 'ADD'"
          [typeRef]="typePropertyRef.Deal"
          [control]="form.get('customProperties')"
          [feature]="featureRef.Deal.HandleCustomProperties"
          [showCustomProperties]="showCustomProperties"
          [parentForm]="form"
          [initForm]="initChildForm"
          [parentFormSubmit]="parentFormSubmit"
          [showIsLoading]="type === 'ADD'"
          (customPropertiesForm)="customPropertiesFormChange($event)"
          (propertiesLoading)="type !== 'EDIT' && propertiesLoading($event)"
          (initialCharge)="initialChargeEvent()"
          (closeModal)="close()"
          [showPropertiesMessage]="
            customPropertiesLoaded && customProperties.length === 0
          "
        ></roma-append-custom-properties>
      </div>

      <div class="wrapper__body__content__divider"></div>

      <!-- customPropertiesGroupSelectItems -->
      <h4
        class="subtitle"
        (click)="showCustomPropertiesGroup = !showCustomPropertiesGroup"
        style="cursor: pointer"
      >
        {{ 'template.customPropertiesGroup' | translate }}
        <img
          *ngIf="!showCustomPropertiesGroup"
          src="assets/icons/gl_up-arrow.svg"
        />
        <img
          *ngIf="showCustomPropertiesGroup"
          src="assets/icons/gl_down_arrow.svg"
        />
      </h4>
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <div
          *ngIf="showCustomPropertiesGroup"
          class="wrapper__body__content__field"
        >
          <div class="wrapper__body__content__field__label">
            <label class="field-label">
              {{ 'template.template' | translate | uppercase }}
            </label>
          </div>
          <div class="wrapper__body__content__field__content">
            <rm-responsive-select
              mode="SINGLE"
              inputHeight="36px"
              listWidth="260px"
              class="alt-dropdown"
              [activeItems]="selectedCustomGroupProperties"
              [isLoading]="isCustomGroupPropertyLoading"
              [items]="customPropertiesGroupSelectItems"
              [primaryDesign]="false"
              [placeholderText]="'general.select' | translate"
              (changeItems)="changeSelectItem($event, 'idGroup')"
              (click)="onClickSelect('customGroupProperties')"
            >
            </rm-responsive-select>
          </div>
        </div>
      </div>
      <!-- CUSTOM FORMS PROPERTIES -->
      <ng-container *ngIf="!loadingChangeGroupProperties && showTemplateForm">
        <div
          *ngFor="let property of customGroupProperties"
          class="custom-properties-container"
          [ngStyle]="{
            visibility: showCustomPropertiesGroup ? 'visible' : 'hidden',
            height: showCustomPropertiesGroup ? 'auto' : '0px'
          }"
        >
          <ng-container *ngIf="property?.isRequired">
            <input type="hidden" [formControlName]="property._id" />
          </ng-container>

          <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Select">
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  {{ property.name
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <button
                  *ngIf="!valueCustomProperty(property?._id)"
                  type="button"
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -3px"
                  />
                </button>
                <roma-editable-field
                  #selectInput
                  [id]="property._id"
                  [value]="valueCustomProperty(property?._id)"
                  [ngStyle]="
                    !valueCustomProperty(property?._id) && { display: 'none' }
                  "
                  [fieldName]="
                    property.type !== dynamicPropertyTypeRef.Select
                      ? 'value'
                      : 'options'
                  "
                  [elements]="
                    property.type === dynamicPropertyTypeRef.Select
                      ? property.options
                      : []
                  "
                  [isRequired]="property.isRequired"
                  [type]="typeCustomProperty(property?.type)"
                  [updateOnEdit]="false"
                  [isMultiple]="property.type === dynamicPropertyTypeRef.Select"
                  (onValueChanged)="
                    attachToCustomProperties(property, $event);
                    setCustomPropertyValue(
                      property.isRequired,
                      property._id,
                      $event
                    )
                  "
                  style="margin-bottom: 6px"
                >
                </roma-editable-field>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="property.type === dynamicPropertyTypeRef.Boolean"
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  {{ property.name
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <button
                  *ngIf="!valueCustomProperty(property?._id)"
                  type="button"
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -3px"
                  />
                </button>
                <select
                  #booleanInput
                  [id]="property._id"
                  [value]="valueCustomProperty(property?._id)"
                  class="select-editable form-control md-input"
                  [ngStyle]="
                    !valueCustomProperty(property?._id) && { display: 'none' }
                  "
                  placeholder="{{ 'activity.edit.selectOption' | translate }}"
                  (change)="
                    attachToCustomProperties(property, booleanInput.value);
                    setCustomPropertyValue(
                      property.isRequired,
                      property._id,
                      booleanInput.value
                    )
                  "
                >
                  <option *ngIf="property?.isRequired === false" value="">
                    Sin asignar
                  </option>

                  <option
                    value="true"
                    [attr.selected]="property.value === 'true' ? true : null"
                  >
                    {{ 'general.yes' | translate }}
                  </option>
                  <option
                    value="false"
                    [attr.selected]="property.value === 'false' ? true : null"
                  >
                    {{ 'general.no' | translate }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Date">
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  {{ property.name
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div
                class="wrapper__body__content__field__content"
                style="position: relative"
              >
                <button
                  *ngIf="!valueCustomProperty(property?._id)"
                  type="button"
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -3px"
                  />
                </button>
                <mat-form-field
                  appearance="fill"
                  #date
                  class="full-field"
                  [ngClass]="{
                    'is-invalid':
                      property.isRequired &&
                      form.controls[property._id].invalid &&
                      (form.controls[property._id].dirty ||
                        form.controls[property._id].touched) &&
                      parentFormSubmit
                  }"
                  [ngStyle]="
                    !valueCustomProperty(property?._id) && { display: 'none' }
                  "
                  [id]="property._id"
                  style="margin-bottom: 6px !important"
                >
                  <input
                    #dateInput
                    matInput
                    [matDatepicker]="picker"
                    class="input-editable date-input"
                    [value]="valueCustomProperty(property?._id)"
                    placeholder="dd/mm/aaaa"
                    (focus)="picker.open(); inputFocus()"
                    (click)="picker.open()"
                    (dateChange)="dateInputChange(property, dateInput.value)"
                    onkeydown="return false;"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    style="height: 35px"
                    (click)="picker.open(); inputFocus()"
                  >
                    <mat-icon
                      matDatepickerToggleIcon
                      style="
                        height: 35px;
                        position: relative;
                        display: block;
                        align-items: center;
                      "
                    >
                      <img
                        class="calendar-icon"
                        src="assets/icons/gl_calendar.svg"
                      />
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div
                  class="calendar-clean-icon"
                  matSuffix
                  [disableRipple]="true"
                  (click)="dateInputChange(property, ''); clearDate()"
                  *ngIf="dateInput.value !== ''"
                >
                  <mat-icon matDatepickerToggleIcon style="position: relative">
                    <img
                      class="calendar-icon"
                      src="assets/icons/gl_close-input.svg"
                    />
                  </mat-icon>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Number">
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  {{ property.name
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <button
                  *ngIf="
                    valueCustomProperty(property?._id) === 0
                      ? false
                      : !valueCustomProperty(property?._id)
                  "
                  type="button"
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -3px"
                  />
                </button>
                <input
                  #numberInput
                  [id]="property._id"
                  [value]="valueCustomProperty(property?._id)"
                  class="form-control md-input"
                  [ngStyle]="
                    valueCustomProperty(property?._id) === 0
                      ? false
                      : !valueCustomProperty(property?._id) && {
                          display: 'none'
                        }
                  "
                  [ngClass]="{
                    'is-invalid':
                      property.isRequired &&
                      form.controls[property._id].invalid &&
                      (form.controls[property._id].dirty ||
                        form.controls[property._id].touched) &&
                      parentFormSubmit
                  }"
                  type="number"
                  placeholder="{{ 'activity.enterValue' | translate }}"
                  min="0"
                  maxlength="25"
                  (keyup)="
                    attachToCustomProperties(property, +$event?.target?.value);
                    setCustomPropertyValue(
                      property.isRequired,
                      property._id,
                      +$event?.target?.value
                    )
                  "
                  (blur)="
                    +$event?.target?.value === 0
                      ? (numberInput.value = +$event?.target?.value)
                      : null
                  "
                />
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="property.type === dynamicPropertyTypeRef.String">
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label">
                <label class="field-label">
                  {{ property.name
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div class="wrapper__body__content__field__content">
                <button
                  *ngIf="!valueCustomProperty(property?._id)"
                  type="button"
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -3px"
                  />
                </button>
                <input
                  #stringInput
                  [id]="property._id"
                  [value]="valueCustomProperty(property?._id)"
                  [ngClass]="{
                    'is-invalid':
                      property.isRequired &&
                      form.controls[property._id].invalid &&
                      (form.controls[property._id].dirty ||
                        form.controls[property._id].touched) &&
                      parentFormSubmit
                  }"
                  class="form-control md-input"
                  [ngStyle]="
                    !valueCustomProperty(property?._id) && { display: 'none' }
                  "
                  type="text"
                  placeholder="{{ 'activity.enterText' | translate }}"
                  maxlength="250"
                  (keyup)="
                    attachToCustomProperties(property, $event?.target?.value);
                    setCustomPropertyValue(
                      property.isRequired,
                      property._id,
                      $event?.target?.value
                    )
                  "
                />
              </div>
            </div>
          </ng-container>
          <!-- property.isRequired errors -->
          <ng-container
            *ngIf="
              property.isRequired &&
              form.controls[property._id].invalid &&
              (form.controls[property._id].dirty ||
                form.controls[property._id].touched) &&
              parentFormSubmit
            "
          >
            <div class="wrapper__body__content__field">
              <div class="wrapper__body__content__field__label"></div>
              <div class="wrapper__body__content__field__content">
                <div>
                  <mat-error
                    class="error-label"
                    *ngIf="form.controls[property._id].errors.required"
                    >{{ 'general.requiredField' | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="wrapper__body__footer">
    <!-- footer of body -->
    <!-- [matTooltip]="showTooltipSave()" -->

    <button
      type="button"
      role="button"
      class="footer-button roma-button save-btn"
      [disabled]="isLoading || propertiesIsLoading || invalidEditCustomFields"
      (click)="onSaveClick()"
    >
      <mat-progress-spinner
        *ngIf="isLoadingSave"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
      <ng-container *ngIf="!isLoading">
        {{
          type === 'ADD'
            ? ('activity.create.submit' | translate)
            : ('activity.edit.submit' | translate)
        }}
      </ng-container>
    </button>

    <!-- Button to charge a deal -->
    <button
      type="button"
      role="button"
      [disabled]="
        !form?.value?.name ||
        isLoading ||
        propertiesIsLoading ||
        invalidEditCustomFields
      "
      romaProFeature
      [featureUser]="featureRefUser.Sale.create"
      class="footer-button roma-button charge-btn"
      (clicked)="onChargeClick()"
    >
      <ng-container *ngIf="!isLoading">
        {{ 'activity.charge' | translate }}
      </ng-container>
      <mat-progress-spinner
        *ngIf="isLoadingCharge"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
    </button>

    <button
      *ngIf="
        type === 'EDIT' &&
        !isDealRecurrence &&
        !isLoading &&
        !isLoadingDetailOfDeal
      "
      type="button"
      class="footer-button delete-btn"
      romaClickDelete
      romaProFeature
      [featureUser]="featureRefUser.Deal.delete"
      (click)="sendEvent()"
      (wantDelete)="eventDelete()"
      subtitle="deleteModal.body_final"
    >
      <img src="assets/icons/gl_trash_red.svg" />
      {{ 'general.deleteAlt' | translate }}
    </button>
    <!-- case delete recurrence deal -->
    <button
      *ngIf="
        type === 'EDIT' &&
        isDealRecurrence &&
        !isLoading &&
        !isLoadingDetailOfDeal
      "
      type="button"
      class="footer-button delete-btn"
      (click)="deleteRecurrenceDeal()"
    >
      <img src="assets/icons/gl_trash_red.svg" />
      {{ 'general.deleteAlt' | translate }}
    </button>
    <!--/ case delete recurrence deal -->
  </div>
</div>

<div class="wrapper__sidebar">
  <!-- sidebar definition -->
  <h4
    class="subtitle"
    [ngClass]="{ middle: !showDivImg }"
    (click)="toggleDivImg()"
  >
    {{ 'general.fileAttach' | translate }}
    <img *ngIf="!showDivImg" src="assets/icons/gl_up-arrow.svg" />
    <img *ngIf="showDivImg" src="assets/icons/gl_down_arrow.svg" />
  </h4>

  <div *ngIf="showDivImg">
    <roma-image-selector
      [type]="'deal'"
      [quantity]="10"
      [title]="'fileUpload.maxSize' | translate"
      [feature]="featureRef.Deal.UploadFiles"
      [fileArray]="imagesParsed"
      [featureUser]="featureUserScreen"
      (changeFileArray)="changeFileArray($event)"
      [module]="idModuleRef.Deals"
    >
    </roma-image-selector>
  </div>

  <h4 class="subtitle" (click)="toggleDivComments()">
    {{ 'equipments.create.notes' | translate }}
    <img
      [src]="
        'assets/icons/' +
        (showDivComments ? 'gl_up-arrow.svg' : 'gl_down_arrow.svg')
      "
    />
  </h4>
  <div
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureUserScreen"
  >
    <roma-equipment-notes
      [ngStyle]="!showDivComments && { visibility: 'hidden', height: '0' }"
      [mode]="type"
      [data]="dealNotes || []"
      (changeNotes)="addingNotes($event)"
    ></roma-equipment-notes>
  </div>
</div>
