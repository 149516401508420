<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<mat-table
  mat-table
  matSort
  [dataSource]="datasource"
  (matSortChange)="sortData($event)"
  [matSortActive]="activeSort.active"
  [matSortDirection]="activeSort.direction"
>
  <!-- Checkbox Column -->
  <ng-container [matColumnDef]="'select'">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      class="cdk-column-select"
    >
      <mat-checkbox
        (click)="toggleAllRows(); $event.stopPropagation()"
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row" class="cdk-column-select">
      <mat-checkbox
        (click)="selectRow(row); $event.stopPropagation()"
        (change)="$event ? selectRow(row) : null"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.date' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.header?.date
          ? (item?.header?.date?.replace('Z', '') | date: company_date_format)
          : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="numDoc">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.numberDoc' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{ getNumberDoc(item?.header) }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dueDate" *ngIf="optionalsColumns.dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.dueDate' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.header?.dueDate
          ? (item?.header?.dueDate?.replace('Z', '')
            | date: company_date_format)
          : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="customerName">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.customer' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.header?.contact
          ? item?.header?.final?.name +
            ' ' +
            (item?.header?.final?.lastName ? item?.header?.final?.lastName : '')
          : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.description' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.date ? item?.project?.name : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="category" *ngIf="optionalsColumns.category">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.category' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.addicionalInfo?.category_id
          ? item?.addicionalInfo?.category?.name
          : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.total' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell class="total">
      {{
        item?.total
          ? (item?.total | currencyFormat: 'currencyPosition')
          : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status_budget">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.status' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      <roma-rm-badge
        [statusName]="
          getStatus(item?.status)
            ? getStatus(item?.status)
            : ('billing.notAvailable' | translate)
        "
        [color]="getStatusColor(item?.status)"
      ></roma-rm-badge>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.status' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.date ? item?.project?.name : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="invoiced">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.total_charged' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.date ? item?.project?.name : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="updated">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.updated' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.date ? item?.project?.name : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="project">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'general.project' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{
        item?.date ? item?.project?.name : ('billing.notAvailable' | translate)
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="more">
    <mat-header-cell
      *matHeaderCellDef
      mat-header-cell
      [matMenuTriggerFor]="menu"
      (click)="addMoreColumnsMenu()"
    >
      <div
        class="add-more-columns-btn"
        [ngClass]="{ 'add-more-columns-btn-active': addMoreColumns }"
      >
        <img src="assets/icons/gl_plus-gray.svg" />
        {{ 'general.more' | translate }}
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="taxes" *ngIf="optionalsColumns.taxes">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.taxes' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      {{ item.taxes | currencyFormat: 'currencyPosition' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="sent" *ngIf="optionalsColumns.sent">
    <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
      {{ 'table.colum.sent' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item" mat-cell>
      <ng-container *ngIf="item.sent">
        <img src="assets/icons/document/sent_ok.svg" class="mr-2" />
        {{ 'table.content.sent' | translate }}
      </ng-container>
      <ng-container *ngIf="!item.sent">
        <img src="assets/icons/document/sent_nok.svg" class="mr-2" />
        {{ 'table.content.not_sent' | translate }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="columns; sticky: true"
    class="alt-header"
  ></mat-header-row>

  <mat-row
    *matRowDef="let row; columns: columns"
    (click)="editClick($event, row)"
    [ngClass]="selectedItem === row._id ? 'selectedRow' : ''"
  >
  </mat-row>
</mat-table>

<mat-menu
  class="add-columns-menu"
  #menu="matMenu"
  (closed)="addMoreColumnsMenuClosed()"
>
  <mat-list role="list">
    <mat-list-item>
      <div class="add-more-fields-title">
        {{ 'tasks.more_fields' | translate }}
      </div>
    </mat-list-item>

    <mat-list-item *ngFor="let column of additionalColumns">
      <mat-checkbox
        [checked]="optionalsColumns[column]"
        (click)="showColumns(column)"
      >
        {{ translateOptionalColumn(column) }}
      </mat-checkbox>
    </mat-list-item>
  </mat-list>
</mat-menu>

<!-- <mat-menu #addMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="editClick($event)">
    <mat-icon class="material-icons"> edit </mat-icon>
    Editar
  </button>

  <button mat-menu-item (click)="deleteClick($event)">
    <mat-icon class="material-icons"> delete </mat-icon>
    Eliminar
  </button>

  <button mat-menu-item (click)="watchClick($event)">
    <mat-icon class="material-icons"> visibility </mat-icon>
    Previsualizar
  </button>

  <button mat-menu-item [matMenuTriggerFor]="convertSelector">
    <mat-icon class="material-icons"> description </mat-icon>
    Convertir
  </button>

  <button mat-menu-item>
    <mat-icon class="material-icons"> send </mat-icon>
    Enviar
  </button>
</mat-menu> -->

<!-- <mat-menu #convertSelector="matMenu" xPosition="before">
  <button mat-menu-item (click)="convertToPreform()">
    Preformas
  </button>
  <button mat-menu-item (click)="convertToBill()">
    Facturas
  </button>
</mat-menu> -->
