import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Clipboard } from '@angular/cdk/clipboard';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import {
  DealSearchService,
  DealService,
  SandboxService,
} from '@web-frontend/shared/services';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';

import { CreateDeal } from './create-deal.types';

import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Company, Deal, Feature, FeatureUser, IFinal } from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { LaunchModalYesOrNoService } from '@web-frontend/shared/directives/click-delete/modal-yes-or-no/launch-modal-yes-or-no.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { CustomDateAdapter } from '@web-frontend/shared/utils/custom-date-adapter';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import { WrapperDetailComponent } from './wrapper-detail/wrapper-detail.component';
import { WrapperPartOfServiceComponent } from './wrapper-part-of-service/wrapper-part-of-service.component';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-create-deal',
  templateUrl: './create-deal.component.html',
  styleUrls: ['./create-deal.component.scss'],
  providers: [{ provide: DateAdapter, useExisting: CustomDateAdapter }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDealComponent implements OnInit {
  @ViewChild(WrapperDetailComponent, { static: false })
  detailComponent: WrapperDetailComponent;

  @ViewChild(WrapperPartOfServiceComponent, { static: false })
  partOfServiceComponent: WrapperPartOfServiceComponent;

  @Output()
  wantDeleteDeal = new EventEmitter<unknown>();

  activeTab: CreateDeal.TabType = 'DETAIL';
  type: 'ADD' | 'EDIT' = 'ADD';
  deal!: Deal.Output;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;

  featureRef = Feature;
  featureRefUser = FeatureUser;
  loadingCancelDeal = false;
  loadingRestoreDeal = false;

  private refreshModalOnClose = false;

  loading = false;
  resize$ = this.sandBoxService.screenBusChannel$;
  customer: IFinal;

  private middlewareExecute = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: CreateDeal.Params,
    private dealSearchService: DealSearchService,
    private dialogRef: MatDialogRef<CreateDealComponent>,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private companyModuleService: CompanyModuleService,
    private sandBoxService: SandboxService,
    private launchModalYesOrNoService: LaunchModalYesOrNoService,
    private dialog: MatDialog,
    public dealsServices: DealService,
    public amplitudeService: AmplitudeService,
    private readonly brazeService: BrazeService,
    private readonly analyticsService: AnalyticsService
  ) {}

  async ngOnInit() {
    if (this.data?.mode === 'EDIT') {
      this.type = this.data.mode;
      await this.requestDeal();
    }

    if (this.data?.forceReloadOnClose) {
      this.refreshModalOnClose = true;
    }

    if (this.data?.customer) {
      this.customer = this.data?.customer;
    }
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onbackDropClick());
  }

  onCreateFinish($event) {
    this.dialogRef.close($event);
  }

  public async onbackDropClick(result?: string): Promise<void> {
    this.close();
  }

  async changeTab(tab: CreateDeal.TabType) {
    if (this.type === 'EDIT') {
      if (!this.middlewareExecute) {
        this.middlewareExecute = true;
        const changeTab = await this.middlewareChangeTab();
        this.middlewareExecute = false;
        this.sendEventAmplitude(tab);
        if (changeTab) {
          this.activeTab = tab;
          this.draw();
        }
      }
    }
  }

  sendEventAmplitude(tab: CreateDeal.TabType) {
    switch (tab) {
      case 'DETAIL':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_detail,
        });
        break;
      case 'PART_OF_SERVICE':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_proof_tab,
        });
        break;
      case 'SALES':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_sale_tab,
        });
        break;
      case 'EXPENSE':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_expense_tab,
        });
        break;
    }
  }

  private async middlewareChangeTab(): Promise<boolean> {
    const tabsToCheckChanges = ['DETAIL', 'PART_OF_SERVICE'];
    if (!tabsToCheckChanges.includes(this.activeTab)) {
      return true;
    }

    const component =
      this.activeTab === 'DETAIL'
        ? this.detailComponent
        : this.partOfServiceComponent;

    if (!component.haveChanges()) {
      this.draw();
      return true;
    }

    const { action } = await this.launchModalYesOrNoService
      .open({
        title: 'general.wantContinueWithoutSave',
        description: 'general.wantContinueWithoutSaveDescription',
        labelButtonAction: 'general.saveAndContinue',
        labelButtonCancel: 'general.nextWithoutSave',
      })
      .toPromise();

    if (action !== 'EXECUTE') {
      return action === 'CANCEL';
    }

    await component.editSubmit({ close: false });
    await this.requestDeal();
    return true;
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  getLang() {
    return this.i18n.currentLang;
  }

  private async requestDeal() {
    this.loading = true;
    try {
      const params = {
        'ids[]': [this.data.idDeal],
      };

      const res = await this.dealSearchService
        .search(params, { automaticallyHandleCancelled: false })
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .toPromise();
      if (res.docs.length) {
        const [deal] = res.docs;
        this.deal = deal;
        this.loading = false;
      }

      this.draw();
    } catch (error) {}
  }

  close() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_exit,
    });
    if (['DETAIL', 'PART_OF_SERVICE'].includes(this.activeTab)) {
      const component =
        this.activeTab === 'DETAIL'
          ? this.detailComponent
          : this.partOfServiceComponent;

      const hasChanges = component.haveChanges();

      if (hasChanges) {
        const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
          panelClass: 'delete-by-selection-modal',
          data: {
            title: this.i18n.instant('general.withoutSave'),
            confirmLabel: this.i18n.instant('general.buttonExit'),
            showBody: false,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'EXECUTE') {
            const refresh = this.refreshModalOnClose ? true : null;
            this.dialogRef.close(refresh);
          }
        });
      } else {
        const refresh = this.refreshModalOnClose ? true : null;
        this.dialogRef.close(refresh);
      }
    } else {
      const refresh = this.refreshModalOnClose ? true : null;
      this.dialogRef.close(refresh);
    }
  }

  shareLink() {
    let url = window.location.href.split('admin')[0];

    url += 'admin/deals?type=deal&id=' + this.data.idDeal;
    this.clipboard.copy(url);
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_share,
    });
    this.toastService.show({
      text: 'activity.copyLink',
      type: 'success',
      msDuration: 4000,
      class: '',
    });
  }

  cancelDeal<T = any>(value: T) {
    this.wantDeleteDeal.next(value);
    this.dialogRef.close(true);
  }

  async cancelDealNoModal(deal) {
    this.loadingCancelDeal = true;
    this.dealsServices
      .delete(deal._id, { action: '', reason: '' })
      .subscribe(() => {
        this.dialogRef.close(true);
        this.loadingCancelDeal = false;
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_cancel,
        });
        this.brazeService.sendEvent({
          event: BrazeEventType.deal_canceled,
          eventProperties: {
            canceledIds: [deal._id],
          },
        });
      });
  }

  restoreDealNoModal(deal) {
    const ids = [deal._id];

    this.dealsServices.restoreMany({ ids: ids }).subscribe(() => {
      this.toastService.show({
        text: this.i18n.instant('general.restoreConfirm'),
        type: 'success',
        msDuration: 4000,
        class: '',
      });

      this.loadingRestoreDeal = false;
      this.dialogRef.close(true);
      this.draw();
    });
  }

  async finishDeal() {
    if (!this.deal) return;

    const dealAction = this.deal.isFinished
      ? this.dealsServices.restoreFinished([this.deal._id])
      : this.dealsServices.finishedMany([this.deal._id]);

    dealAction.subscribe(() => {
      this.deal.isFinished = !this.deal.isFinished;
      const toastMessage = this.deal.isFinished
        ? 'deals.finishDeal'
        : 'deals.restoreDeal';
      this.toastService.show({
        text: this.i18n.instant(toastMessage),
        type: 'success',
        msDuration: 4000,
      });
      this.refreshModalOnClose = true;
      this.draw();
    });
  }
}
