import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTimeComponent } from './add-time.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';

import { RmSelectV2Module } from '../globals/rm-select-v2';
import { RmSelectRegisterModule } from '../globals/rm-select-register';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { RmSelectModule } from '../globals/rm-select';
import { PermissionsModule } from '../permissions';
import { GoogleMapsLinkModule } from '../google-maps-link/google-maps-link.module';

@NgModule({
  declarations: [AddTimeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    NgxMaskModule,
    RmSelectV2Module,
    RmSelectRegisterModule,
    RmSelectModule,
    NgSelectModule,
    MatSelectModule,
    PermissionsModule,
    GoogleMapsLinkModule,
  ],
  exports: [AddTimeComponent],
})
export class AddTimeModule {}
