export default {
  alpha: [
    'deals',
    'booking',
    'online-booking',
    'reports',
    'auth',
    'rates',
    'clock-hours',
    'activities',
    'clock-absence',
  ],
  beta: [
    'budgets',
    'sales',
    'finals',
    'payments',
    'documents',
    'auth/onboarding',
    'expenses',
  ],
};
