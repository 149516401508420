import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfTimeTrackingComponent } from './mf-time-tracking.component';
import { MfTimeTrackingRoutingModule } from './mf-time-tracking-routing.module';
import { AppName, AppUrl } from '../microfrontend.tokens';

const microfrontendKey = 'mf-time-tracking';

@NgModule({
  declarations: [MfTimeTrackingComponent],
  imports: [CommonModule, MfTimeTrackingRoutingModule],
  exports: [MfTimeTrackingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppName,
      useValue: microfrontendKey,
    },
    {
      provide: AppUrl,
      useFactory: () => {
        return localStorage.getItem(microfrontendKey);
      },
    },
  ],
})
export class MfTimeTrackingModule {}
