import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BudgetCommonData,
  Company,
  Feature,
  FeatureUser,
  IAdditionalInfoBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  IEquipmentNote,
  IMessageBudget,
  IPaymentInfoBudget,
  IProject,
  PaginateSort,
  PaymentInfoBudget,
  TypeAmplitudeBudget,
  TypeBudget,
} from '@tacliatech/types';
import { Product } from '@web-frontend/shared/class';
import { translatePaymentsName } from '@web-frontend/shared/helpers/translations/translate-util';
import {
  DealService,
  ProjectService,
  SandboxService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { ProductService } from '@web-frontend/shared/services/products';
import { RemoveEmpty } from '@web-frontend/shared/utils';

import { Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { CreateProjectService } from '../../create-project';
import { SelectSearch } from '../../select-search';
import { BudgetUtilisService } from '../budget-util.service';
import { RmSelect } from '../../../../shared/components/globals/rm-select/rm-select.types';
import { DomSanitizer } from '@angular/platform-browser';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ModalMobileInfoComponent } from '../../modal-mobile-info';
import { MatDialog } from '@angular/material/dialog';
import { SelectItem } from '../../create-deal/wrapper-part-of-service/select-item/select-item.types';
import InvoiceAdapter from '../../../../invoice/adapter/invoice-adapter';
import { INVOICE_TYPE_LIST } from '../../../../invoice/types/invoice-type.type';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-budget-footer',
  templateUrl: './budget-footer.component.html',
  styleUrls: ['./budget-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetFooterComponent implements OnInit, OnDestroy, OnChanges {
  /*   INPUTS
 ..............................................*/
  @Input()
  formFooter: FormGroup;
  footerForm: AbstractControl;

  @Input()
  id: string;

  @Input()
  messages: IMessageBudget;

  @Input()
  settings: IBudgetsSetting;

  @Input()
  typePayments: IPaymentInfoBudget[];

  @Input()
  addicionalInfo: IAdditionalInfoBudget;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  budget: BudgetCommonData = new BudgetCommonData();

  @Output()
  changedFooter = new EventEmitter<unknown>();

  @Input()
  type: string;

  @Input()
  validForm: boolean;

  @Input()
  idDeal: string;

  @Input()
  haveChanges: boolean;

  @Output()
  isValid = new EventEmitter<boolean>();

  @Output()
  paymentsMethods = new EventEmitter<PaymentInfoBudget[]>();

  @Output()
  goToPrompt = new EventEmitter<{
    url: string;
    type: string;
    changes: boolean;
  }>();

  @Input()
  budgetTypeAmplitude: TypeAmplitudeBudget;

  /*   PROPERTIES
 ..............................................*/

  private sub$ = new Subscription();
  canEditBasic = true;
  paymentsMethodsList: RmSelect.Items;
  paymentAssigned: PaymentInfoBudget = new PaymentInfoBudget();
  projectAssigned: IProject;
  categoryAssigned: Product = new Product();
  categories: RmSelect.Items = [];
  deals: RmSelect.Items = [];
  usages: RmSelect.Items = [];
  invoiceTypes: RmSelect.Items = [];
  projects: RmSelect.Items = [];
  isLoading = true;
  selectType = SelectSearch.DatabaseAdd;
  selectedPayment: RmSelect.Items;
  selectedCategory: RmSelect.Items;
  selectedProject: RmSelect.Items;
  selectedDeal: RmSelect.Items;
  selectedUsage: RmSelect.Items;
  selectedType: RmSelect.Items;
  isLoadingpaymentsMethods = true;
  isLoadingCategories = true;
  isLoadingDeals = true;
  isLoadingUsage = true;
  isLoadingProjects = true;
  showToolTip = false;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;
  resize$ = this.sandBoxService.screenBusChannel$;

  private searchParams = {
    page: 1,
    sort: PaginateSort.ASC,
    applyPaginate: false,
    keyword: null,
    'sortOptions[created_at]': 'desc',
    takeDeleted: null,
    'ids[]': [],
    'clients[]': [],
    'owners[]': [],
    'users[]': [],
  };

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private i18n: TranslateService,
    private createProjectService: CreateProjectService,
    public productService: ProductService,
    private budgetUtilisService: BudgetUtilisService,
    private companyModuleService: CompanyModuleService,
    private dealService: DealService,
    public sanitizer: DomSanitizer,
    private amplitudeService: AmplitudeService,
    public dialog: MatDialog,
    private sandBoxService: SandboxService,
    private readonly userService: UserService,
    private invoiceAdapter: InvoiceAdapter,
    private readonly translateService: TranslateService
  ) {}

  showPrices: SelectItem.Items;
  showSignatures: SelectItem.Items;

  get validForBillLegacy(): boolean {
    return (
      this.type !== TypeBudget.BILL ||
      (this.type === TypeBudget.BILL && !this.userService.validateCountryMX())
    );
  }

  get notes(): IEquipmentNote[] {
    return this.formFooter.get('budgetFooter').get('notes').value ?? [];
  }

  get showComments(): boolean {
    return [TypeBudget.BILL, TypeBudget.BUDGET].includes(
      this.type as TypeBudget
    );
  }

  setNotes(values: IEquipmentNote[]): void {
    const notes = values.map((note) => {
      return {
        text: note.text,
        lastName: note.name,
        createdAt: note.createdAt,
        idAuthor: note.idAuthor,
      };
    });
    this.formFooter.get('budgetFooter').get('notes').setValue(notes);
  }

  showDivComments = true;
  toggleDivComments(): void {
    this.showDivComments = !this.showDivComments;
  }

  ngOnInit(): void {
    this.footerForm = this.formFooter.controls.budgetFooter;
    this.showPrices = [
      {
        title: 'budgets.settings.create.form.showPrices',
        description: undefined,
        active: this.formFooter.get('budgetFooter').get('showPrices').value,
        refField: 'showPrices',
      },
    ];
    if (!this.showPrices[0].active) {
      this.showPrices[0].title = 'budgets.settings.create.form.showPricesDis';
    }

    this.showSignatures = [
      {
        title: 'budgets.settings.create.form.showSignatures',
        description: undefined,
        active: this.formFooter.get('budgetFooter').get('showSignatures').value,
        refField: 'showSignatures',
      },
    ];
    if (!this.showSignatures[0].active) {
      this.showSignatures[0].title =
        'budgets.settings.create.form.showSignaturesDis';
    }
    this.fillLists();
    this.watchRefreshList();
    this.sub$.add(
      this.formFooter.controls['budgetFooter'].valueChanges.subscribe(() => {
        if (!this.selectedType) this.fillInvoiceType();
        if (!this.selectedUsage) this.fillUsage();
        this.draw();
      })
    );
    if (this.showInvoiceTypeUsage) {
      this.resolveInvoiceType();
      this.resolveUsage();
    }
    //this.createPaymentMethod();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  ngOnChanges(): void {
    let showPrices;
    let showSignatures;
    if (this.mode === TypeOfCRUD.UPDATE && this.type === TypeBudget.WAL) {
      showPrices = this.showPrices[0];
      showPrices.active = this.footerForm.value.showPrices;
      showSignatures = this.showSignatures[0];
      showSignatures.active = this.footerForm.value.showSignatures;

      this.showPrices = [showPrices];
      this.showSignatures = [showSignatures];
    }
  }

  get showInvoiceTypeUsage(): boolean {
    return (
      this.type === TypeBudget.BILL && this.userService.validateCountryMX()
    );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  get formFooterControl(): FormGroup {
    return this.formFooter.controls['budgetFooter'] as FormGroup;
  }

  fieldIsInvalid(fieldName: string): boolean {
    if (this.formFooterControl) {
      return (
        this.formFooterControl.controls[fieldName]?.invalid &&
        this.formFooterControl.controls[fieldName]?.touched
      );
    }
    return false;
  }

  resolvePaymentsMethods(): void {
    if (this.showInvoiceTypeUsage) {
      this.invoiceAdapter
        .getPaymentOptions()
        .then((data) => {
          this.paymentsMethodsList = data.map((res) => {
            return {
              title: res.description,
              id: res.id,
              value: res.id,
            };
          });
          const { payment_method } = this.footerForm.value;

          const payment = this.paymentsMethodsList.find(
            (u) =>
              u.id === (payment_method?.[0]?.id || payment_method?.[0]?._id)
          );
          if (payment) this.selectedPayment = [payment];
        })
        .catch(() => {
          this.paymentsMethodsList = [];
        })
        .finally(() => {
          this.isLoadingpaymentsMethods = false;
        });
    } else {
      this.budgetService
        .findAllPaymentsMethods(StorageService.CompanyId)
        .pipe(
          finalize(() => {
            if (this.mode === TypeOfCRUD.UPDATE) {
              const payment_method = this.footerForm.value.payment_method;
              const paymentId =
                Array.isArray(payment_method) && payment_method.length > 0
                  ? payment_method[0]?._id || payment_method[0]?.id
                  : null;
              const payment = this.paymentsMethodsList.filter(
                (d) => d.id === paymentId
              );
              this.selectedPayment = payment;
            }
          })
        )
        .subscribe((data: PaymentInfoBudget[]) => {
          this.paymentsMethodsList = data.map((res) => {
            return {
              title: res.name,
              id: res._id,
              value: res._id,
            };
          });
          this.isLoadingpaymentsMethods = false;

          this.paymentsMethods.emit(data);
        });
    }
  }

  translateName(item: string) {
    if (item) {
      const result = translatePaymentsName(item);
      return result;
    }
    return '';
  }

  updateItem(item: string, evt: RmSelect.Items) {
    if (evt.length) {
      // const [first] = ids;
      this.formFooter.patchValue({
        budgetFooter: {
          [item]: item === 'payment_method' ? evt : evt[0].id,
        },
      });
    } else {
      // this.selectedDeal = [];
      this.formFooter.patchValue({
        budgetFooter: {
          [item]: '',
        },
      });
    }
  }

  changePayment(evt: RmSelect.Items) {
    this.selectedPayment = evt;
    this.updateItem('payment_method', evt);

    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editPaymentMethod`,
    });
  }

  updatePaymentModel(p: IPaymentInfoBudget[]) {
    this.budget.typePayments = p;
    this.changedFooter.emit(this.budget);
  }

  changeProject(evt: RmSelect.Items) {
    this.updateItem('project_id', evt);
  }

  changeCategory(evt: RmSelect.Items) {
    this.updateItem('category_id', evt);

    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editCategorization`,
    });
  }

  updateCategoryModel(): void {
    this.changedFooter.emit(this.budget);
  }

  changeDeals(evt: RmSelect.Items): void {
    this.updateItem('deal_id', evt);

    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editDeal`,
    });
  }

  changeInvoiceType(evt: RmSelect.Items): void {
    this.selectedType = evt;
    this.updateItem('invoiceType', evt);
  }

  changeUsage(evt: RmSelect.Items): void {
    this.selectedUsage = evt;
    this.updateItem('purposeUse', evt);
  }

  private requestDeals() {
    this.sub$.add(
      this.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
        .subscribe(() => {
          this.isLoadingDeals = true;
          this.draw();

          this.sub$.add(
            this.dealService
              .findAllDealsByCompany_SELECT()
              .pipe(
                finalize(() => {
                  this.isLoadingDeals = false;

                  if (this.footerForm.value.deal_id) {
                    const deal_id = this.footerForm.value.deal_id;
                    const deal = this.deals.filter((d) => d.id === deal_id);
                    if (deal.length) {
                      this.selectedDeal = deal;
                    } else {
                      this.selectedDeal = [];
                    }
                  }
                  this.draw();
                })
              )
              .subscribe((res) => {
                this.deals = res.map((res) => {
                  return {
                    title: res.name || this.i18n.instant('general.untitle'),
                    value: res._id,
                    id: res._id,
                  };
                });
                this.draw();
              })
          );
        })
    );
  }

  getProjects() {
    this.isLoadingProjects = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          const query = RemoveEmpty(this.searchParams);

          this.sub$.add(
            this.projectService
              .findAllProjectsByCompany_SELECT()
              .pipe(
                finalize(() => {
                  this.isLoading = false;

                  if (this.mode === TypeOfCRUD.UPDATE) {
                    const project_id = this.footerForm.value.project_id;
                    const project = this.projects.filter(
                      (p) => p.id === project_id
                    );
                    if (project) {
                      this.selectedProject = project;
                    } else {
                      this.selectedProject = [];
                    }
                  }

                  this.draw();
                })
              )
              .subscribe((data) => {
                this.projects = data.map((res) => {
                  return {
                    title: res.name,
                    id: res._id,
                    value: res._id,
                  };
                });
                this.isLoadingProjects = false;
              })
          );
        })
    );
  }

  resolveMode(): void {
    if (this.budget === undefined || this.mode === TypeOfCRUD.UPDATE) {
      return;
    }
    this.budget = new BudgetCommonData();
    this.formFooter.patchValue({
      budgetFooter: {
        messages: this.settings?.budgetPreferences?.message,
      },
    });

    this.draw();
  }

  resolveInvoiceType(): void {
    this.invoiceTypes = INVOICE_TYPE_LIST.map((type) => ({
      title: this.translateService.instant(
        `budgets.settings.create.form.invoice-type-${type}`
      ),
      value: type,
      id: type,
    }));
    this.fillInvoiceType();
    this.draw();
  }

  fillInvoiceType(): void {
    const { invoiceType } = this.footerForm.value;
    if (!invoiceType) return;
    const inv = this.invoiceTypes.find((u) => u.id === invoiceType);
    if (inv) this.selectedType = [inv];
  }

  fillUsage(): void {
    const { purposeUse } = this.footerForm.value;
    if (!purposeUse) return;
    const usage = this.usages.find((u) => u.id === purposeUse);
    if (usage) this.selectedUsage = [usage];
  }

  resolveUsage(): void {
    this.isLoadingUsage = true;
    this.invoiceAdapter
      .getUsageOptions()
      .then((options) => {
        this.usages = options.map((option) => ({
          title: option.description,
          value: option.id,
          id: option.id,
        }));
        this.fillUsage();
      })
      .catch(() => {
        this.usages = [];
      })
      .finally(() => {
        this.isLoadingUsage = false;
        this.draw();
      });
  }

  updateBudget(data: any) {
    if (this.type == TypeBudget.BUDGET) {
      if (this.id) {
        this.sub$.add(
          this.budgetService.updateOne(this.id, data).subscribe((data) => {
            this.budget = data;
            this.resolveMode();
            this.budgetUtilisService.refreshBudget.emit(this.budget);
            this.draw();
          })
        );
      }
    }

    if (this.type == TypeBudget.PROFORM) {
      if (this.id) {
        this.sub$.add(
          this.budgetService
            .updateOneProform(this.id, data)
            .subscribe((data) => {
              this.budget = data;
              this.budgetUtilisService.refreshBudget.emit(this.budget);
              this.draw();
            })
        );
      }
    }

    if (this.type == TypeBudget.BILL) {
      if (this.id) {
        this.sub$.add(
          this.budgetService.updateOneBill(this.id, data).subscribe((data) => {
            this.budget = data;
            this.budgetUtilisService.refreshBudget.emit(this.budget);
            this.draw();
          })
        );
      }
    }
  }

  async showCreateProject() {
    this.sub$.add(this.createProjectService.open().subscribe());
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createProjectService.refreshList$
        .pipe(
          map((res) => !!res),
          filter((res) => res)
        )
        .subscribe(() => {
          this.searchParams = {
            ...this.searchParams,
            page: 1,
          };

          this.getProjects();
        })
    );

    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe(
        (data: IBudgetCommonData) => {
          this.budget = data;
          this.resolveMode();
        }
      )
    );
  }

  fillLists(): void {
    this.getProjects();
    this.resolvePaymentsMethods();
    this.findCategories();
    this.requestDeals();
    this.resolveMode();
  }

  findCategories() {
    this.isLoadingCategories = true;
    this.sub$.add(
      this.productService
        // .findAll()
        .findAllProductsByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.isLoadingCategories = false;
            if (this.mode === TypeOfCRUD.UPDATE) {
              const category_id = this.footerForm.value.category_id;
              const category = this.categories.filter(
                (c) => c.id === category_id
              );
              if (category) {
                this.selectedCategory = category;
              } else {
                this.selectedCategory = [];
              }
            }
            this.draw();
          })
        )
        .subscribe((res) => {
          this.categories = res.map((res) => {
            return {
              title: res.name,
              id: res._id,
              value: res._id,
            };
          });
        })
    );
  }

  goTo(event: { url: string; type: string; changes: boolean }) {
    event.changes = this.haveChanges;
    this.goToPrompt.emit(event);
  }

  showTooltipDialog(
    html: string,
    buttonText: string,
    link?: string,
    tooltipUrl?: string
  ): void {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_tooltip_start,
    });
    this.dialog.open(ModalMobileInfoComponent, {
      data: {
        html,
        buttonText,
        link,
        tooltipUrl,
        internal: true,
      },
    });
  }

  changeSelectedItems(evt) {
    if (evt.changed?.refField === 'showPrices') {
      if (this.showPrices[0].active) {
        this.amplitudeService.sendEvent({
          event: 'waybill_card_show_valueddocOn',
        });
        this.showPrices[0].title = 'budgets.settings.create.form.showPrices';
      } else {
        this.amplitudeService.sendEvent({
          event: 'waybill_card_show_valueddocOFF',
        });
        this.showPrices[0].title = 'budgets.settings.create.form.showPricesDis';
      }
    } else {
      if (this.showSignatures[0].active) {
        this.amplitudeService.sendEvent({
          event: 'waybill_card_show_SignOn',
        });
        this.showSignatures[0].title =
          'budgets.settings.create.form.showSignatures';
      } else {
        this.amplitudeService.sendEvent({
          event: 'waybill_card_show_SignOFF',
        });
        this.showSignatures[0].title =
          'budgets.settings.create.form.showSignaturesDis';
      }
    }
    this.formFooter.patchValue({
      budgetFooter: {
        [evt?.changed?.refField]: evt?.changed?.active,
      },
    });
  }
}
