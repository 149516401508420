<div class="banner-snackbar" *ngIf="shouldShowBanner">
  <roma-snackbar-mexico
    [type]="dataSnackbar.type"
    [message]="dataSnackbar.message"
    [textLink]="dataSnackbar.textLink"
    (onClickLink)="onLinkClickSnackbar($event)"
  ></roma-snackbar-mexico>
</div>
<ng-container *ngIf="!companyCanCreateBill && !isLoadingBillAllow">
  <bill-digital-certificate class="h-auto"></bill-digital-certificate>
</ng-container>

<roma-mf-accounting-service
  *ngIf="!this.deal && !this._customerId"
></roma-mf-accounting-service>

<!-- Trial Disclaimer -->
<roma-pro-disclaimer-banner
  *ngIf="isLowerThan1450px"
  (emitRefresh)="refresh()"
  [rootModule]="'sales'"
></roma-pro-disclaimer-banner>

<ng-container
  *ngIf="
    companyCanCreateBill && !isLoading && !isLoadingBillAllow && showEmptyState
  "
>
  <empty-state
    [contentType]="emptyStateContent"
    (firstButtonClick)="createBudget()"
  ></empty-state>
</ng-container>
<ng-container
  *ngIf="companyCanCreateBill && !isLoadingBillAllow && !showEmptyState"
>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <div class="row" *ngIf="showHeader">
    <!-- Stripe Snackbar -->
    <roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>
    <roma-basic-snackbar
      [message]="'budgets.error.invoice_different_waybill'"
      [type]="'error'"
      [icon]="'gl_danger-icon.svg'"
      [displaySnackbar]="displaySnackbar"
    ></roma-basic-snackbar>
    <div class="col-12 col-md-6" style="display: flex; align-items: center">
      <h1 class="page-title">
        {{ title | translate }}
        <div class="tooltip-container">
          <img
            width="32px"
            class="help-icon"
            [src]="iconDialog"
            (click)="showDialog()"
          />
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip 22"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
            (clicked)="toggleTooltip($event)"
          >
            <div [innerHTML]="getTooltip('tooltip') | translate"></div>
            <a *ngIf="academyLink" [href]="getTooltip('url')" target="_blank">{{
              getTooltip('url-text') | translate
            }}</a>
          </div>
        </div>
      </h1>
    </div>

    <div class="col-12 col-md-6 video-section">
      <button
        class="btn-download settings"
        (click)="sendAmplitudeEvent('list_account_settings')"
        clickOpenSettings
        romaProFeature
        [featureUserHide]="featureRefUser.CompanyAdjust.show"
      >
        {{ 'section_settings.title' | translate }}
        <img
          src="assets/icons/b_menu_help.svg"
          class="settings-icon"
          alt="configuration"
        />
      </button>
      <div
        class="tutorial"
        *ngIf="getLang() === 'es'"
        (click)="openVideo(type)"
      >
        <strong>{{ 'tutorial.tutorial' | translate }}</strong>
        <img class="video" src="assets/icons/gl_play-tutorial.svg" />
      </div>
      <button
        *ngIf="type"
        (clicked)="createBudget()"
        [disabled]="isLoadingPage"
        disabled
        [type]="type"
        class="roma-button add-button-3 mobile-hidden"
        style="float: right"
        romaProFeature
        [featureUser]="permissionUserCreate"
      >
        {{ 'budget.createBudget' | translate }}
        <mat-progress-spinner
          *ngIf="isLoadingPage"
          class="loading-spinner icon-option"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>
        <img
          *ngIf="!isLoadingPage"
          class="icon-option"
          src="assets/icons/gl_plus-btn.svg"
        />
      </button>
    </div>
  </div>

  <!-- FILTER -->
  <div class="row">
    <div class="col filter" *ngIf="showHeader">
      <div
        *ngIf="!(resize$ | async)?.payload?.mobile && isAllowedByCountry"
        class="container__filters"
      >
        <roma-rm-filter-core
          *ngIf="sourceSearch"
          [source]="sourceSearch"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-core>
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
          (openFilter)="sendAmplitudeEvent('openFilter')"
        ></roma-rm-filter-button>
        <rm-select-date
          #dateRange
          class="select-date"
          [amplitudeType]="budgetTypeAmplitude"
          (dateChange)="dateChange($event)"
        ></rm-select-date>
        <roma-rm-filter-clear
          *ngIf="sourceOptions"
          [showClear]="showClear"
          [sources]="[sourceSearch, sourceOptions]"
          (changes)="clearFilters($event)"
        ></roma-rm-filter-clear>
      </div>

      <div
        *ngIf="(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-button
          *ngIf="sourceOptions && isAllowedByCountry"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
          (openFilter)="sendAmplitudeEvent('openFilter')"
        ></roma-rm-filter-button>
        <button
          *ngIf="type"
          (loadingEvent)="pageLoading($event)"
          [disabled]="isLoadingPage"
          [type]="type"
          class="mobile-button-add"
          romaProFeature
          [featureUser]="permissionUserCreate"
          (clicked)="createBudget()"
        >
          <mat-progress-spinner
            *ngIf="isLoadingPage"
            class="loading-spinner icon-option"
            mode="indeterminate"
            [diameter]="20"
          ></mat-progress-spinner>

          <img
            *ngIf="!isLoadingPage"
            src="/assets/icons/gl_plus-1.svg"
            alt="{{ 'general.add' | translate }}"
          />
        </button>
      </div>
    </div>
  </div>

  <!-- Loading file (pdf/zip) -->
  <div class="col-12" style="text-align: center">
    <div *ngIf="isLoadingFile" class="loading">
      <roma-loading [size]="80"></roma-loading>
      <h3 class="animate__flash animate__delay-2s messages">
        {{ downloadText | translate }}
      </h3>
    </div>
  </div>
  <!-- Loading file (pdf/zip) -->

  <!--Start Deal Tab Sales -->
  <div
    *ngIf="!showHeader"
    romaProSelect
    class="row page-options wrapper-pro-feature-user"
    [featureUser]="featureRefUser.Quote.update"
  >
    <!--Select Row-->
    <div
      *ngIf="!showHeader && !_customerId?.length"
      class="row budget-select sales-associate"
    >
      <label
        for="selectedBudget"
        class="mb-2"
        *ngIf="!isLoading && list?.data?.length"
        >{{ 'general.selectedBudget1' | translate }}
        {{ showTypeSelect | translate }}
        {{ 'general.selectedBudget2' | translate }}</label
      >
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div
          class="form-group"
          [ngClass]="{ 'is-hidden': isLoading || !list?.data?.length }"
          style="text-align: left"
        >
          <ng-select
            #items
            appendTo="body"
            bindLabel="header.numberDoc"
            bindValue="_id"
            [items]="list?.data ? list.data : []"
            [placeholder]="'general.select' | translate"
            [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            (change)="changeSelected($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <!--Buttons Row-->
    <div
      class="col-12 col-download"
      style="margin-bottom: 20px; padding-left: 15px; padding-right: 10px"
      [ngStyle]="{ 'margin-top': _customerId?.length ? '24px' : '0px' }"
    >
      <!-- Element count inicator -->
      <div
        *ngIf="!isLoading || !list?.data?.length"
        class="table-total md"
        style="float: left"
      >
        {{ resultSearch?.total || 0 }} {{ 'general.results' | translate }}
      </div>

      <!-- Download button -->
      <a
        *ngIf="!(resize$ | async)?.payload?.mobile && type === 'BDG'"
        class="btn-download"
        romaProFeature
        [feature]="featureRef.Sale.DownloadBudgetExcel"
        [ngClass]="{ 'is-hidden': isLoading || !list?.data?.length }"
        (clicked)="downloadExcel()"
      >
        <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
        {{ 'general.downloadExcel' | translate }}
        <img class="download-icon" src="assets/icons/gl_download.svg" />
      </a>
      <a
        *ngIf="
          !(resize$ | async)?.payload?.mobile &&
          (type === 'BILL' || type === 'WAL')
        "
        class="btn-download"
        romaProFeature
        [feature]="featureRef.Sale.DownloadBillExcel"
        [ngClass]="{ 'is-hidden': isLoading || !list?.data?.length }"
        (clicked)="downloadExcel()"
      >
        <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
        {{ 'general.downloadExcel' | translate }}
        <img class="download-icon" src="assets/icons/gl_download.svg" />
      </a>

      <!-- Create button -->
      <div
        *ngIf="!isLoading && type"
        class="tutorial"
        (clicked)="createBudget()"
        (loadingEvent)="disableButtonBudget($event)"
        [idDeal]="deal"
        [idCustomer]="_customerId"
        [disabled]="isClickedBtnBudget"
        [type]="type"
        romaProFeature
        [featureUser]="permissionUserCreate"
      >
        {{ 'general.add2' | translate }}
        <mat-progress-spinner
          *ngIf="isClickedBtnBudget"
          style="margin-left: 6px"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>

        <img
          *ngIf="!isClickedBtnBudget"
          class="video"
          src="assets/icons/gl_plus.svg"
        />
      </div>
    </div>
  </div>
  <!--End Deal Tab Sales -->

  <div class="row page-options" *ngIf="showHeader && isAllowedByCountry">
    <div class="col-12 col-download" style="height: fit-content">
      <budget-total-summary
        *ngIf="!isLoading"
        [totalResults]="resultSearch?.total || 0"
        [totalAmount]="resultSearch?.totalAmount || 0"
        [showSummary]="type === 'BILL' && !isResponsive"
        [statusTotals]="summaryTotals"
      ></budget-total-summary>
      <ng-container
        *ngIf="
          !(resize$ | async)?.payload?.mobile &&
          !isLoading &&
          list?.data?.length &&
          isAllowedByCountry
        "
        [ngSwitch]="type"
      >
        <roma-shared-download-btn
          *ngSwitchCase="'BDG'"
          [featureExcel]="featureRef.Sale.DownloadBudgetExcel"
          [showDownloadExcel]="true"
          [style]="'budgets'"
          [showDownloadPdf]="true"
          [showArrowIcon]="'down'"
          [askPdfDownloadConfirmation]="true"
          [quantityToDownload]="entriesForDelete?.length || list?.data?.length"
          [showDownloadIcon]="false"
          (download)="download($event)"
        ></roma-shared-download-btn>
        <roma-shared-download-btn
          *ngSwitchCase="'BILL'"
          [featureExcel]="featureRef.Sale.DownloadBillExcel"
          [showDownloadExcel]="true"
          [style]="'budgets'"
          [showDownloadPdf]="true"
          [showArrowIcon]="'down'"
          [askPdfDownloadConfirmation]="true"
          [quantityToDownload]="entriesForDelete?.length || list?.data?.length"
          [showDownloadIcon]="false"
          (download)="download($event)"
        ></roma-shared-download-btn>
        <roma-shared-download-btn
          *ngSwitchCase="'PROFM'"
          [featureExcel]="featureRef.Sale.DownloadProformExcel"
          [showDownloadExcel]="true"
          [style]="'budgets'"
          [showDownloadPdf]="true"
          [showArrowIcon]="'down'"
          [askPdfDownloadConfirmation]="true"
          [quantityToDownload]="entriesForDelete?.length || list?.data?.length"
          [showDownloadIcon]="false"
          (download)="download($event)"
        ></roma-shared-download-btn>
        <roma-shared-download-btn
          *ngSwitchCase="'WAL'"
          [showDownloadExcel]="true"
          [featureExcel]="featureRef.Sale.WayBillDownload"
          [style]="'budgets'"
          [showDownloadPdf]="true"
          [showArrowIcon]="'down'"
          [askPdfDownloadConfirmation]="true"
          [quantityToDownload]="entriesForDelete?.length || list?.data?.length"
          [showDownloadIcon]="false"
          (download)="download($event)"
        ></roma-shared-download-btn>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="isResponsive">
    <div *ngIf="isLoading">
      <roma-loading></roma-loading>
    </div>
    <roma-budget-mobile-list
      *ngIf="!isLoading"
      [data]="list"
      [type]="type"
      [budgetTypeAmplitude]="budgetTypeAmplitude"
      (checkRowChanges)="deleteMany($event)"
      (closeServiceCard)="closeServiceCardEvent()"
      (totalSelected)="totalSelectedChanges($event)"
    >
    </roma-budget-mobile-list>
  </ng-container>

  <div
    class="col-12"
    *ngIf="
      searchParamsHasChange &&
      type !== TYPES_OF_BUDGETS.WAL &&
      !list?.data?.length
    "
  >
    <roma-not-result-found></roma-not-result-found>
  </div>

  <ng-container *ngIf="!isResponsive">
    <div
      id="containerTable"
      class="containerTable"
      [ngStyle]="{ 'margin-top': tableMarginTop }"
      (scroll)="checkScroll($event)"
    >
      <ng-container>
        <div class="my-4">
          <div *ngIf="isLoading" class="col-12">
            <roma-loading></roma-loading>
          </div>
          <div *ngIf="!isLoading" class="col-12">
            <roma-budget-list
              *ngIf="columns.length"
              (editBudget)="editBudget($event)"
              (deleteBudget)="deleteBudget($event)"
              (previewBudget)="previewBudget($event)"
              (toProformBudget)="toProformBudget($event)"
              (toBillBudget)="toBillBudget($event)"
              (openNewTab)="toNewTab($event)"
              [amplitudePrefix]="amplitudePrefix"
              [showOpenIcon]="true"
              [columns]="columns"
              [data]="list"
              [type]="type"
              [selectedItem]="selectedItem"
              [settings]="settings"
              [totalPages]="totalPages"
              (detectSortChange)="sortChange($event)"
              (checkRowChanges)="deleteMany($event)"
              (columnsDisplayedChanges)="columnsDisplayedChanges($event)"
              (totalSelected)="totalSelectedChanges($event)"
            >
            </roma-budget-list>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- table pagination -->
  <div *ngIf="list?.data?.length && !isLoading" class="pagination">
    <button
      class="arrow prev"
      [attr.disabled]="resultSearch.page === 1 ? true : null"
      (click)="previousPage()"
    >
      <img src="assets/icons/gl_arrow_left.svg" />
    </button>
    <ng-container *ngFor="let page of totalPages">
      <div
        class="page-item"
        *ngIf="page.page < 6"
        [ngClass]="{ 'current-page': page.page == resultSearch.page }"
        (click)="goToPage(page.page)"
      >
        {{ page.page }}
      </div>
      <div
        class="page-item"
        *ngIf="
          resultSearch.countPages > 5 && page.page == resultSearch.countPages
        "
        [ngClass]="{ 'current-page': page.page == resultSearch.page }"
        (click)="goToPage(resultSearch.countPages)"
      >
        ...
        {{ resultSearch.countPages }}
      </div>
    </ng-container>
    <button
      class="arrow next"
      [attr.disabled]="!resultSearch.hasNextPage ? true : null"
      (click)="nextPage()"
    >
      <img src="assets/icons/gl_arrow_right-pagination.svg" />
    </button>
  </div>

  <div
    *ngIf="
      !isLoading &&
      list?.data?.length === 0 &&
      type === TYPES_OF_BUDGETS.WAL &&
      !deal &&
      !_customerId
    "
  >
    <roma-not-result-found
      class="waybill"
      [type]="!searchParamsHasChange ? 'budgets' : null"
    ></roma-not-result-found>
    <button
      *ngIf="type"
      (clicked)="createBudget()"
      [disabled]="isLoadingPage"
      disabled
      [type]="type"
      class="roma-button add-button-3"
      romaProFeature
      [featureUser]="permissionUserCreate"
      style="margin: auto"
    >
      {{ 'general.addButton' | translate }}
      <mat-progress-spinner
        *ngIf="isLoadingPage"
        class="loading-spinner icon-option"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
      <img
        *ngIf="!isLoadingPage"
        class="icon-option"
        src="assets/icons/gl_plus-btn.svg"
      />
    </button>
  </div>
  <div
    *ngIf="deleteMessage"
    class="row justify-content-center justify-content-lg-start"
  >
    <div class="col-12 col-md-11" style="position: relative">
      <div class="delete-message" [ngClass]="{ modal__in: _customerId }">
        <strong>
          ({{ entriesForDelete.length }}) {{ 'general.selected' | translate }}
        </strong>

        <ng-container>
          <button
            *ngIf="type === types.WAL"
            class="btn"
            romaProFeature
            [featureUser]="featureRefUser.Bill.create"
            [feature]="featureRef.Sale.WayBillDownload"
            (clicked)="invoiceWaybill()"
          >
            <img
              class="prefix"
              src="assets/icons/gl_black_convert_arrows.svg"
            />
            <strong>{{ 'billing.toInvoice' | translate }}</strong>
            <img
              style="margin-right: 0"
              class="icon-star-pro-feature ml-7px"
              src="assets/icons/star.svg"
            />
          </button>

          <ng-container [ngSwitch]="type" *ngIf="isAllowedByCountry">
            <roma-shared-download-btn
              *ngSwitchCase="'BDG'"
              [featureExcel]="featureRef.Sale.DownloadBudgetExcel"
              [showDownloadExcel]="true"
              [showDownloadPdf]="entriesForDelete.length > 0"
              [style]="'budgets'"
              [showArrowIcon]="'up'"
              [askPdfDownloadConfirmation]="true"
              [quantityToDownload]="entriesForDelete?.length"
              [showDownloadIcon]="false"
              (download)="download($event)"
            ></roma-shared-download-btn>
            <roma-shared-download-btn
              *ngSwitchCase="'BILL'"
              [featureExcel]="featureRef.Sale.DownloadBillExcel"
              [showDownloadExcel]="true"
              [showDownloadPdf]="entriesForDelete.length > 0"
              [style]="'budgets'"
              [showArrowIcon]="'up'"
              [askPdfDownloadConfirmation]="true"
              [quantityToDownload]="entriesForDelete?.length"
              [showDownloadIcon]="false"
              (download)="download($event)"
            ></roma-shared-download-btn>
            <roma-shared-download-btn
              *ngSwitchCase="'PROFM'"
              [featureExcel]="featureRef.Sale.DownloadProformExcel"
              [showDownloadExcel]="true"
              [showDownloadPdf]="entriesForDelete.length > 0"
              [style]="'budgets'"
              [showArrowIcon]="'up'"
              [askPdfDownloadConfirmation]="true"
              [quantityToDownload]="entriesForDelete?.length"
              [showDownloadIcon]="false"
              (download)="download($event)"
            ></roma-shared-download-btn>
            <roma-shared-download-btn
              *ngSwitchCase="'WAL'"
              [showDownloadExcel]="true"
              [featureExcel]="featureRef.Sale.WayBillDownload"
              [showDownloadPdf]="true"
              [style]="'budgets'"
              [showArrowIcon]="'up'"
              [askPdfDownloadConfirmation]="true"
              [quantityToDownload]="entriesForDelete?.length"
              [showDownloadIcon]="false"
              (download)="download($event)"
            ></roma-shared-download-btn>
          </ng-container>

          <button
            class="btn btn-delete"
            (clicked)="openDeleteManyDialog()"
            romaProFeature
            [featureUser]="permissionUserDelete"
          >
            <img src="assets/icons/gl_trash_red.svg" />
            <ng-container *ngIf="!isResponsive">
              <strong class="delete">{{
                'general.deleteAlt' | translate
              }}</strong>
            </ng-container>
          </button>
          <div class="btn-close" (click)="unselectItems()"></div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<div class="loadingAll" *ngIf="isLoadingBillAllow">
  <mat-progress-spinner
    class="loading-spinner icon-option"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
