import { Injectable } from '@angular/core';
import { USER_DATA } from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import { getCountryCodeByCompanyFromLocalStorage } from '@web-frontend/shared/utils/country-validator';
import * as configcat from 'configcat-js';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private configCatClient: configcat.IConfigCatClient;

  constructor() {
    this.initConfigCatClient();
  }

  private initConfigCatClient(): void {
    try {
      const logger = configcat.createConsoleLogger(configcat.LogLevel.Info);
      const setupConfigCat: configcat.IJSAutoPollOptions = {
        logger: logger,
        pollIntervalSeconds: 1800, // 30 mins
      };
      if (environment.production) {
        delete setupConfigCat.logger;
      }
      this.configCatClient = configcat.getClient(
        environment.configCatKey,
        configcat.PollingMode.LazyLoad,
        setupConfigCat
      );
    } catch (error) {
      console.error('Error initializing ConfigCat client', error);
    }
  }

  public getValue(
    key: string,
    defaultValue: boolean | string | number,
    customAttributes?: {
      [key: string]: configcat.UserAttributeValue;
    }
  ): Promise<boolean | string | number> {
    if (!this.configCatClient) return Promise.resolve(defaultValue);
    return this.configCatClient.getValueAsync(
      key,
      defaultValue,
      this.getUserObject(customAttributes)
    );
  }

  private getUserObject(customAttributes?: {
    [key: string]: configcat.UserAttributeValue;
  }) {
    const userDataString = localStorage.getItem(USER_DATA);
    const userData = JSON.parse(userDataString);
    if (!userData || !userData.id || !userData.email) return null;

    const { id, email } = userData;
    const country = getCountryCodeByCompanyFromLocalStorage();
    const user = new configcat.User(id, email, country, customAttributes);

    return user;
  }
}
