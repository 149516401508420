import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@web-frontend/environments';
import { StorageService } from '@web-frontend/shared/services';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalEmptyObjectComponent } from '@web-frontend/shared/components/modal-empty-object';
import { ClickLogoutService } from '@web-frontend/shared/directives/click-logout';
import { Observable, throwError, Subject, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { authRoutes } from '../../../core/auth/auth-routing.module';

import * as Sentry from '@sentry/angular-ivy';

interface RateLimitInfo {
  timestamp: number;
  count: number;
}

interface CacheEntry {
  response: HttpResponse<any>;
  timestamp: number;
}

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  private requestMap = new Map<string, RateLimitInfo>();
  private readonly RATE_LIMIT_WINDOW = 1000; // 1 second
  private readonly MAX_REQUESTS = 5; // Maximum requests allowed in the window

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private logOutService: ClickLogoutService
  ) {}

  private getRequestKey(req: HttpRequest<any>): string {
    const url = req.url;
    const userId = StorageService.GetItem('USER_ID') || 'anonymous';
    const clientIp = req.headers.get('X-Forwarded-For') || 'unknown-ip';
    return `${userId}-${clientIp}-${url}-${req.method}`;
  }

  private isRateLimited(req: HttpRequest<any>): boolean {
    const key = this.getRequestKey(req);
    const now = Date.now();
    const rateLimitInfo = this.requestMap.get(key);

    if (!rateLimitInfo) {
      this.requestMap.set(key, { timestamp: now, count: 1 });
      return false;
    }

    if (now - rateLimitInfo.timestamp > this.RATE_LIMIT_WINDOW) {
      this.requestMap.set(key, { timestamp: now, count: 1 });
      return false;
    }

    if (rateLimitInfo.count >= this.MAX_REQUESTS) {
      Sentry.captureException(
        `Request to ${req.url} was blocked due to rate limiting`,
        {
          level: 'warning',
          extra: { ...req, key },
        }
      );
      return true;
    }

    rateLimitInfo.count++;
    return false;
  }

  private cleanupOldEntries(): void {
    const now = Date.now();
    for (const [key, info] of this.requestMap.entries()) {
      if (now - info.timestamp > this.RATE_LIMIT_WINDOW) {
        this.requestMap.delete(key);
      }
    }
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clean up old entries periodically
    this.cleanupOldEntries();

    // Check rate limiting
    if (this.isRateLimited(req)) {
      return throwError(
        () =>
          new HttpErrorResponse({
            error: {
              code: 'Rate limit exceeded',
              message: 'Rate limit exceeded',
              statusCode: 409,
            },
            headers: req.headers,
            status: 429,
            statusText: 'Rate limit exceeded',
            url: req.url,
          })
      );
    }

    let request = this.prepareRequest(req);

    return next.handle(request).pipe(
      catchError(this.handleError.bind(this)),
      finalize(() => {
        // Decrement the rate limit count
        const key = this.getRequestKey(request);
        const rateLimitInfo = this.requestMap.get(key);
        if (rateLimitInfo?.count > 0) {
          rateLimitInfo.count--;
        }
      })
    );
  }

  private prepareRequest(req: HttpRequest<any>): HttpRequest<any> {
    let request = req;
    let authorization = null;

    if (
      req.url.indexOf(':API_URL') > -1 ||
      req.url.indexOf(':API_BASE_URL') > -1
    ) {
      authorization =
        req.headers.get('Authorization')?.split(' ')[1] || StorageService.Token;

      request = req.clone({
        url: this.parseURL(req.url),
        setHeaders: authorization
          ? {
              Authorization: `Bearer ${authorization}`,
              language: StorageService.GetItem('USER_LANG'),
              timestamp: new Date().toISOString(),
              apiUrl:
                req.url.indexOf(':API_URL') > -1
                  ? environment.api.url
                  : environment.api.urlPlain,
            }
          : {},
      });
    }

    return request;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 429 || error.status >= 500) {
      Sentry.captureException(error.name, {
        level: 'warning',
        extra: { ...error },
      });
    }
    if (error instanceof HttpErrorResponse && error.status === 400) {
      this.checkEmptyObjects(error);
    }

    if (
      error instanceof HttpResponse &&
      error.status === 256 &&
      !localStorage.getItem('register')
    ) {
      this.logOutService.logout();
      this.router.navigateByUrl(authRoutes.login);
    }

    return throwError(() => error);
  }

  private parseURL(url: string): string {
    const apiUrl = environment.api.url;
    const apiBaseUrl = environment.api.urlPlain;

    url = url.replace(':API_URL', apiUrl);
    url = url.replace(':API_BASE_URL', apiBaseUrl);

    return url;
  }

  private checkEmptyObjects(err: HttpErrorResponse) {
    switch (err?.error?.message) {
      case 'DEAL_ID_NOT_EXIST':
        this.showModalEmptyObject('deal');
        break;

      case 'ACTIVITY_ID_NOT_EXIST':
        this.showModalEmptyObject('activity');
        break;

      case 'ASSET_ID_NOT_EXIST':
        this.showModalEmptyObject('asset');
        break;
      case 'FINAL_ID_NOT_EXIST':
        this.showModalEmptyObject('final');
        break;
      case 'VENDOR_ID_NOT_EXIST':
        this.showModalEmptyObject('vendor');
        break;

      case 'EQUIPMENT_ID_NOT_EXIST':
        this.showModalEmptyObject('equipment');
        break;

      case 'PROJECT_ID_NOT_EXIST':
        this.showModalEmptyObject('project');
        break;

      default:
        break;
    }
  }

  private showModalEmptyObject(type) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ModalEmptyObjectComponent,
      ngbModalOptions
    );

    (modalRef.componentInstance as ModalEmptyObjectComponent).type = type;
  }
}
