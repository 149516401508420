import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
} from '@angular/core';
import { MicrofrontendComponent } from '../microfrontend.component';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { CustomerService } from '../../shared/services';
import { CompanyModuleService } from '../../shared/services/company';
import {
  AnalyticsService,
  Source,
} from '../../shared/services/analytics/analytics.service';
import { authRoutes } from '../../core/auth/auth-routing.module';
import * as Sentry from '@sentry/angular-ivy';
import CampaignManager from '../../shared/services/campaign/campaignManager.service';
import { NativeAuthService } from './native-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '../../shared/services/toast/toast.service';

export enum EventType {
  loginSuccessfully = 'loginSuccessfully',
  navigateToOnboarding = 'navigateToOnboarding',
  navigateToApp = 'navigateToApp',
  navigateToRecoverPassword = 'navigateToRecoverPassword',
  sendAnalyticsEvent = 'sendAnalyticsEvent',
  nativeLogin = 'nativeLogin',
  nativeLoginResult = 'auth:nativeLoginResult',
  nativeLoginError = 'auth:nativeLoginError',
  registrationComplete = 'registrationComplete',
  navigateToLogin = 'navigateToLogin',
  finishNoLoginRegister = 'finishNoLoginRegister',
  showToast = 'showToast',
}

type EventPayload = { resource: string } | { event: string } | { path: string };

type SendEventPayload = {
  success?: boolean;
  token?: string;
  email?: string;
  error?: string;
};

type EventData = {
  type: EventType;
  payload?: EventPayload;
};

type SendEventData = {
  type: EventType;
  payload?: SendEventPayload;
};

@Component({
  selector: 'roma-mf-auth',
  template: `<mf-auth [attr.isnologin]="isNoLogin"></mf-auth>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfAuthComponent extends MicrofrontendComponent<
  EventData,
  SendEventData
> {
  @Input() appName = 'mf-auth';
  @Input() appUrl: string = localStorage.getItem('mf-auth');
  @Input() isNoLogin = false;

  constructor(
    private _httpClient: HttpClient,
    private _el: ElementRef,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly companyModuleService: CompanyModuleService,
    private readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService,
    private readonly campaignManager: CampaignManager,
    private readonly toastService: ToastService,
    private injector: Injector
  ) {
    super(_httpClient, _el);
    this.exposeNativeAuth();
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
    this.handleNavigateToLogin = this.handleNavigateToLogin.bind(this);
    this.handleFinishNoLoginRegister = this.handleFinishNoLoginRegister.bind(
      this
    );
  }

  onInit(): void {
    this.campaignManager.fetchCampaign();
    this.analyticsService.initAnalytics({
      sources: ['amplitude'],
    });
  }

  private async initLegacyFlow(): Promise<void> {
    await this.authService.loginSuccessfullyFlow();
    this.companyModuleService.init();
    this.customerService.loginSuccessfullyFlow();
  }

  private handleAnalyticsEvent({
    event,
    sources,
    properties = {},
  }: {
    event: string;
    sources: Source[];
    properties?: { [key: string]: string };
  }): void {
    this.analyticsService.trackEvent({
      sources: sources,
      eventName: event,
      eventProperties: properties,
    });
  }

  private handleEventRegisterSave(): void {
    this.analyticsService.initAnalytics({ identify: true });
    const dependencyTimeout = 0;
    setTimeout(() => {
      this.dispatchRegistrationFormSubmit();
    }, dependencyTimeout);
  }

  private dispatchRegistrationFormSubmit(): void {
    try {
      window.dataLayer.push({
        event: 'GTM_form_submit',
        registrationStatus: 'success',
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  private handleNavigateToLogin(): void {
    this.authService.logout();
    this.router.navigate([authRoutes.login]);
  }

  handleCustomEvent(event: CustomEvent): void {
    const eventMappedToCalendarEvent = {
      [EventType.navigateToOnboarding]: async () => {
        await this.initLegacyFlow();
        const params = event.detail.payload?.params || {};
        this.router.navigate(
          [
            Object.keys(params).some((key) => key.startsWith('utm'))
              ? authRoutes.onboarding
              : authRoutes.clientOnboarding,
          ],
          {
            queryParams: params,
          }
        );
      },
      [EventType.navigateToApp]: async () => {
        await this.initLegacyFlow();
        this.companyModuleService.goFirstPermissionActive(event.detail.payload);
      },
      [EventType.navigateToRecoverPassword]: () =>
        this.router.navigate([authRoutes.recover]),
      [EventType.sendAnalyticsEvent]: () =>
        this.handleAnalyticsEvent(event.detail.payload),
      [EventType.registrationComplete]: () => this.handleEventRegisterSave(),
      [EventType.navigateToLogin]: this.handleNavigateToLogin,
      [EventType.finishNoLoginRegister]: this.handleFinishNoLoginRegister,
      [EventType.showToast]: () => this.handleShowToast(event.detail.payload),
    };
    eventMappedToCalendarEvent[event.detail.type]?.();
  }

  onDestroy(): void {}

  private exposeNativeAuth(): void {
    const authService = this.injector.get(NativeAuthService);
    // Attach service methods to window for simplicity
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).NativeAuthService = {
      loginWithGoogle: authService.loginWithGoogle.bind(authService),
      loginWithApple: authService.loginWithApple.bind(authService),
    };
  }

  private async handleFinishNoLoginRegister(): Promise<void> {
    const openDialogs = this.dialog.openDialogs;
    await this.authService.refreshCacheUserRequest().toPromise();
    this.handleEventRegisterSave();

    openDialogs?.forEach((dialogRef) => {
      if (dialogRef.id.startsWith('no-login-register-modal')) {
        dialogRef.close(true);
      }
    });
  }

  handleShowToast({
    type,
    text,
  }: {
    type: 'info' | 'success' | 'error';
    text: string;
  }): void {
    this.toastService.show({
      text,
      type,
      msDuration: 4000,
    });
  }
}
