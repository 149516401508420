import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { FileSelectService } from '@web-frontend/shared/helpers/file-select';
import { UtilImage } from './utils/utils';
import {
  Company,
  EFileType,
  Feature,
  FreeLimitType,
  SharedBusinessErrors,
} from '@tacliatech/types';
import { FileUploadService } from '@web-frontend/shared/helpers/file-upload';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { finalize } from 'rxjs/operators';
import { environment } from '@web-frontend/environments';
import { ProFeatureService } from '../permissions/pro-feature.service';
import { FreeLimitService } from '@web-frontend/shared/services/free-limit';

export const MEGABYTES = 0.000001;
class ImageSnippet {
  pending = false;
  status = 'init';

  constructor(public src: string, public file: File, public p?: boolean) {
    this.pending = p;
  }
}

@Component({
  selector: 'roma-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageSelectorComponent implements OnInit, AfterViewInit {
  private fileAdmitData = [
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ];
  accept: string;
  filesReceived;
  fileArrayLenght = [];
  util: UtilImage = new UtilImage();
  selectedFile: Map<number, ImageSnippet> = new Map();
  sourcesfilesServer: Map<number, string> = new Map();

  @Input()
  title: string;

  @Input('delete-images-service')
  deleteImages$: Observable<boolean>;

  @Input('background-color')
  backgroundColor = '#3c48ec';

  @Input()
  color = '#fff';

  @Input('accept-files')
  acceptFiles = false;

  @Input('quantity')
  quantityImages = 3;

  @Input()
  feature = Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUser = Feature.SystemPermission.DefaultAllow;

  @Input()
  fileSize = 10000000;

  @Input()
  multiple = true;

  @Input()
  img = 'assets/images/gl_plus-circle.svg';

  @Input()
  filesServer: Array<string>;

  @Input()
  type: string;

  @Input()
  mode: string;

  @Input()
  edit = false;

  @Input()
  maxSize = 10;

  @Input()
  module: Company.IdModule = Company.IdModule.Unknown;
  @Output('images-changed')
  imagesChanged = new EventEmitter<Array<string>>();

  @Output()
  OnErrorEvent = new EventEmitter<any>();

  error = SharedBusinessErrors;

  @Input('file-admit')
  set fileAdmit(value: Array<string>) {
    this.fileAdmitData = value;
    this.getAccept();
  }

  get fileAdmit() {
    return this.fileAdmitData;
  }

  permissions$ = this.permissionService.permissions$;
  featureRef = Feature;
  screenWidth = window.screen.width;
  isLoading = false;
  sub$ = new Subscription();
  freeLimit: FreeLimitType.Response;
  filesLimit = environment.freeLimits.files;
  numFilesAdded = 0;

  constructor(
    private fileUploadService: FileUploadService,
    private changeDetectionRef: ChangeDetectorRef,
    private fileSelect: FileSelectService,
    private permissionService: PermissionService,
    private freeLimitService: FreeLimitService,
    private proFeatureService: ProFeatureService
  ) {
    this.fileArrayLenght = this.util.createArray(this.quantityImages);
  }

  get customContentWeb() {
    return null;
  }

  get customContentMobile() {
    return null;
  }

  hasPermissionFeature$ = this.permissionService.hasFeatureFn(
    Feature.SystemPermission.AllowAll
  );

  ngOnInit(): void {
    this.initValues();
    this.watchDeleteTempImages();
    this.watchFreeLimit();
    this.draw();
  }

  ngAfterViewInit(): void {
    this.loadImages();
  }

  ngOnDestroy(): void {
    // TODO -> Improve method to unsubscribe and remove image uploaded
    // this.sub$.unsubscribe();
  }
  watchDeleteTempImages() {
    if (this.deleteImages$) {
      this.deleteImages$.subscribe(() => {
        if (this.mode === 'ADD') this.deleteAllTempImages();
      });
    }
  }

  haveNumFilesAdded(i: number) {
    return (
      i == this.numFilesAdded && i <= this.filesLimit - this.freeLimit?.consumed
    );
  }

  dontHaveNumFilesAdded(i: number) {
    return i < this.filesLimit - this.freeLimit?.consumed;
  }

  watchFreeLimit() {
    this.isLoading = true;
    this.draw();
    this.sub$.add(
      this.freeLimitService
        .getLimit(FreeLimitType.AllowedTypes.File)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.freeLimit = res;
          this.draw();
        })
    );
  }
  initValues() {
    this.fileArrayLenght = this.util.createArray(this.quantityImages);

    if (this.acceptFiles) {
      this.fileAdmit.push('pdf');
      this.fileAdmit.push('xls');
      this.fileAdmit.push('xlsx');
    }
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  loadImages() {
    if (this.type == 'ADD') {
      this.sourcesfilesServer = new Map();
    } else {
      this.fillArraySource();
    }
    this.draw();
  }

  // convert array images from Object to array to manage server images
  fillArraySource() {
    if (this.filesServer) {
      if (this.filesServer.length > 0) {
        for (let index = 0; index < this.filesServer.length; index++) {
          this.sourcesfilesServer.set(index + 1, this.filesServer[index]);
        }
      }
    }
    this.draw();
  }

  private getAccept() {
    const fileAdmit = this.fileAdmit.map((elem) => `.${elem}`);
    this.accept = fileAdmit.join(',');
    this.draw();
  }

  async openFileSelector(positionImage: number) {
    const response = await this.fileSelect.openSelector({
      extensions: this.fileAdmitData,
    });
    const files = response.getAll('file[]') as File[];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.addEventListener('load', (event: any) => {
      //Valid File size ( throw error if exceed 20MB)
      const file = files[0];
      if (this.checkFileSize(file)) {
        //fill Image Map()
        const image = new ImageSnippet(event.target.result, files[0], true);
        this.selectedFile.set(positionImage, image);

        // Upload to server if flag is true
        this.uploadFile(positionImage, files);
      }
      this.draw();
    });
  }

  checkFileSize(file) {
    const valid = file.size * MEGABYTES <= this.maxSize;
    if (!valid) {
      this.OnErrorEvent.emit(this.error.FileMaxSizeError.apiErrorCode_10);
      //throw new Error(this.error.FileMaxSizeError.apiErrorCode);
      return false;
    }
    return true;
  }

  openImages({
    type,
    img,
    position,
  }: {
    type: string;
    img: string;
    position: number;
  }) {
    const split = img.split('.');
    const ext = split[split.length - 1];

    const filterPipe = new ImageFirebasePipe();
    const imagePath = filterPipe.transform(img, type);
    window.open(imagePath, '_blank');
  }

  openImage(positionImage: number) {
    const image = this.sourcesfilesServer.get(positionImage);
    this.openImages({
      type: this.type,
      img: image,
      position: positionImage,
    });
    this.draw();
  }

  deleteSourceFiles(positionImage: number) {
    this.selectedFile.delete(positionImage);
    this.sourcesfilesServer.delete(positionImage);
    this.emitChangesImages();
  }

  deleteAllTempImages() {
    this.fileArrayLenght.forEach((item) => {
      this.deleteFile(item);
    });
  }

  delete(positionImage: number) {
    this.deleteFile(positionImage);
    this.deleteSourceFiles(positionImage);
    this.draw();
  }

  existImageLoad(positionImage: number) {
    return (
      this.selectedFile?.has(positionImage) ||
      this.sourcesfilesServer?.has(positionImage)
    );
  }

  private uploadFile(positionImage: number, files: any) {
    this.isLoading = true;
    this.fileUploadService
      .uploadFile(this.type as EFileType, files[0] as File)
      .subscribe(
        (res) => {
          this.updateFreeLimit(res, positionImage);
          this.isLoading = false;
        },
        (err) => {
          this.onError(positionImage);
          this.draw();
        }
      );
  }

  deleteFile(positionImage: number) {
    let image = this.sourcesfilesServer.get(positionImage);

    if (Array.isArray(image)) {
      image = image[0];
    }

    if (image !== undefined) {
      this.isLoading = true;
      this.fileUploadService
        .deleteFirebaseStorageFile(this.type as EFileType, image)
        .then((result) => {
          this.updateFreeLimit(image, positionImage, false);
          this.isLoading = false;
        })
        .catch((error) => {
          this.draw();
        });
    }
  }

  updateFreeLimit(fileId: string, positionImage: number, onInsert = true) {
    this.isLoading = true;

    this.sub$.add(
      this.freeLimitService
        .updateLimit(FreeLimitType.AllowedTypes.File, {
          onInsert,
          file: {
            id: fileId,
            module: this.module,
          },
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe((freeLimit) => {
          this.freeLimit = freeLimit;
          this.draw();
          if (onInsert) {
            this.onNewSuccess(positionImage, fileId);
          }
        })
    );
  }

  onNewSuccess(positionImage: number, response: any) {
    this.selectedFile.get(positionImage).pending = false;
    // this.filesReceived = response.map((elem) => elem.filename);
    //console.log('onSuccess filesReceived response', response);
    this.sourcesfilesServer.set(positionImage, response);
    //console.log('onSuccess sourcesfilesServer', this.sourcesfilesServer);

    this.emitChangesImages();
    this.draw();
  }

  onSuccess(positionImage: number, response: any) {
    this.selectedFile.get(positionImage).pending = false;
    this.filesReceived = response.map((elem) => elem.filename);
    //console.log('onSuccess filesReceived', this.filesReceived);
    this.sourcesfilesServer.set(positionImage, this.filesReceived[0]);
    //console.log('onSuccess sourcesfilesServer', this.sourcesfilesServer);

    this.emitChangesImages();
    this.draw();
  }

  onError(positionImage: number) {
    this.selectedFile.get(positionImage).pending = false;
    this.emitChangesImages();
    this.draw();
  }

  emitChangesImages() {
    const imagesUploaded = this.util.convertToArray(this.sourcesfilesServer);
    //console.log('emitChangesImages imagesUploaded', imagesUploaded);
    this.imagesChanged.emit(imagesUploaded);
    this.draw();
  }

  resolveFile(items: string[]) {
    const item = Array.isArray(items) ? items[0] : items;

    const fileTypes: Record<string, string> = {
      pdf: 'assets/images/file.png',
      ods: 'assets/images/file_csv.svg',
      csv: 'assets/images/file_csv.svg',
      xls: 'assets/images/excel.png',
      xlsx: 'assets/images/excel.png',
      xsl: 'assets/images/excel.png',
      txt: 'assets/images/document.svg',
      odt: 'assets/images/document.svg',
      rtf: 'assets/images/document.svg',
      docx: 'assets/images/file_docx.svg',
      doc: 'assets/images/file_doc.svg',
      mp3: 'assets/images/audio.png',
      wav: 'assets/images/audio.png',
      aiff: 'assets/images/audio.png',
      ogg: 'assets/images/audio.png',
      flac: 'assets/images/audio.png',
      mp4: 'assets/images/videoicon.png',
      mov: 'assets/images/videoicon.png',
      avi: 'assets/images/videoicon.png',
      wmv: 'assets/images/videoicon.png',
    };

    const fileName = item.split('?')[0];
    const extension = fileName.split('.').pop();
    return fileTypes[extension.toLowerCase()] || 'assets/images/archive.svg';
  }

  isFile(items: string[]) {
    let item: string;

    if (Array.isArray(items)) {
      item = items[0];
    } else {
      item = items;
    }

    if (
      item.toLowerCase().includes('jpg') ||
      item.toLowerCase().includes('jpeg') ||
      item.toLowerCase().includes('png')
    ) {
      return false;
    }

    return true;
  }

  forceRefresh() {
    this.watchFreeLimit();
  }

  isImageInFreeLimit(position) {
    return this.freeLimit.files?.some(
      (file) => file.id === this.sourcesfilesServer.get(position)
    );
  }

  determineIfOpen(position) {
    if (this.isLoading) {
      return false;
    }

    if (this.isImageInFreeLimit(position)) {
      return Feature.SystemPermission.DefaultAllow;
    }

    return this.feature;
  }
}
