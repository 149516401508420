<div romaProSelect [featureUser]="featureUser" class="wrapper-pro-feature-user">
  <roma-modal-template
    [title]="'clockHour.create.form.title' | translate"
    [showCancelBtn]="false"
    [isLoading]="loading"
    [askBeforeClose]="haveChanges"
    [disableSecondary]="wrongTime || hasOverlapError"
    [disableSuccess]="wrongTime || hasOverlapError"
    (success)="submit()"
    (cancel)="close()"
  >
    <form
      [formGroup]="form"
      romaProSelect
      class="wrapper-pro-feature-user"
      [featureUser]="featureUser"
    >
      <div class="row">
        <div class="col-12">
          <div class="date-hours-info">
            <div class="date">
              <span>{{ 'clockHour.create.form.date' | translate }}</span>
              {{
                dataParams?.date
                  ? (dataParams?.date | dateDayMonthConvert | titlecase)
                  : (null | dateDayMonthConvert | titlecase)
              }}
            </div>
            <div class="hours">
              <span>TOTAL</span>
              {{ [form.get('histories').value] | clockHoursDuration }}
            </div>
          </div>
        </div>
      </div>

      <!-- selector by hours -->
      <roma-add-time
        style="margin-top: 30px"
        [mode]="mode"
        [data]="histories || []"
        [disabled]="blockEdition"
        (changes)="changeClockHistories($event)"
        [stopwatchMandatory]="clockInWithStopwatchMandatory"
      >
      </roma-add-time>

      <div
        *ngIf="blockEdition"
        class="roma-disclaimer warning"
        style="margin-bottom: 10px"
      >
        <div class="icon">
          <img
            src="assets/icons/gl_alert_yellow.svg"
            style="width: 24px; height: 24px"
          />
        </div>
        <div class="body">
          {{ 'clockHour.disclaimerBlock' | translate }}
        </div>
      </div>

      <div class="error-container">
        <label *ngIf="wrongTime">
          <ng-container *ngIf="!equalTime"
            >*{{
              'clockHour.create.form.errorHistories' | translate
            }}</ng-container
          >
        </label>

        <label *ngIf="hasOverlapError">
          {{ 'clockHour.create.form.overlapError' | translate }}
        </label>
      </div>

      <!-- note -->
      <p class="title-notes">
        {{ 'general.notes' | translate }}
      </p>

      <div>
        <textarea
          cols="30"
          rows="1"
          class="note-input"
          formControlName="note"
          maxlength="255"
          [placeholder]="'equipments.create.notePh' | translate"
        >
        </textarea>
      </div>
      <div
        *ngIf="dataParams?.status === statusRef.Rejected"
        class="data-flex-container"
      >
        <div class="item item-name">{{ 'general.reason' | translate }}:</div>
        <div class="item item-info">
          <ng-container *ngIf="dataParams?.reasonRejected?.description">
            {{ dataParams?.reasonRejected?.description }}
          </ng-container>

          <ng-container *ngIf="!dataParams?.reasonRejected?.description">
            -
          </ng-container>
        </div>
      </div>
    </form>
  </roma-modal-template>
</div>
