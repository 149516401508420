<div class="form-field expires" [ngClass]="{ full: isMobile }">
  <rm-select-register
    #selectRegister
    [isClearable]="false"
    [placeholderText]="selectPlaceHolder | translate"
    [activeItems]="dateSelected"
    [items]="dateList"
    icon="assets/icons/gl_calendar.svg"
    type="users"
    inputHeight="36px"
    listCustomStyle="top: 8px"
    [fullWidthList]="true"
    [isAbsolute]="true"
    [addButtonText]="'budgets.settings.create.form.date_range'"
    [addButtonIcon]="'assets/icons/gl_calendar_blue.svg'"
    [addButtonClickKeepOpen]="true"
    [isAddOpened]="isAddOpened"
    [addIconStyle]="{ width: '16px', height: '16px', 'margin-bottom': '2px' }"
    (panelOpen)="onOpen()"
    (changeItems)="changeDate($event)"
    (addElement)="addDate()"
  >
  </rm-select-register>
  <mat-form-field
    appearance="fill"
    style="position: absolute; visibility: hidden; top: -16px; left: 233px"
  >
    <mat-date-range-input [rangePicker]="picker">
      <input
        #startDateInput
        matStartDate
        [value]="startDate"
        (dateChange)="rangeDateChange('START', $event)"
      />
      <input
        #endDateInput
        matEndDate
        [value]="endDate"
        (dateChange)="rangeDateChange('END', $event)"
      />
    </mat-date-range-input>
    <mat-date-range-picker
      #picker
      (closed)="isAddOpened = false"
    ></mat-date-range-picker>
  </mat-form-field>
</div>
