import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { RmFilterService } from '../rm-filter.service';
import { RmFilter } from '../rm-filter.types';

@Component({
  selector: 'roma-rm-filter-clear',
  templateUrl: './rm-filter-clear.component.html',
  styleUrls: ['./rm-filter-clear.component.scss'],
})
export class RmFilterClearComponent implements OnInit, OnDestroy {
  @Input()
  sources: RmFilter.Filter[];

  @Input()
  showClear = false;

  @Output()
  changes = new EventEmitter<RmFilter.Changes>();

  constructor(private rmFilterService: RmFilterService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    try {
      this.clean();
    } catch (e) {
      //Error filter clean
    }
  }

  get has(): boolean {
    if (this.showClear) return true;

    for (const source of this.sources) {
      const value = source.toJson();
      if (value.length > 0) {
        return true;
      }
    }

    return false;
  }

  public clean(): void {
    this.emptyInputs();
    this.rmFilterService.emitCleanFilters(true);
    this.changes.emit({ type: 'CHANGE', queryParams: {} });
  }

  public emptyInputs(): void {
    // to empty inputs
    for (const source of this.sources) {
      source?.clean();
    }
    this.rmFilterService.emit({ type: 'CLEAN_ALL' });
  }
}
