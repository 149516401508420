import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractFormModalComponent } from './contract-form-modal.component';
import { ModalTemplateModule } from '../modal-template';
import { TranslateModule } from '@ngx-translate/core';
import { ContractFormModule } from '../contract-form/contract-form.module';
import { BaseButtonModule } from '../../../common/components/button/baseButton.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ModalTemplateModule,
    ContractFormModule,
    BaseButtonModule,
  ],
  declarations: [ContractFormModalComponent],
  exports: [ContractFormModalComponent],
})
export class ContractFormModalModule {}
