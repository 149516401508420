import moment from 'moment-timezone';
import { StorageService } from '../services';

/**
 * Busca un substring entre 2 strings (solo encuentra dentro de un string la primera coincidencia)
 * fullString = string entera
 * startStr = primera string a buscar
 * endStr = segunda string a buscar
 */
export function getSubStrBetween(
  fullString: string,
  startStr: string,
  endStr: string
) {
  if (fullString.indexOf(startStr) == -1) {
    return null;
  }
  return fullString
    .substring(
      fullString.indexOf(startStr) + startStr.length,
      fullString.length
    )
    .split(endStr)[0];
}
/**
 * Busca substring entre 2 strings y devuelve un array con todas las coincidencias
 * fullString = string entera
 * startStr = primera string a buscar
 * endStr = segunda string a buscar
 */
export function getAllSubStrBetween(
  fullString: string,
  startStr: string,
  endStr: string
) {
  const substrings: any[] = [];
  let cont = true;
  do {
    const aux = getSubStrBetween(fullString, startStr, endStr);
    fullString = fullString.substring(fullString.indexOf(endStr) + 1);
    if (aux) {
      substrings.push(aux);
    } else {
      cont = false;
    }
  } while (cont == true);
  return substrings;
}

/**
 * String in format YYYY-MM-DD
 * @param date
 * @returns
 */
export function getDateStrWithoutUTC(date?: Date) {
  if (!date) {
    date = new Date();
  }
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function getTimeZone(date?: Date) {
  if (!date) {
    date = new Date();
  }
  const offset = date.getTimezoneOffset(),
    o = Math.abs(offset);
  return parseInt(
    (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2)
  );
}

export function getMonthShortName(monthNo) {
  const date = new Date();
  date.setMonth(monthNo - 1);

  const month = date.toLocaleString(
    StorageService.GetItem('USER_LANG') ?? 'es',
    {
      month: 'short',
    }
  );
  return month.charAt(0)?.toUpperCase() + month.slice(1).toLocaleLowerCase();
}

export function getDateStrResumStr(date?: string) {
  if (!date) {
    return getDateStrResum(new Date());
  }

  const finalDateObj = {
    day: '',
    month: '',
    monthLocalShort: '',
    year: '',
    formattedDate: '',
    hour: '',
    minute: '',
    second: '',
    formattedFullSeconds: '',
    formattedFullMinutes: '',
    parseToISOStringLocal: '',
    formattedDateStorage: '',
    formattedDateStorageHours: '',
  };

  if (typeof date === 'string' && !date?.includes('T')) {
    const auxDate = date?.split('-');
    if (auxDate.length != 3) {
      try {
        return getDateStrResum(new Date(date));
      } catch (e) {
        // @ts-ignore
        return;
      }
    }
    finalDateObj.day = auxDate[2];
    finalDateObj.month = auxDate[1];
    finalDateObj.year = auxDate[0];
    finalDateObj.monthLocalShort = getMonthShortName(finalDateObj.month);
    finalDateObj.formattedDate = date;
    finalDateObj.hour = '00';
    finalDateObj.minute = '00';
    finalDateObj.second = '00';
    finalDateObj.formattedFullSeconds = `${finalDateObj.year}-${finalDateObj.month}-${finalDateObj.day} ${finalDateObj.hour}:${finalDateObj.minute}:${finalDateObj.second}`;
    finalDateObj.formattedFullMinutes = `${finalDateObj.year}-${finalDateObj.month}-${finalDateObj.day} ${finalDateObj.hour}:${finalDateObj.minute}`;
    const dateAux = new Date(date);
    const dateLocalParsed = new Date(
      dateAux.getTime() - getTimeZone(dateAux) * 60 * 60 * 1000
    );
    finalDateObj.parseToISOStringLocal = dateLocalParsed.toISOString();

    if (StorageService.DateFormat == 'DD/MM/YYYY') {
      finalDateObj.formattedDateStorage = `${finalDateObj.day}/${finalDateObj.month}/${finalDateObj.year}`;
    } else {
      finalDateObj.formattedDateStorage = `${finalDateObj.month}/${finalDateObj.day}/${finalDateObj.year}`;
    }
  } else {
    /*let auxTDate = date.split('T');
    let auxDate = auxTDate[0].split('-');*/
    try {
      return getDateStrResum(new Date(date));
    } catch (e) {}
  }
  return finalDateObj;
}

/**
 * Object with str, date without UTC
 * @param date
 * @returns
 */
export function getDateStrResum(date?: Date) {
  const finalDateObj = {
    day: '',
    month: '',
    monthLocalShort: '',
    year: '',
    formattedDate: '',
    hour: '',
    minute: '',
    second: '',
    formattedFullSeconds: '',
    formattedFullMinutes: '',
    formattedFullMinutesT: '',
    parseToISOStringLocal: '',
    formattedDateStorage: '',
    formattedDateStorageHours: '',
  };
  if (!date) {
    date = new Date();
  }

  const dateLocalParsed = new Date(
    date.getTime() - getTimeZone(date) * 60 * 60 * 1000
  );

  finalDateObj.day = date.getDate().toString().padStart(2, '0');
  finalDateObj.month = (date.getMonth() + 1).toString().padStart(2, '0');
  finalDateObj.year = date.getFullYear().toString();
  finalDateObj.monthLocalShort = getMonthShortName(finalDateObj.month);
  finalDateObj.formattedDate = `${finalDateObj.year}-${finalDateObj.month}-${finalDateObj.day}`;
  finalDateObj.hour = date.getHours().toString().padStart(2, '0');
  finalDateObj.minute = date.getMinutes().toString().padStart(2, '0');
  finalDateObj.second = date.getSeconds().toString().padStart(2, '0');
  finalDateObj.formattedFullSeconds = `${finalDateObj.year}-${finalDateObj.month}-${finalDateObj.day} ${finalDateObj.hour}:${finalDateObj.minute}:${finalDateObj.second}`;
  finalDateObj.formattedFullMinutes = `${finalDateObj.year}-${finalDateObj.month}-${finalDateObj.day} ${finalDateObj.hour}:${finalDateObj.minute}`;
  finalDateObj.formattedFullMinutesT = `${finalDateObj.year}-${finalDateObj.month}-${finalDateObj.day}T${finalDateObj.hour}:${finalDateObj.minute}`;
  finalDateObj.parseToISOStringLocal = dateLocalParsed.toISOString();

  finalDateObj.formattedDateStorage = moment(date).format(
    StorageService.DateFormat
  );
  finalDateObj.formattedDateStorageHours = moment(date).format(
    StorageService.DateFormat + ' hh:mm a'
  );

  return finalDateObj;
}

export function normalize(cadena) {
  const chars = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    ä: 'a',
    ë: 'e',
    ï: 'i',
    ö: 'o',
    ü: 'u',
    â: 'a',
    ê: 'e',
    î: 'i',
    ô: 'o',
    û: 'u',
    à: 'a',
    è: 'e',
    ì: 'i',
    ò: 'o',
    ù: 'u',
    ñ: 'n',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    Ä: 'A',
    Ë: 'E',
    Ï: 'I',
    Ö: 'O',
    Ü: 'U',
    Â: 'A',
    Ê: 'E',
    Î: 'I',
    Ô: 'O',
    Û: 'U',
    À: 'A',
    È: 'E',
    Ì: 'I',
    Ò: 'O',
    Ù: 'U',
    Ñ: 'N',
  };
  const expr = /[áàäâéèëêíìïîóòöôúùüûñ]/gi;
  const res = cadena.replace(expr, function (e) {
    return chars[e];
  });
  return res;
}
