import { Feature, FeatureUser } from '@tacliatech/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { format } from 'date-fns';
import { DateFields } from '../rm-select-date/rm-select-date.const';
import { AnalyticsService } from '../../../services/analytics/analytics.service';

export namespace RmFilter {
  export class Filter {
    private _data: RmFilter.Controls[];

    constructor(data: Array<RmFilter.Controls>) {
      this._data = data;
    }

    get() {
      return this._data;
    }

    clean() {
      for (const item of this._data) {
        item.clean();
      }
    }

    toQueryParams() {
      const values = {};

      for (const item of this._data) {
        if (!item.isEmpty()) {
          item.getKeyRequest().forEach((key, index) => {
            const value = item.getValue()[index];
            const keyRequestParent = item.getKeyRequestParent();

            if (keyRequestParent) {
              const newOrLastKeyRequestParent =
                values[keyRequestParent] || Object.create({});

              Object.assign(newOrLastKeyRequestParent, {
                [key]: value,
              });

              Object.assign(values, {
                [keyRequestParent]: newOrLastKeyRequestParent,
              });
            } else {
              Object.assign(values, {
                [key]: value,
              });
            }
          });
        }
      }

      return this.parseFromAndTo(values);
    }

    parseFromAndTo(value: any) {
      if (value.from && value.to) {
        return {
          ...value,
          from: format(value.from, 'YYYY-MM-DD'),
          to: format(value.to, 'YYYY-MM-DD'),
        };
      }
      return value;
    }

    toJson() {
      const values = [];

      for (const item of this._data) {
        item.getKeyRequest().forEach((key, index) => {
          const value = item.getValue()[index];

          if (!item.isEmpty()) {
            values.push({
              [key]: value,
            });
          }
        });
      }

      return values;
    }
  }

  export type Controls =
    | RmFilter.Checkbox
    | RmFilter.Input
    | RmFilter.Select
    | RmFilter.RangeDate
    | RmFilter.Divider
    | RmFilter.Hour;

  export abstract class Control {
    // storage to values
    abstract _value: BehaviorSubject<unknown>;
    // key identifiers to attach to the request
    abstract _keyRequest: string[];

    _keyRequestParent: string;

    show$!: Observable<boolean>;

    abstract getValue(): unknown[];
    abstract setValue(value: unknown): void;
    abstract getKeyRequest(): string[];

    getKeyRequestParent() {
      return this._keyRequestParent;
    }

    abstract isEmpty(): boolean;
    abstract clean(): void;

    isSelect(item: RmFilter.Control) {
      return item instanceof RmFilter.Select;
    }

    isInput(item: RmFilter.Control) {
      return item instanceof RmFilter.Input;
    }

    isDivider(item: RmFilter.Divider) {
      return item instanceof RmFilter.Divider;
    }

    isRangeDateInput(item: RmFilter.Control) {
      return item instanceof RmFilter.RangeDate;
    }

    isHour(item: RmFilter.Control) {
      return item instanceof RmFilter.Hour;
    }

    isCheckbox(item: RmFilter.Control) {
      return item instanceof RmFilter.Checkbox;
    }

    isInputRange() {
      return this instanceof RmFilter.InputRange;
    }

    isDate() {
      return this instanceof RmFilter.InputDate;
    }

    isSelectDateRange() {
      return this instanceof RmFilter.SelectDateRange;
    }
  }

  export class Select extends RmFilter.Control {
    _keyRequest: string[];
    _value = new BehaviorSubject<unknown[]>([]);

    feature!: Feature.Purchasable;
    featureUser!: Feature.Purchasable | FeatureUser.Purchasable;

    private _type!: RmFilter.SelectType;
    private _source$!: Observable<RmFilter.SelectMultipleItem[]>;
    private _data$ = new BehaviorSubject<RmFilter.SelectMultipleItem[]>([]);
    private _isLoading$ = new BehaviorSubject(false);
    private _content!: { label: string; placeholder: string };

    constructor(data: {
      keyRequest: string;
      type: RmFilter.SelectType;
      source$: Observable<RmFilter.SelectMultipleItem[]>;
      content: { label: string; placeholder: string };
      feature?: Feature.Purchasable;
      featureUser?: Feature.Purchasable | FeatureUser.Purchasable;
      keyRequestParent?: string;
      show$?: Observable<boolean>;
    }) {
      super();
      this._keyRequest = [data.keyRequest];
      this._type = data.type;
      this._source$ = data.source$;
      this._content = data.content;
      this.feature = data?.feature
        ? data.feature
        : Feature.SystemPermission.DefaultAllow;

      this.featureUser = data?.featureUser
        ? data.featureUser
        : Feature.SystemPermission.DefaultAllow;

      this._keyRequestParent = data?.keyRequestParent
        ? data.keyRequestParent
        : null;

      this.show$ = data?.show$ ? data.show$ : null;
    }

    get type() {
      return this._type;
    }

    get source$() {
      return this._source$;
    }

    get isLoading$() {
      return this._isLoading$.asObservable();
    }

    get data$() {
      return this._data$.asObservable();
    }

    get content() {
      return this._content;
    }

    value(): unknown[] {
      return this._value.getValue();
    }

    isEmpty(): boolean {
      return !this.getValue().length;
    }

    hasValue(value: unknown) {
      return this.getValue().includes(value);
    }

    setValue(items: RmFilter.SelectMultipleItem[]): void {
      const values = items.map((item) => item.value);

      this._value.next(values);
    }

    getValue(): unknown[] {
      const value = this._value.getValue();

      return value.length ? [value] : [];
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    load() {
      const data = this._data$.value;

      if (!data.length) {
        this._isLoading$.next(true);
        this._source$
          .pipe(
            finalize(() => {
              this._isLoading$.next(false);
            })
          )
          .subscribe(
            (res) => {
              this._data$.next(res);
              this._isLoading$.next(false);
            },
            (err) => {
              this._isLoading$.next(false);
            }
          );
      }
    }

    clean(): void {
      this.setValue([]);
    }
  }

  export class SelectDateRange extends RmFilter.Control {
    _keyRequest: [string, string];
    _value = new BehaviorSubject<unknown[]>([]);

    feature!: Feature.Purchasable;
    featureUser!: Feature.Purchasable | FeatureUser.Purchasable;

    private _type: RmFilter.SelectType = 'SINGLE';
    private _source$!: Observable<RmFilter.SelectMultipleItem[]>;
    private _data$ = new BehaviorSubject<RmFilter.SelectMultipleItem[]>([]);
    private _isLoading$ = new BehaviorSubject(false);
    private _content!: {
      label: string;
      placeholder: string;
      amplitudeType?: string;
    };
    analyticsService: AnalyticsService;

    constructor(data: {
      keyRequest: [string, string];
      type: RmFilter.SelectType;
      source$: Observable<RmFilter.SelectMultipleItem[]>;
      content: {
        label: string;
        placeholder: string;
        amplitudeType?: string;
      };
      feature?: Feature.Purchasable;
      featureUser?: Feature.Purchasable | FeatureUser.Purchasable;
      keyRequestParent?: string;
      analyticsService: AnalyticsService;
      show$?: Observable<boolean>;
    }) {
      super();

      this._keyRequest = [data.keyRequest[0], data.keyRequest[1]];
      this._type = data.type;
      this._source$ = data.source$;
      this._content = data.content;
      this.analyticsService = data.analyticsService;
      this.feature = data?.feature
        ? data.feature
        : Feature.SystemPermission.DefaultAllow;

      this.featureUser = data?.featureUser
        ? data.featureUser
        : Feature.SystemPermission.DefaultAllow;

      this._keyRequestParent = data?.keyRequestParent
        ? data.keyRequestParent
        : null;

      this.show$ = data?.show$ ? data.show$ : null;
    }

    get type() {
      return this._type;
    }

    get source$() {
      return this._source$;
    }

    get isLoading$() {
      return this._isLoading$.asObservable();
    }

    get data$() {
      return this._data$.asObservable();
    }

    get content() {
      return this._content;
    }

    value(): unknown[] {
      return this._value.getValue();
    }

    isEmpty(): boolean {
      return !this.getValue().length;
    }

    hasValue(value: unknown) {
      return this.getValue().includes(value);
    }

    setValue(
      items: RmFilter.SelectMultipleItem[],
      avoidEvents?: boolean
    ): void {
      const values = items.map((item) => item.value);
      if (!avoidEvents && items.length > 0) {
        const eventMap = {
          [`budgets.settings.create.form.${DateFields.CURRENT_MONTH}`]: `${this.content.amplitudeType}_datepicker_currentmonth`,
          [`budgets.settings.create.form.${DateFields.CURRENT_YEAR}`]: `${this.content.amplitudeType}_datepicker_currentyear`,
          [`budgets.settings.create.form.${DateFields.PREVIOUS_MONTH}`]: `${this.content.amplitudeType}_datepicker_lastmonth`,
          [`budgets.settings.create.form.${DateFields.PREVIOUS_YEAR}`]: `${this.content.amplitudeType}_datepicker_lastyear`,
        };
        this.analyticsService.trackEvent({
          eventName:
            eventMap[items[0].title] ??
            `${this.content.amplitudeType}_datepicker_daterange`,
          sources: ['amplitude'],
        });
      }
      this._value.next(values);
    }

    getValue(): unknown[] {
      const value = this._value.getValue().toString().split(' - ');
      if (value?.length < 2) return [];
      const from = value[0];
      const to = value[1];
      return [from, to];
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    load() {
      const data = this._data$.value;
      this.analyticsService.trackEvent({
        eventName: `${this.content.amplitudeType}_datepicker_filter_start`,
        sources: ['amplitude'],
      });

      if (!data.length) {
        this._isLoading$.next(true);
        this._source$
          .pipe(
            finalize(() => {
              this._isLoading$.next(false);
            })
          )
          .subscribe(
            (res) => {
              this._data$.next(res);
              this._isLoading$.next(false);
            },
            (err) => {
              this._isLoading$.next(false);
            }
          );
      }
    }

    clean(): void {
      this.setValue([], true);
    }
  }

  export type SelectType = 'MULTIPLE' | 'SINGLE';

  export interface SelectMultipleItem {
    id: string;
    _id?: number;
    value: unknown;
    title: string;
    img?: string;
  }

  export class Input extends RmFilter.Control {
    _value = new BehaviorSubject<unknown>(null);
    _keyRequest: string[];

    private _type!: RmFilter.InputType;
    private _content!: { label: string; placeholder: string };
    feature!: Feature.Purchasable;

    constructor(data: {
      keyRequest: string;
      type: RmFilter.InputType;
      content: {
        label: string;
        placeholder: string;
        placeholderHover?: string;
      };
      feature?: Feature.Purchasable;
      keyRequestParent?: string;
    }) {
      super();
      this._keyRequest = [data.keyRequest];
      this._type = data.type;
      this._content = data.content;
      this.feature = data?.feature
        ? data.feature
        : Feature.SystemPermission.DefaultAllow;

      this._keyRequestParent = data?.keyRequestParent
        ? data.keyRequestParent
        : null;
    }

    get content() {
      return this._content;
    }

    get type() {
      return this._type;
    }

    value() {
      const value = this._value.getValue();

      return value ? value : '';
    }

    getValue(): unknown[] {
      const value = this._value.getValue();
      return value ? [value] : [];
    }

    setValue(value: unknown): void {
      this._value.next(value);
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    isEmpty(): boolean {
      return this.getValue().length <= 0;
    }

    clean(): void {
      this.setValue(null);
    }
  }

  export type InputType = 'SEARCH' | 'NUMBER' | 'STRING';
  export class Divider extends RmFilter.Control {
    _value: BehaviorSubject<unknown>;
    _keyRequest: string[];
    getValue(): unknown[] {
      return [];
    }
    setValue(value: unknown): void {}
    getKeyRequest(): string[] {
      return [];
    }

    isEmpty(): boolean {
      return true;
    }

    clean(): void {}
  }

  export class Checkbox extends RmFilter.Control {
    _value = new BehaviorSubject<unknown>(null);
    _keyRequest: string[];

    private _content!: {
      label: string;
    };

    private _class: string;

    constructor(data: {
      keyRequest: string;
      class?: string;
      content: {
        label: string;
      };
    }) {
      super();
      this._keyRequest = [data.keyRequest];
      this._class = data.class;
      this._content = data.content;
    }

    get content() {
      return this._content;
    }

    get class() {
      return this._class;
    }

    value() {
      const value = this._value.getValue();

      return Boolean(value);
    }

    getValue(): unknown[] {
      const value = this._value.getValue();
      return value ? [value] : [];
    }

    setValue(value: unknown): void {
      this._value.next(value);
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    isEmpty(): boolean {
      return !this.getValue().length;
    }

    clean(): void {
      this._value.next(null);
    }
  }

  export class RangeDate extends RmFilter.Control {
    _value = new BehaviorSubject<[string, string]>([null, null]);
    _keyRequest: [string, string];

    private _content!: {
      label: string;
      startPlaceholder: string;
      endPlaceholder: string;
    };
    constructor(data: {
      keyRequest: [string, string];
      content: {
        label: string;
        startPlaceholder: string;
        endPlaceholder: string;
      };
    }) {
      super();
      this._content = data.content;
      this._keyRequest = data.keyRequest;
    }

    get content() {
      return this._content;
    }

    isComplete() {
      const [from, to] = this.getValue();
      return Boolean(from) && Boolean(to);
    }

    getValue(): [string, string] {
      return this._value.getValue();
    }

    setValue(value: { from: string; to: string }): void {
      this._value.next([value.from, value.to]);
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    isEmpty(): boolean {
      const [first, second] = this.getValue();

      return !first || !second;
    }

    clean(): void {
      this.setValue({ from: '', to: '' });
    }
  }

  export type RangeDateOutput = { from: string; to: string };

  export class Hour extends RmFilter.Control {
    _value = new BehaviorSubject<[[string, number]]>([[null, null]]);
    _keyRequest: string[];
    private _content!: {
      label: string;
      placeholder: string;
      description: string;
    };

    constructor(data: {
      keyRequest: string[];
      content: { label: string; placeholder: string; description: string };
    }) {
      super();
      this._keyRequest = data.keyRequest;
      this._content = data.content;
    }

    get content() {
      return this._content;
    }

    getValue(): [[string, number]] {
      return this._value.getValue();
    }

    setValue(value: [string, number]): void {
      this._value.next([value]);
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    isEmpty(): boolean {
      const [values] = this.getValue();
      const [first, second] = values;

      if (!first) {
        return true;
      }

      if (second !== 0 && !second) {
        return true;
      }

      return false;
    }

    clean(): void {
      this.setValue([null, null]);
    }
  }

  export class InputRange extends RmFilter.Control {
    _value = new BehaviorSubject<{
      order: '<' | '>' | '<=' | '=' | '>=';
      value: number;
    }>({ order: '=', value: null });

    _keyRequest: string[];

    feature!: Feature.Purchasable;

    private _content!: { placeholder: string };

    constructor(data: {
      keyRequest: string;
      content: { placeholder: string };
      feature?: Feature.Purchasable;
      keyRequestParent?: string;
    }) {
      super();
      this._keyRequest = [data.keyRequest];
      this._content = data.content;
      this.feature = data?.feature
        ? data.feature
        : Feature.SystemPermission.DefaultAllow;

      this._keyRequestParent = data?.keyRequestParent
        ? data.keyRequestParent
        : null;
    }

    get content() {
      return this._content;
    }

    value() {
      return this._value.getValue();
    }

    getValue(): unknown[] {
      return [this._value.getValue()];
    }

    setValue(data: {
      order: '<' | '>' | '<=' | '=' | '>=';
      value: number;
    }): void {
      this._value.next(data);
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    isEmpty(): boolean {
      const storage = this._value.getValue();

      if (storage?.value === null) {
        return true;
      }

      return !storage?.order || !(storage?.value >= 0);
    }

    clean(): void {
      this._value.next({ order: '=', value: null });
    }
  }

  export class InputDate extends Control {
    _value = new BehaviorSubject<string>(null);
    _keyRequest: string[];

    feature!: Feature.Purchasable;

    private _content!: { placeholder: string };

    constructor(data: {
      keyRequest: string;
      content: { placeholder: string };
      feature?: Feature.Purchasable;
      keyRequestParent?: string;
    }) {
      super();
      this._keyRequest = [data.keyRequest];
      this._content = data.content;
      this.feature = data?.feature
        ? data.feature
        : Feature.SystemPermission.DefaultAllow;

      this._keyRequestParent = data?.keyRequestParent
        ? data.keyRequestParent
        : null;
    }

    get content() {
      return this._content;
    }

    isComplete() {
      const [value] = this.getValue();
      return Boolean(value);
    }

    getValue(): unknown[] {
      return [this._value.getValue()];
    }

    setValue(value: Date): void {
      const current = value ? format(value, 'YYYY-MM-DD').toString() : null;

      this._value.next(current);
    }

    getKeyRequest(): string[] {
      return this._keyRequest;
    }

    isEmpty(): boolean {
      return !this._value.getValue();
    }

    clean(): void {
      this._value.next(null);
    }
  }

  export interface Changes {
    type: 'CHANGE' | 'INPUT_CHANGE';
    queryParams: { [key: string]: unknown };
  }
}
