<!-- Stripe Snackbar -->
<roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

<!-- Disclaimer row -->
<roma-pro-disclaimer-banner
  *ngIf="isLowerThan1450px"
  (emitRefresh)="refresh()"
  [rootModule]="'taclia'"
></roma-pro-disclaimer-banner>

<ng-container *ngIf="showEmptyState">
  <empty-state
    contentType="expense"
    (firstButtonClick)="addExpense()"
  ></empty-state>
</ng-container>

<ng-container *ngIf="!showEmptyState">
  <div class="row" *ngIf="showTitle">
    <div class="col-8 col-md-6">
      <h1 class="page-title">
        {{ 'expense.title' | translate }}
        <div class="tooltip-container">
          <img
            width="32px"
            class="help-icon"
            [src]="iconDialog"
            (click)="showDialog()"
          />
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip 22"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
            (clicked)="toggleTooltip($event)"
          >
            <div [innerHTML]="'expense.tooltip' | translate"></div>
            <a
              *ngIf="academyLink?.length"
              [href]="academyLink"
              target="_blank"
              >{{ 'expense.tooltipUrl' | translate }}</a
            >
          </div>
        </div>
      </h1>
    </div>
    <div class="col-4 col-md-6 video-section">
      <div
        class="tutorial"
        *ngIf="getLang() === 'es'"
        (click)="openVideo('expenses')"
      >
        <strong>{{ 'tutorial.tutorial' | translate }}</strong>
        <img class="video" src="assets/icons/gl_play-tutorial.svg" />
      </div>
      <button
        class="roma-button add-button-3 mobile-hidden"
        style="display: inline-block"
        romaProFeature
        [featureUser]="featureRefUser.Expense.create"
        (clicked)="addExpense()"
      >
        {{ 'general.addButton' | translate }}
        <img class="icon-option" src="assets/icons/gl_plus-btn.svg" />
      </button>
    </div>
  </div>

  <div class="row" *ngIf="showFilter">
    <div class="col">
      <div
        *ngIf="!(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-core
          *ngIf="sourceSearch"
          [source]="sourceSearch"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-core>
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-button>
        <roma-rm-filter-clear
          *ngIf="sourceOptions"
          [sources]="[sourceSearch, sourceOptions]"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-clear>
      </div>

      <div
        *ngIf="(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-button>
        <button
          class="mobile-button-add"
          romaProFeature
          [featureUser]="featureRefUser.Expense.create"
          (clicked)="addExpense($event)"
        >
          <img
            src="/assets/icons/gl_plus-1.svg"
            alt="{{ 'general.add' | translate }}"
          />
        </button>
      </div>
    </div>
  </div>

  <div class="page-options">
    <div
      *ngIf="!isLoading || !expenses?.data?.length"
      class="table-total md"
      style="float: left"
    >
      {{ resultSearch?.total || 0 }} {{ 'general.results' | translate }}
    </div>

    <div style="display: flex">
      <roma-shared-download-btn
        *ngIf="!(resize$ | async)?.payload?.mobile && expenses?.data?.length"
        [showDownloadExcel]="true"
        [showDownloadPdf]="false"
        (download)="download($event)"
        [ngClass]="{ 'is-hidden': isLoading }"
      ></roma-shared-download-btn>
      <div
        romaProSelect
        [featureUser]="featureRefUser.Expense.create"
        class="wrapper-pro-feature-user"
      >
        <button
          *ngIf="showAddBtn && !isLoading"
          class="tutorial"
          romaClickSequence
          [disabled]="modalIsLoading"
          (click)="addExpense()"
        >
          {{ 'general.addButton' | translate }}
          <mat-progress-spinner
            *ngIf="modalIsLoading"
            style="margin-left: 6px"
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="20"
          ></mat-progress-spinner>

          <img
            *ngIf="!modalIsLoading"
            class="video"
            src="assets/icons/gl_plus.svg"
          />
        </button>
      </div>
    </div>
  </div>
  <div
    id="containerTable"
    class="containerTable"
    *ngIf="viewTypeActive === 'ROW'"
    [ngClass]="{
      'video-tutorial-box':
        !isLoading &&
        !(searchParamsHasChange$ | async) &&
        !expenses?.data?.length
    }"
    [ngStyle]="{ 'margin-top': tableMarginTop }"
    (scroll)="checkScroll($event)"
  >
    <ng-container>
      <div class="my-4">
        <div *ngIf="isLoading" class="col-12">
          <roma-loading></roma-loading>
        </div>

        <div
          *ngIf="
            !isLoading &&
            (searchParamsHasChange$ | async) &&
            !expenses?.data?.length
          "
          class="col-12"
        >
          <roma-not-result-found></roma-not-result-found>
        </div>

        <ng-container *ngIf="columns.length">
          <div
            class="col-12"
            [ngClass]="{ 'is-hidden': isLoading || !expenses?.data?.length }"
          >
            <roma-table
              class="table"
              type="expenses"
              idModule="EXPENSE_MODULE"
              [appendSettings]="'YES'"
              [tagNotAvailable]="'expense.notAvailable' | translate"
              [data]="expenses"
              [displayedColumns]="columns"
              [customFields]="customProperties"
              [showActions]="true"
              (changeSort)="sortChange($event)"
              (wantMark)="markExpense($event)"
              (wantDelete)="removeExpense($event)"
              (wantEdit)="editExpense($event)"
              [appendInfo]="'YES'"
              (wantRestore)="removeExpense($event)"
              [showTotal]="showTotal"
              [totalCount]="totalExpenses"
              [subtotalCount]="subtotalExpenses"
              [settings]="settings"
              (checkRowChanges)="deleteMany($event)"
            >
            </roma-table>
          </div>
        </ng-container>
        <div *ngIf="!isLoading && isLoadingPaginate" class="col-12">
          <roma-loading></roma-loading>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- table pagination -->
  <div *ngIf="expenses?.data?.length" class="pagination">
    <button
      class="arrow prev"
      [attr.disabled]="resultSearch.page === 1 ? true : null"
      (click)="previousPage()"
    >
      <img src="assets/icons/gl_arrow_left.svg" />
    </button>
    <ng-container *ngFor="let page of totalPages">
      <div
        class="page-item"
        *ngIf="page.page < 6"
        [ngClass]="{ 'current-page': page.page === resultSearch.page }"
        (click)="goToPage(page.page)"
      >
        {{ page.page }}
      </div>
      <div
        class="page-item"
        *ngIf="
          resultSearch.countPages > 5 && page.page === resultSearch.countPages
        "
        [ngClass]="{ 'current-page': page.page === resultSearch.page }"
        (click)="goToPage(resultSearch.countPages)"
      >
        ...
        {{ resultSearch.countPages }}
      </div>
    </ng-container>
    <button
      class="arrow next"
      [attr.disabled]="!resultSearch.hasNextPage ? true : null"
      (click)="nextPage()"
    >
      <img src="assets/icons/gl_arrow_right-pagination.svg" />
    </button>
  </div>
  <roma-activity-calendar
    *ngIf="viewTypeActive === 'CALENDAR'"
    [searchParams]="searchParams"
    (activityClick)="requestOpenActivity($event)"
  ></roma-activity-calendar>
  <div *ngIf="deleteMessage" class="row">
    <div
      class="col-12 col-md-11 delete-message-modal"
      style="position: relative"
    >
      <div class="delete-message">
        <strong>
          ({{ entriesForDelete.length }})
          {{ 'general.selected' | translate }}
        </strong>
        <ng-container *ngIf="searchParams?.takeDeleted">
          <!-- restore option   -->
          <button
            class="btn-download btn-restore"
            (clicked)="restore()"
            romaProFeature
            [featureUser]="featureRefUser.Expense.update"
          >
            <img src="assets/icons/gl_trash_restore.svg" />
            {{ 'general.restoreButton' | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="!searchParams?.takeDeleted">
          <roma-shared-download-btn
            [showDownloadExcel]="true"
            [showDownloadPdf]="false"
            (download)="download($event)"
            [ngClass]="{ 'is-hidden': isLoading }"
          ></roma-shared-download-btn>
          <button
            class="btn"
            (clicked)="openDeleteManyDialog()"
            romaProFeature
            [featureUser]="featureRefUser.Expense.delete"
          >
            <img src="assets/icons/gl_trash_red.svg" />
            {{ 'general.deleteAlt' | translate }}
          </button>
        </ng-container>
        <div class="btn-close" (click)="unselectItems()"></div>
      </div>
    </div>
  </div>
</ng-container>
