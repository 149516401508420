import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  COMPANY_ID_KEY,
  ClockAbsence,
  Feature,
  FeatureUser,
  PaginateResponse,
} from '@tacliatech/types';
import {
  AddClockAbsenceService,
  ShowDetailClockAbsenceService,
} from '@web-frontend/shared/components/clock-absences';
import { RmSelect } from '@web-frontend/shared/components/globals/rm-select/rm-select.types';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import {
  ClockAbsenceService,
  SandboxService,
  StorageService,
  UserSearchService,
  UserService,
} from '@web-frontend/shared/services';
import { isEqual } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { FilterComponent } from '@web-frontend/shared/components/filter/filter.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { RmSelectComponent } from '@web-frontend/shared/components/globals/rm-select';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { TableComponent } from '@web-frontend/shared/components/table/table.component';
import { USER_ID_KEY } from '@web-frontend/shared/constants';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { RemoveEmpty } from '@web-frontend/shared/utils';
import { addHours } from 'date-fns';
import { Workbook } from 'exceljs';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import {
  SOURCE_FILTER,
  SOURCE_MOBILE,
  SOURCE_SECONDARY_WEB_FILTER,
  SOURCE_USER_FILTER,
  SOURCE_USER_MOBILE_FILTER,
  dummyDataPreview,
} from './clock-absence.conts';
import { ListMobileClockAbsenceAction } from './list-mobile-clock-absence';

import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { CalendarEventType } from '../../../microfrontends/mf-calendar/mf-calendar.component';
import { VacationReport } from '../../../../types-legacy/lib/interfaces/vacation-report.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CreatePersonService } from '@web-frontend/shared/components/create-persons/create-person.service';
import {
  Absence,
  AbsenceEmployee,
} from '../../../../types-legacy/lib/packages/clock-absence/absenceEmployee';
import { ClockHourService } from '../../../shared/services/clock-hour/clock-hour.service';

const INITIAL_FILTER = (isMyAbsence: boolean) => {
  return {
    'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
    'idOwners[]': isMyAbsence ? [StorageService.GetItem(USER_ID_KEY)] : [],
    'types[]': [],
    'status[]': [],
    customProperties: null,
    keyword: '',
    page: 1,
    limit: 50,
    from: null,
    to: null,
  };
};

const absenceTypes = () => {
  return [
    {
      title: 'holidays',
      value: '0',
    },
    {
      title: 'sickness',
      value: '1',
    },
    {
      title: 'maternity',
      value: '2',
    },
    {
      title: 'familySickness',
      value: '3',
    },
    {
      title: 'others',
      value: '4',
    },
  ];
};

enum absenceViews {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}

@Component({
  selector: 'roma-clock-absence',
  templateUrl: './clock-absence.component.html',
  styleUrls: ['./clock-absence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockAbsenceComponent implements OnInit {
  tableMarginTop = '0px';
  distanceToTop = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
  }

  @ViewChild(FilterComponent)
  filterComponent: FilterComponent;

  @ViewChild(TableComponent)
  table: TableComponent;

  @ViewChild('inputFilterSearch')
  input: ElementRef<HTMLInputElement>;

  @ViewChild('selectAbsenceOwner') selectAbsenceOwner: RmSelectComponent;

  @ViewChild('selectAbsenceStatus') selectAbsenceStatus: RmSelectComponent;

  @ViewChild('selectTypeAbsence') selectTypeAbsence: RmSelectComponent;

  searchText = '';
  isLoading = false;
  isLoadingPaginate = false;
  loadingAuthors = false;
  resultSearch!: PaginateResponse<ClockAbsence.Output[]>;
  entriesForDelete!: ClockAbsence.Output[];
  resize$ = this.sandBoxService.screenBusChannel$;
  activeFilters = false;
  usersFilters = false;
  typesFilters = false;
  statusFilters = false;
  searchFilter = false;
  deleteMessage = false;
  timeout: any = null;
  totalPages: any[] = [];
  authors: RmSelect.Items = [];
  authorsCharged = false;
  absenceTypesFilter = absenceTypes();
  absenceStatusFilter = [];
  absencesColumns = [
    'select',
    'userName',
    'initDate',
    'endDate',
    'type',
    'status',
    'note',
  ];
  displayedColumns = [];
  isAdminAbsences = false;
  featureRef = Feature;
  featureUserRef = FeatureUser;
  tableProPreviewData = dummyDataPreview;
  sourceFilter!: RmFilter.Filter;
  sourceSecondaryMobileFilter!: RmFilter.Filter;
  sourceSecondaryWebFilter!: RmFilter.Filter;
  sourceUserFilter: RmFilter.Filter;
  sourceMobileFilter: RmFilter.Filter;
  allFilters: any;

  disableBulkDelete = false;
  viewTypeActive: absenceViews.CALENDAR | absenceViews.LIST;
  absenceViews = absenceViews;
  calendarYear: number = new Date().getFullYear();
  openMobileViewType = false;
  openMobileTeamActions = false;
  private hasActiveFilters = false;
  private calendarId: string;
  private isLoadingCalendars = false;
  private isLoadingHolidays = false;
  private workdays: string[] = [];
  private holidaysDates: string[] = [];
  private loadingTotal = true;

  hasAddClockAbsenceFeature$ = this.permissionService.hasFeatureFn(
    Feature.ClockAbsence.AddNewClockAbsenceMine
  );

  isMyAbsence = () => {
    const map = this.activateRoute.snapshot.queryParamMap;
    const hasOwner = !!map.get('idOwner');

    return hasOwner;
  };

  private userAbsenceTitle?: string;
  public isFromTeamView = false;
  fromTeamView = (): void => {
    const map = this.activateRoute.snapshot.queryParamMap;
    if (map.get('fromTeamView')) {
      this.setUserId();
      if (this.userId) {
        this.userService.findById(this.userId).subscribe((user) => {
          this.userAbsenceTitle = user.name;
          this.isFromTeamView = true;
        });
      }
    } else {
      this.isFromTeamView = false;
      this.userAbsenceTitle = this.i18n.instant('clockHour.myClockAbsence');
    }
    this.draw();
  };

  private sub$ = new Subscription();
  private searchParams = INITIAL_FILTER(this.isMyAbsence());

  showVideoTutorialButton: boolean;

  scrollDown$ = this.scrollService.scrollDown$;
  academyLink = '';
  queryParamMap$: Observable<any>;
  abscenseType = null;
  private pendingAbsenceEventSent = false;
  calendarEvents: CalendarEventType[] = [];
  holidayEvents: CalendarEventType[] = [];
  vacationReport: VacationReport;
  pendingEvents: CalendarEventType[] = [];
  totalSummary: { type: string; count: number }[] = [];
  isAdmin = false;
  absencesEmployees: AbsenceEmployee[] = [];
  filteredAbsencesEmployees: AbsenceEmployee[] = [];
  userId = StorageService.GetItem(USER_ID_KEY);
  absencesPerUser: ClockAbsence.Output[] = [];
  filterDate: { startDate: string; endDate: string } = {
    startDate: this.searchParams.from,
    endDate: this.searchParams.to,
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private i18n: TranslateService,
    private fileWrite: FileWriteService,
    private sandBoxService: SandboxService,
    private clockAbsenceService: ClockAbsenceService,
    private userSearchService: UserSearchService,
    private addClockAbsenceService: AddClockAbsenceService,
    private showDetailClockAbsenceService: ShowDetailClockAbsenceService,
    private activateRoute: ActivatedRoute,
    private permissionService: PermissionService,
    public dialog: MatDialog,
    private video: VideoService,
    public tutorialService: TutorialService,
    private scrollService: ScrollService,
    private toastService: ToastService,
    private readonly downloadBtnService: DownloadBtnService,
    private analyticsService: AnalyticsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private createPersonService: CreatePersonService,
    private userService: UserService,
    private readonly router: Router,
    private readonly clockHourService: ClockHourService
  ) {
    i18n.onLangChange.subscribe(() => {
      this.translateAbsencesTypes();
    });

    this.matIconRegistry.addSvgIcon(
      'activity_calendar_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/calendar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'table_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/table-horizontal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'celebration_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/party-horn.svg'
      )
    );
  }

  addClockAbsencePreviewMode() {
    this.addClockAbsenceService
      .open({
        data: {
          mode: 'ADD',
          previewMode: true,
        },
      })
      .subscribe();
  }

  ngOnInit(): void {
    this.watchQueryParams();
    this.requestAbsences(true);
    this.translateAbsencesTypes();
    this.watchRefreshList();
    this.videoTutorial();
    this.resetViewType();
    this.getVacationsReport();
    this.getAbsencesEmployees();
    this.getAbsencesPerUser();
    this.isAdmin = this.isUserAdmin();
    this.fromTeamView();
  }

  resetViewType(): void {
    this.changeViewToCalendar(false);
  }

  changeViewToCalendar(trackEvent = true): void {
    this.viewTypeActive = absenceViews.CALENDAR;
    if (trackEvent) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: this.isAdminAbsences
          ? AmplitudeEvents.absenceTeam_teamView
          : AmplitudeEvents.absence_calendarView,
      });
    }
  }

  changeViewToList(trackEvent = true): void {
    this.viewTypeActive = absenceViews.LIST;
    if (trackEvent) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: this.isAdminAbsences
          ? AmplitudeEvents.absenceTeam_fullView
          : AmplitudeEvents.absence_fullView,
      });
    }
  }

  private fetchUserWorkdays(): void {
    if (this.userId) {
      this.userService.findById(this.userId).subscribe((res) => {
        if (res && res.contract?.workdays) {
          this.workdays = res.contract.workdays;
        }
        this.calculatePendingAbsences();
        this.calculateTotalSummary();
        this.draw();
      });
    }
  }

  setAbsencePermissions(): void {
    if (this.resultSearch?.docs) {
      this.resultSearch.docs.map((item, index) => {
        let getPermissions = null;

        if (this.isAdminAbsences) {
          getPermissions = this.permissionService.getTeamViewAbsencePermissions(
            this.isUserAdmin(),
            item?.dateDetail?.startDate
          );
        } else {
          getPermissions = this.permissionService.getMyViewAbsencePermissions(
            this.isUserAdmin(),
            item?.dateDetail?.startDate
          );
        }

        if (getPermissions) {
          getPermissions.then((statusMap) => {
            this.resultSearch.docs[index]['showEdit'] =
              statusMap[item.status].showEdit;
            this.resultSearch.docs[index]['showDelete'] =
              statusMap[item.status].showDelete;
          });
        }
      });
      this.draw();
    }
  }

  videoTutorial() {
    this.queryParamMap$ = this.activateRoute.queryParamMap.pipe(
      map((queryParamMap) => {
        return queryParamMap.has('idOwner');
      })
    );

    this.sub$.add(
      this.queryParamMap$.subscribe((res) => {
        this.showVideoTutorialButton = false;
        this.draw();

        if (res) {
          this.abscenseType = 'clock-absence-mine';
        } else {
          this.abscenseType = 'clock-absence-team';
        }

        this.tutorialService
          .get(this.abscenseType)
          .pipe(
            finalize(() => {
              this.draw();
            })
          )
          .subscribe((res) => {
            this.academyLink = res?.academy;
            if (res?.source) {
              this.showVideoTutorialButton = !!res.source;
            } else {
              this.showVideoTutorialButton = false;
            }
          });
      })
    );
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'authors':
        if (!this.authorsCharged) {
          this.authorsCharged = true;
          this.requestAuthors();
        }
        break;
    }
  }

  launchModalAbsence = (startDate?: Date): void => {
    if (startDate) {
      startDate = new Date(startDate + 'T00:00:00');
    }

    this.addClockAbsenceService
      .open({
        data: {
          mode: 'ADD',
          defaultStartDate: startDate || null,
          isAdminAbsences: this.isAdminAbsences,
          calendarView: this.viewTypeActive === absenceViews.CALENDAR,
        },
      })
      .subscribe(() => {
        this.getAbsencesPerUser();
      });
  };

  launchModalAbsenceEdit(evt: ClockAbsence.Output): void {
    this.addClockAbsenceService
      .open({
        data: {
          mode: 'EDIT',
          clockAbsence: evt,
          isAdminAbsences: this.isAdminAbsences,
          calendarView: this.viewTypeActive === absenceViews.CALENDAR,
        },
      })
      .subscribe(() => {
        this.getAbsencesPerUser();
      });
  }

  launchModalAbsenceDetails = (evt: ClockAbsence.Output): void => {
    this.showDetailClockAbsenceService
      .open({
        data: {
          clockAbsence: evt,
          isAdminAbsences: this.isAdminAbsences,
          calendarView: this.viewTypeActive === absenceViews.CALENDAR,
        },
      })
      .subscribe();
  };

  goToPrevYear(): void {
    this.calendarYear--;
    this.getHolidays();
    this.getVacationsReport();
    this.getAbsencesPerUser();
  }
  goToNextYear(): void {
    this.calendarYear++;
    this.getHolidays();
    this.getVacationsReport();
    this.getAbsencesPerUser();
  }
  goToCurrentYear(): void {
    this.calendarYear = new Date().getFullYear();
    this.getHolidays();
    this.getVacationsReport();
    this.getAbsencesPerUser();
  }

  createAbsenceFromEmployeeCalendar = (evt: {
    date: Date;
    userId: string;
  }): void => {
    this.addClockAbsenceService
      .open({
        data: {
          mode: 'ADD',
          defaultStartDate: evt.date || null,
          userId: evt.userId,
          isAdminAbsences: this.isAdminAbsences,
          calendarView: this.viewTypeActive === absenceViews.CALENDAR,
        },
      })
      .subscribe(() => {
        this.getAbsencesEmployees();
        this.requestAbsences(true);
      });
  };

  showAbsenceDetailFromCalendar = (evt: string): void => {
    const map = this.activateRoute.snapshot.queryParamMap;

    let clockAbsenceSelected = this.absencesPerUser.find(
      (item) => item._id === evt
    );

    if (clockAbsenceSelected) {
      clockAbsenceSelected = {
        ...clockAbsenceSelected,
        owner: {
          _id: clockAbsenceSelected.idOwner,
          name: map.get('fromTeamView') ? this.userAbsenceTitle : '',
          email: '',
        },
      };

      this.showDetailClockAbsenceService
        .open({
          data: {
            clockAbsence: clockAbsenceSelected,
            isAdminAbsences: this.isAdminAbsences || !!map.get('fromTeamView'),
            calendarView: this.viewTypeActive === absenceViews.CALENDAR,
          },
        })
        .subscribe(() => {
          this.getAbsencesPerUser();
          this.requestAbsences(true);
        });
    }
  };

  showAbsenceDetailFromEmployeeCalendar = (evt: { id: string }): void => {
    const params = {
      ['idCompanies[]']: StorageService.CompanyId,
      ['ids[]']: [evt.id],
    };

    let clockAbsence: ClockAbsence.Output;

    this.clockAbsenceService.searchByTeam(params).subscribe((res) => {
      if (res.docs.length > 0) {
        clockAbsence = res.docs[0];
      } else {
        const clockAbsenceAux = this.findAbsenceInEmployees(
          this.filteredAbsencesEmployees,
          evt.id
        );

        if (clockAbsenceAux) {
          clockAbsence = this.transformAbsenceToClockAbsenceOutput(
            clockAbsenceAux
          );
        }
      }

      this.showDetailClockAbsence(clockAbsence);
    });
  };

  private transformAbsenceToClockAbsenceOutput(
    absence: Absence
  ): ClockAbsence.Output {
    return {
      _id: absence._id,
      owner: {
        _id: absence.idOwner,
        name: '',
        email: '',
      },
      idCompany: absence.idCompany,
      idCreatedBy: absence.idCreatedBy,
      idOwner: absence.idOwner,
      totalDays: 0,
      dateDetail: {
        startDate: absence.dateDetail.startDate.toString(),
        startDateUTC: absence.dateDetail.startDate,
        endDate: absence.dateDetail.endDate.toString(),
        endDateUTC: absence.dateDetail.endDate,
      },
      type: absence.type,
      status: absence.status,
      note: absence.note,
      dateCreated: new Date(),
      deleted: absence.deleted,
      reasonRejected: {
        description: '',
        idCreatedBy: '',
      },
      typeDetail: {
        type: absence.typeDetail.type,
        halfDayType: ClockAbsence.HalfDayType[absence.typeDetail.halfDayType],
        files: absence.typeDetail.files,
      },
    };
  }

  private showDetailClockAbsence = (clockAbsence: ClockAbsence.Output) => {
    this.showDetailClockAbsenceService
      .open({
        data: {
          clockAbsence: clockAbsence,
          isAdminAbsences: this.isAdminAbsences,
          calendarView: this.viewTypeActive === absenceViews.CALENDAR,
        },
      })
      .subscribe((res) => {
        if (res) {
          this.getAbsencesEmployees();
          this.requestAbsences(true);
        }
      });
  };

  private findAbsenceInEmployees(
    employees: AbsenceEmployee[],
    absenceId: string
  ): Absence | undefined {
    for (const employee of employees) {
      const absence = employee.absences.find(
        (absence) => absence._id === absenceId
      );
      if (absence) {
        return absence;
      }
    }
    return undefined;
  }

  showUserDetail = (evt: { id: string }): void => {
    this.router.navigate(['/admin/clock-absence'], {
      queryParams: { idOwner: evt.id, fromTeamView: true },
    });
  };

  isUserAdmin(): boolean {
    return (
      JSON.parse(StorageService.userData).role?.toLowerCase() == 'admin_role'
    );
  }

  deleteAbsence(evt) {
    if (evt[0]?.status == 2 && this.isUserAdmin() == false) {
      this.toastService.show({
        text: this.i18n.instant('clockHour.errorDeleteAccess'),
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    const id = evt.map((entry) => entry._id);
    this.clockAbsenceService.deleteOne(id).subscribe(() => {
      this.requestAbsences(true);
      this.toastService.show({
        text: this.i18n.instant('clockHour.deleteAbsence'),
        type: 'success',
        msDuration: 4000,
      });
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: AmplitudeEvents.absence_deleted,
      });
    });
  }

  deleteAbsences(evt: ClockAbsence.Output[]): void {
    const ids = evt.map((entry) => entry._id);
    this.sub$.add(
      this.clockAbsenceService
        .deleteMany({
          ids: ids,
        })
        .pipe(
          finalize(() => {
            this.deleteMessage = false;
            this.entriesForDelete = [];
          })
        )
        .subscribe(() => {
          this.requestAbsences(true);
          this.toastService.show({
            text: this.i18n.instant('clockAbsence.deleteSuccess'),
            type: 'success',
            msDuration: 4000,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.absence_deleted,
          });
        })
    );
  }

  deleteMany(selectedItems: ClockAbsence.Output[]): void {
    this.entriesForDelete = selectedItems;
    this.disableBulkDelete = selectedItems.some((item) => !item['showDelete']);
    this.deleteMessage = selectedItems.length > 0;
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: this.entriesForDelete?.length > 1,
        body: this.i18n.instant('deleteModal.body_final'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteAbsences(this.entriesForDelete);
        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName: AmplitudeEvents.absenceTeam_bulk_delete,
        });
      }
    });
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.changeKeyword(event.target.value);
      }
    }, 500);
  }

  makeMobileActions(evt: ListMobileClockAbsenceAction) {
    const clockAbsence = this.resultSearch.docs.find(
      (clock) => clock._id === evt.id
    );

    if (evt.action === 'EDIT') {
      this.launchModalAbsenceEdit(evt.item);
    }

    if (evt.action === 'VIEW') {
      this.launchModalAbsenceDetails(evt.item);
    }

    if (evt.action === 'DELETE') {
      this.deleteAbsence([clockAbsence]);
    }
  }

  private changeKeyword(keyword: string) {
    if (keyword) {
      this.searchFilter = true;
    } else {
      this.searchFilter = false;
    }
    this.searchParams['keyword'] = keyword;
    this.searchParams['page'] = 1;

    this.requestAbsences(true);
  }

  private requestAuthors() {
    this.loadingAuthors = true;
    this.draw();

    this.sub$.add(
      this.userSearchService
        .search({ applyPaginate: false })
        .pipe(
          finalize(() => {
            this.loadingAuthors = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.authors = res.docs
            .map((res) => {
              return {
                title: res.name.toString().toLocaleLowerCase(),
                value: res._id,
                id: res._id,
              };
            })
            .sort((a, b) => (a?.title > b?.title ? 1 : -1));
          this.draw();
        })
    );
  }

  changeOwners(evt): void {
    if (evt.length) {
      this.usersFilters = true;
    } else {
      this.usersFilters = false;
    }

    const ids = evt.map(function (element) {
      return element.value;
    });
    this.searchParams['idOwners[]'] = ids;
    this.searchParams['page'] = 1;
    this.requestAbsences(true);
  }

  changeTypesAbsences(evt) {
    let absencesTypeValues;
    if (evt.length) {
      this.typesFilters = true;
      absencesTypeValues = evt.map(function (element) {
        return element.value;
      });
    } else {
      this.typesFilters = false;
    }
    this.searchParams['types[]'] = absencesTypeValues;
    this.searchParams['page'] = 1;
    this.requestAbsences(true);
  }

  changeStatusAbsences(evt) {
    let statusValues;
    if (evt.length) {
      this.statusFilters = true;
      statusValues = evt.map(function (element) {
        return element.value;
      });
    } else {
      this.statusFilters = false;
    }
    this.searchParams['status[]'] = statusValues;
    this.searchParams['page'] = 1;

    this.requestAbsences(true);
  }

  changeDate(type: 'START' | 'END', value: Date) {
    this.activeFilters = true;
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      [type === 'START' ? 'from' : 'to']: JSON.stringify(
        addHours(value, 6).toUTCString()
      ),
    };

    if (this.searchParams['from'] && this.searchParams['to']) {
      this.requestAbsences(true);
    }
  }

  cleanFilters() {
    this.input.nativeElement.value = '';
    this.filterComponent.clearRangeDateInput();
    this.selectAbsenceOwner?.clearInput('out');
    this.selectAbsenceStatus?.clearInput('out');
    this.selectTypeAbsence?.clearInput('out');
    this.activeFilters = false;
    this.searchFilter = false;
    this.statusFilters = false;
    this.typesFilters = false;
    this.usersFilters = false;
    this.searchParams = INITIAL_FILTER(this.isMyAbsence());
    this.requestAbsences(true);
  }

  nextPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestAbsences(true);
  }

  previousPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };

    this.requestAbsences(true);
  }

  downloadExcel() {
    const workbook = this.getBuildExcel();

    const type =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    workbook.xlsx
      .writeBuffer()
      .then(
        (data) =>
          new Blob([data], {
            type,
          })
      )
      .then((blob) =>
        this.fileWrite.writeFile({
          path: `clock-absence-${this.datePipe.transform(
            new Date(),
            'dd-MM-yyyy'
          )}.xlsx`,
          blob,
        })
      )
      .then(() => this.downloadBtnService.downloadedSuccessfully());
  }

  private requestAbsences(loadingGlobal = false, loadingPagination = false) {
    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    if (loadingPagination) {
      this.isLoadingPaginate = true;
      this.draw();
    }

    const query = RemoveEmpty(this.searchParams);

    this.sub$.add(
      this.clockAbsenceService
        .searchByTeam({
          ...query,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.isLoadingPaginate = false;
            this.calcTableDistance();
            this.getVacationsReport();
            this.draw();
          })
        )
        .subscribe(this.setAbsences.bind(this))
    );
  }

  private getBuildExcel(): Workbook {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('ClockAbsence');
    const header = [
      this.i18n.instant('clockAbsence.file.name'),
      this.i18n.instant('clockAbsence.file.startDate'),
      this.i18n.instant('clockAbsence.file.endDate'),
      this.i18n.instant('clockAbsence.file.totalDays'),
      this.i18n.instant('clockAbsence.file.typeAbsence'),
      this.i18n.instant('clockAbsence.file.status'),
      this.i18n.instant('clockAbsence.file.notes'),
    ];
    // const footer = [' ', ' ', ' ', ' ', 'TOTAL', this.resultSearch.total];
    const headerRow = worksheet.addRow(header);

    headerRow.font = {
      color: { argb: '003c48ec' },
      bold: true,
      size: 12,
    };

    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    const NOT_AVAILABLE_TEXT = this.i18n.instant(
      'general.propertyNotAvailable'
    );

    for (const clockAbsence of this.resultSearch.docs) {
      const cells = [];

      cells.push(
        clockAbsence.owner ? clockAbsence.owner?.name : NOT_AVAILABLE_TEXT
      );

      cells.push(clockAbsence.dateDetail.startDate);

      cells.push(clockAbsence.dateDetail.endDate);

      const { startDate, endDate } = clockAbsence.dateDetail;
      const type = clockAbsence.typeDetail.type;

      cells.push(
        ClockAbsence.GetTotalDays({
          startDate: new Date(startDate).toDateString(),
          endDate: new Date(endDate).toDateString(),
          type,
        })
      );

      cells.push(this.getTypeAbsence(clockAbsence.type));

      cells.push(this.getAbsenceStatus(clockAbsence.status));

      cells.push(clockAbsence.note);

      const row = worksheet.addRow(cells);

      row.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    }

    worksheet.columns.forEach(function (column) {
      column.width = 27;
    });

    return workbook;
  }

  getTypeAbsence(type) {
    let typeAbsence;
    switch (type) {
      case 0:
        typeAbsence = this.i18n.instant('table.content.holidays');
        break;
      case 1:
        typeAbsence = this.i18n.instant('table.content.sickness');
        break;
      case 2:
        typeAbsence = this.i18n.instant('table.content.maternity');
        break;
      case 3:
        typeAbsence = this.i18n.instant('table.content.familySickness');
        break;
      case 4:
        typeAbsence = this.i18n.instant('table.content.others');
        break;
      default:
        typeAbsence = this.i18n.instant('general.propertyNotAvailable');
        break;
    }
    return typeAbsence;
  }

  getAbsenceStatus(status) {
    let absenceStatus;
    switch (status) {
      case 0:
        absenceStatus = this.i18n.instant('table.content.pending');
        break;
      case 1:
        absenceStatus = this.i18n.instant('table.content.rejected');
        break;
      case 2:
        absenceStatus = this.i18n.instant('table.content.approved');
        break;
      case 3:
        absenceStatus = this.i18n.instant('table.content.others');
        break;
      default:
        absenceStatus = this.i18n.instant('general.propertyNotAvailable');
        break;
    }
    return absenceStatus;
  }

  private setAbsences(response: PaginateResponse<ClockAbsence.Output[]>) {
    this.resultSearch = response;
    let i;
    this.totalPages = [];
    for (i = 1; i <= this.resultSearch.countPages; i++) {
      this.totalPages.push({
        page: i,
      });
    }

    /**
     * Since data here is paginated, this is not the proper approach. Way better to check in a cron basis in the backend
     * and send custom event to whatever the user need to be approving it.
     */
    const pending = this.resultSearch.docs.filter(
      (doc) => doc.status === ClockAbsence.Status.Pending
    );
    if (
      pending.length > 0 &&
      this.isAdminAbsences &&
      !this.pendingAbsenceEventSent
    ) {
      this.analyticsService.trackEvent({
        sources: ['braze'],
        eventName: AmplitudeEvents.absence_pending_approval,
        eventProperties: {
          count: pending.length,
        },
      });
      this.pendingAbsenceEventSent = true;
    }
    this.setAbsencePermissions();
  }

  getAbsenceCalendar(results: ClockAbsence.Output[]): void {
    if (results) {
      this.calendarEvents = [
        ...results.map((item) => {
          return {
            id: item._id,
            title: '',
            start: new Date(item.dateDetail.startDate),
            end: new Date(item.dateDetail.endDate),
            type: ClockAbsence.AbsenceType[item.type],
            isPending: item.status === 0,
            isHalfDay: item.typeDetail.type === 0,
          };
        }),
        ...this.holidayEvents,
      ];

      this.calculatePendingAbsences();
      this.calculateTotalSummary();
    }
  }

  calculatePendingAbsences(): void {
    const pendingEventsAux = this.calendarEvents.filter(
      (item) => item.isPending
    );

    this.pendingEvents = pendingEventsAux.map((item) => {
      const dateFormatted = {
        startDate: new Date(item.start).toISOString().split('T')[0],
        endDate: new Date(item.end).toISOString().split('T')[0],
      };

      const type: ClockAbsence.Type = item.isHalfDay
        ? ClockAbsence.Type.HalfDay
        : dateFormatted.startDate === dateFormatted.endDate
        ? ClockAbsence.Type.OneDay
        : ClockAbsence.Type.SeveralDays;

      return {
        ...item,
        totalDays: ClockAbsence.GetTotalWorkdays({
          startDate: dateFormatted.startDate,
          endDate: dateFormatted.endDate,
          type: type,
          workdays: this.workdays,
          holidays: this.holidaysDates,
        }),
      };
    });

    this.draw();
  }

  calculateTotalSummary(): void {
    const initialSummary = Object.values(ClockAbsence.AbsenceType).reduce(
      (acc, type) => {
        if (typeof type === 'string') {
          acc[type] = 0;
        }
        return acc;
      },
      {} as { [key: string]: number }
    );

    const excludedStatuses = [
      ClockAbsence.Status.Pending,
      ClockAbsence.Status.Rejected,
      ClockAbsence.Status.Other,
    ];

    const totalSummary = this.absencesPerUser.reduce((acc, item) => {
      if (!excludedStatuses.includes(item.status)) {
        const type = ClockAbsence.AbsenceType[item.type];
        const totalDays = ClockAbsence.GetTotalWorkdays({
          startDate: item.dateDetail.startDate,
          endDate: item.dateDetail.endDate,
          type: item.typeDetail.type,
          workdays: this.workdays,
          holidays: this.holidaysDates,
        });
        if (!acc[type]) {
          acc[type] = 0;
        }

        acc[type] += totalDays;
      }
      return acc;
    }, initialSummary);
    this.totalSummary = Object.keys(totalSummary).map((key) => ({
      type: key,
      count: totalSummary[key],
    }));

    this.draw();
  }

  translateAbsencesTypes() {
    this.absenceTypesFilter = [
      {
        title: this.i18n.instant('table.content.holidays'),
        value: '0',
      },
      {
        title: this.i18n.instant('table.content.sickness'),
        value: '1',
      },
      {
        title: this.i18n.instant('table.content.maternity'),
        value: '2',
      },
      {
        title: this.i18n.instant('table.content.familySickness'),
        value: '3',
      },
      {
        title: this.i18n.instant('table.content.others'),
        value: '4',
      },
    ];
    this.absenceStatusFilter = [
      {
        title: this.i18n.instant('table.content.pending'),
        value: '0',
      },
      {
        title: this.i18n.instant('table.content.rejected'),
        value: '1',
      },
      {
        title: this.i18n.instant('table.content.approved'),
        value: '2',
      },
      {
        title: this.i18n.instant('table.content.others'),
        value: '3',
      },
    ];
  }

  private watchRefreshList() {
    const refreshAbsenceList = (res: { reload: boolean }) => {
      if (res?.reload) {
        if (this.absenceViews.CALENDAR === this.viewTypeActive) {
          this.getVacationsReport();
          this.getAbsencesPerUser();
        } else {
          this.requestAbsences(true);
        }
      }
    };

    this.sub$.add(
      this.addClockAbsenceService.refreshList$.subscribe(refreshAbsenceList)
    );

    this.sub$.add(
      this.showDetailClockAbsenceService.refreshList$.subscribe(
        refreshAbsenceList
      )
    );
  }

  private watchQueryParams() {
    const patchParams = (map: ParamMap) => {
      this.fromTeamView();
      const hasOwner = !!map.get('idOwner');

      const idOwners = hasOwner ? [map.get('idOwner')] : [];

      this.isAdminAbsences = this.activateRoute.snapshot.queryParamMap.get(
        'idOwner'
      )
        ? false
        : true;
      this.displayedColumns = this.activateRoute.snapshot.queryParamMap.get(
        'idOwner'
      )
        ? this.absencesColumns.filter((item) => item !== 'userName')
        : this.absencesColumns;

      // If I'm in my own absences the initial filters need to be applied
      if (hasOwner) {
        this.searchParams = INITIAL_FILTER(this.isMyAbsence());
      }

      this.resetViewType();

      this.searchParams = {
        ...this.searchParams,
        'idOwners[]': idOwners,
      };

      this.resolveFiltersParams();
    };

    const map = this.activateRoute.snapshot.queryParamMap;

    patchParams(map);

    this.sub$.add(
      this.activateRoute.queryParamMap.subscribe((map) => {
        patchParams(map);
        this.requestAbsences(true);
        this.getAbsencesPerUser();
      })
    );
  }

  defaultValues = {
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0],
    view: 'month',
  };

  changePeriodTeamView(evt: { startDate: string; endDate: string }): void {
    this.filterDate = {
      startDate: evt.startDate,
      endDate: evt.endDate,
    };

    this.calendarYear = new Date(evt.endDate).getFullYear();

    this.getAbsencesEmployees();
  }

  private resolveFiltersParams(): void {
    const showOwners = Boolean(this.isAdminAbsences);
    this.sourceFilter = SOURCE_FILTER({
      userSearchService: this.userSearchService,
      showOwners,
    });

    this.sourceSecondaryMobileFilter = SOURCE_MOBILE({
      userSearchService: this.userSearchService,
      showOwners,
    });

    this.sourceSecondaryWebFilter = SOURCE_SECONDARY_WEB_FILTER({
      userSearchService: this.userSearchService,
      showOwners,
    });

    this.sourceUserFilter = SOURCE_USER_FILTER({
      userSearchService: this.userSearchService,
      showOwners,
    });
    this.sourceMobileFilter = SOURCE_USER_MOBILE_FILTER({
      userSearchService: this.userSearchService,
      showOwners,
    });
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  public refresh() {
    return this.draw();
  }

  public goBack(): void {
    this.router.navigate(['/admin/clock-absence']);
  }

  applyFilters(evt: {
    startDate: Date;
    endDate: Date;
    customProperties: { [key: string]: any };
  }): void {
    const startDate = evt.startDate;
    const endDate = evt.endDate;
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      customProperties: JSON.stringify(evt.customProperties),
    };

    if (!startDate && !endDate) {
      this.searchParams['from'] = startDate;
      this.searchParams['to'] = endDate;
      this.requestAbsences(true);
      return;
    }

    this.changeDate('START', startDate);
    this.changeDate('END', endDate);
  }

  rmFilterCoreChanges(evt: RmFilter.Changes) {
    this.searchParams = INITIAL_FILTER(this.isMyAbsence());
    this.searchParams.page = 1;
    this.searchParams = {
      ...this.searchParams,
      ...evt.queryParams,
    };
    if (evt.queryParams && Object.keys(evt.queryParams).length > 0) {
      let rateEvent = '';
      if (evt.type === 'CHANGE') {
        rateEvent = this.isAdminAbsences
          ? AmplitudeEvents.absenceTeam_filter
          : AmplitudeEvents.absence_filter;
      } else {
        rateEvent = this.isAdminAbsences
          ? AmplitudeEvents.absenceTeam_search
          : AmplitudeEvents.absence_search;
      }
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: rateEvent,
      });
    }
    this.requestAbsences(true);
  }

  rmFilterCalendarChanges(evt: RmFilter.Changes) {
    if (evt.queryParams && evt.queryParams['idOwners[]']) {
      const ownerIds: string[] = evt.queryParams['idOwners[]'] as string[];
      this.filteredAbsencesEmployees = this.absencesEmployees.filter(
        (employee) => ownerIds.includes(employee.user.id)
      );
      this.hasActiveFilters = true;
    } else {
      this.filteredAbsencesEmployees = [...this.absencesEmployees];
      this.hasActiveFilters = false;
    }

    this.draw();
  }

  calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 1000);
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  private getBuildExcelSelection(): Workbook {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('ClockAbsence');
    const header = [
      this.i18n.instant('clockAbsence.file.name'),
      this.i18n.instant('clockAbsence.file.startDate'),
      this.i18n.instant('clockAbsence.file.endDate'),
      this.i18n.instant('clockAbsence.file.totalDays'),
      this.i18n.instant('clockAbsence.file.typeAbsence'),
      this.i18n.instant('clockAbsence.file.status'),
      this.i18n.instant('clockAbsence.file.notes'),
    ];
    // const footer = [' ', ' ', ' ', ' ', 'TOTAL', this.resultSearch.total];
    const headerRow = worksheet.addRow(header);

    headerRow.font = {
      color: { argb: '003c48ec' },
      bold: true,
      size: 12,
    };

    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    const NOT_AVAILABLE_TEXT = this.i18n.instant(
      'general.propertyNotAvailable'
    );

    for (const clockAbsence of this.entriesForDelete) {
      const cells = [];

      cells.push(
        clockAbsence.owner ? clockAbsence.owner?.name : NOT_AVAILABLE_TEXT
      );

      cells.push(clockAbsence.dateDetail.startDate);

      cells.push(clockAbsence.dateDetail.endDate);

      const { startDate, endDate } = clockAbsence.dateDetail;
      const type = clockAbsence.typeDetail.type;

      cells.push(
        ClockAbsence.GetTotalDays({
          startDate: new Date(startDate).toDateString(),
          endDate: new Date(endDate).toDateString(),
          type,
        })
      );

      cells.push(this.getTypeAbsence(clockAbsence.type));

      cells.push(this.getAbsenceStatus(clockAbsence.status));

      cells.push(clockAbsence.note);

      const row = worksheet.addRow(cells);

      row.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    }

    worksheet.columns.forEach(function (column) {
      column.width = 27;
    });

    return workbook;
  }

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.table.clearChecks();
  }

  downloadExcelSelection() {
    const workbook = this.getBuildExcelSelection();

    const type =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    workbook.xlsx
      .writeBuffer()
      .then(
        (data) =>
          new Blob([data], {
            type,
          })
      )
      .then((blob) =>
        this.fileWrite.writeFile({
          path: `clock-absence-${this.datePipe.transform(
            new Date(),
            'dd-MM-yyyy'
          )}.xlsx`,
          blob,
        })
      )
      .then(() => {
        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName: AmplitudeEvents.absenceTeam_excel_bulk_download,
        });
        this.downloadBtnService.downloadedSuccessfully();
      });
  }

  // Get current language
  getLang() {
    return this.i18n.currentLang;
  }

  openVideo(type: string) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: this.isAdminAbsences
        ? AmplitudeEvents.absenceTeam_play_tutorial
        : AmplitudeEvents.absence_play_tutorial,
    });
    this.video.open(type);
  }

  /********************************************TOOLTIP************************************************** */
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showCreateUser = (): void => {
    this.createPersonService.open().subscribe(() => {
      this.getAbsencesEmployees();
    });
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.user_start,
      eventProperties: {
        event_location: 'absenceTeam',
      },
    });
  };

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: this.isAdminAbsences
            ? 'adminClockAbsence.tooltip'
            : 'clockAbsence.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: this.isAdminAbsences
        ? AmplitudeEvents.absenceTeam_info
        : AmplitudeEvents.absence_info,
    });
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  download(type: DownloadType) {
    switch (type) {
      case DownloadType.EXCEL:
        if (this.entriesForDelete?.length) {
          this.downloadExcelSelection();
        } else {
          this.downloadExcel();
        }
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: this.isAdminAbsences
            ? AmplitudeEvents.absenceTeam_excel_download
            : AmplitudeEvents.absence_excel_download,
          eventProperties: {
            event_location:
              this.viewTypeActive === absenceViews?.CALENDAR
                ? 'team_list'
                : 'all_list',
          },
        });
        this.analyticsService.trackEvent({
          sources: ['braze'],
          eventName: AmplitudeEvents.absence_excel_download,
        });
        break;
      case DownloadType.PDF:
        break;
      default:
        break;
    }
  }

  getVacationsReport(): void {
    this.setUserId();
    this.clockAbsenceService
      .getVacationReport(this.userId, this.calendarYear)
      .subscribe((res) => {
        this.vacationReport = res;

        this.fetchUserWorkdays();
        this.draw();
      });
  }

  getAbsencesEmployees(): void {
    if (
      this.viewTypeActive === absenceViews?.CALENDAR &&
      this.isAdminAbsences
    ) {
      this.clockAbsenceService
        .getAbsencesCalendar(this.filterDate.startDate, this.filterDate.endDate)
        .subscribe(
          (res: AbsenceEmployee[]) => {
            this.absencesEmployees = res;

            if (this.hasActiveFilters) {
              const filteredIds = this.filteredAbsencesEmployees.map(
                (emp) => emp.user.id
              );
              this.filteredAbsencesEmployees = this.absencesEmployees.filter(
                (emp) => filteredIds.includes(emp.user.id)
              );
            } else {
              this.filteredAbsencesEmployees = [...this.absencesEmployees];
            }

            this.draw();
            this.getHolidayCalendars();
          },
          (error) => {
            console.warn(error);
          }
        );
    }
  }
  getAbsencesPerUser(): void {
    this.setUserId();

    this.clockAbsenceService
      .getAbsencesPerUser(this.calendarYear, this.userId)
      .subscribe((res: ClockAbsence.Output[]) => {
        this.absencesPerUser = res;
        this.getAbsenceCalendar(res);
        this.getHolidayCalendars();
      });
  }

  setUserId(): void {
    const map = this.activateRoute.snapshot.queryParamMap;
    if (map.get('idOwner')) {
      this.userId = map.get('idOwner');
    }
  }

  navigateToHolidaysSettings(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.absenceTeam_bankholidays_start,
    });
    this.router.navigate(['/admin/settings/general/holidays'], {
      queryParams: { origin: 'absences' },
    });
  }

  private getHolidayCalendars(): void {
    if (this.isLoadingCalendars) {
      return;
    }
    this.isLoadingCalendars = true;

    this.sub$.add(
      this.clockHourService
        .getBankHolidaysCalendars()
        .pipe(
          finalize(() => {
            this.isLoadingCalendars = false;
            if (this.calendarId) {
              this.getHolidays();
            } else {
              this.loadingTotal = false;
            }
            this.draw();
          })
        )
        .subscribe((res) => {
          if (Array.isArray(res) && res.length > 0) {
            this.calendarId = res[0].id;
          }
        })
    );
  }

  private getHolidays(): void {
    if (this.isLoadingHolidays) {
      return;
    }
    this.isLoadingHolidays = true;

    this.sub$.add(
      this.clockHourService
        .getBankHolidays(this.calendarId, this.calendarYear)
        .pipe(
          finalize(() => {
            this.isLoadingHolidays = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          const holidays = Array.isArray(res) ? res : [];
          this.holidaysDates = holidays.map((holiday) => holiday.day);
          this.calculateTotalSummary();
          this.loadingTotal = false;
          this.filteredAbsencesEmployees = this.filteredAbsencesEmployees.map(
            (employee) => {
              return {
                ...employee,
                holidays: holidays,
              };
            }
          );

          const startOfDay = 'T00:00:00';
          const endOfDay = 'T23:59:59';

          const holidayEvents = holidays.map((holiday) => {
            const h: CalendarEventType = {
              id: holiday.id,
              start: new Date(holiday.day + startOfDay),
              end: new Date(holiday.day + endOfDay),
              title: holiday.name,
              type: 'Holiday',
              isHoliday: true,
            };
            return h;
          });

          if (!isEqual(this.holidayEvents, holidayEvents)) {
            this.calendarEvents = [...this.calendarEvents, ...holidayEvents];
            this.holidayEvents = holidayEvents;
          }

          this.draw();
        })
    );
  }
}
